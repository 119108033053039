import { createSlice } from '@reduxjs/toolkit';

interface MaterialRequestState {
	addMaterialRequestModalVisible: boolean;
	modalStatus: boolean
}

const initialState: MaterialRequestState = {
	addMaterialRequestModalVisible: false,
	modalStatus: false
};

export const materialRequest = createSlice({
	name: 'materialRequest',
	initialState,
	reducers: {
		showAddMaterialModalRequest: (state) => {
			state.addMaterialRequestModalVisible = true;
		},
		closeAddMaterialModalRequest: (state) => {
			state.addMaterialRequestModalVisible = false;
		},
		showModal: ( state ) => {
			state.modalStatus = true;
		},
		closeModal: ( state ) => {
			state.modalStatus = false;
		}
	},
});

// Action creators are generated for each case reducer function
export const { showAddMaterialModalRequest, closeAddMaterialModalRequest, showModal, closeModal } = materialRequest.actions;

export default materialRequest.reducer;
