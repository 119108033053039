import { createSlice } from '@reduxjs/toolkit';


interface MaterialOrderLineItemState {
    statusModalVisible: boolean;
}

const initialState: MaterialOrderLineItemState = {
    statusModalVisible: false,
}

export const materialOrderLineItemModel = createSlice({
    name: 'materialOrderLineItemModel',
    initialState,
    reducers: {
        showStatusModal: (state) => {
            state.statusModalVisible = true;
        },
        closeStatusModal: (state) => {
            state.statusModalVisible = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { 
    showStatusModal, closeStatusModal } =
materialOrderLineItemModel.actions;

export default materialOrderLineItemModel.reducer;
