import { Col, Row } from 'antd';
import MaterialRequestTable from '../../../components/MaterialRequestTable';

import Header from '../../../components/Header';
import { Link } from 'react-router-dom';

function MaterialRequests() {
	return (
		<>
			<Header
				title="Material Requests"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Material Requests',
					},
				]}
			/>

			<Row>
				<Col span={24}>
					<MaterialRequestTable />
				</Col>
			</Row>
		</>
	);
}

export default MaterialRequests;
