import {Button, Card, Empty, Modal, TableProps, notification, Tag} from 'antd';
import {ColumnsType, SorterResult} from 'antd/es/table/interface';
import {useEffect, useState} from 'react';
import {
    useLazyGetMaterialRequestQuery,
    useUpdateMaterialRequestMutation,
} from '../../redux/api/material-request';
import {MaterialTypeObject} from '../../interfaces/Material.Interface';
import {WarningOutlined, FileSyncOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router';
import {useAddMaterialOrderDraftMutation} from '../../redux/api/material';
import Search from 'antd/es/input/Search';
import {useSearchParams} from 'react-router-dom';
import '../../customTable.css';
import dayjs from 'dayjs';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';
import MaterialRequestModal from '../../components/modals/Orders/MaterialRequestModal';
import {getOrderLineItemStatusTagColor} from "../../utils";

interface ILineItemObject {
	id: string;
	orderName?: string;
	materialRequestId?: string;
}

const MaterialRequestTable = () => {
	const navigate = useNavigate();
	const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [getLazyMaterial] = useLazyGetMaterialRequestQuery();
	const [materialRequests, setMaterialRequest] = useState<any[]>([]);
	const [enable, setEnable] = useState(false);
	const { search } = useLocation();
	const [find, setFind] = useState<any>();
	const [sort, setSort] = useState<any>();
	const [limit, setLimit] = useState(1);
	const [count, setCount] = useState(0);
	const [load, setLoad] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRequests, setSelectedRequests] = useState<any[]>([]);
	const [addMaterialOrderDraft] = useAddMaterialOrderDraftMutation();
	const [updateMaterialRequestMutation] = useUpdateMaterialRequestMutation();
	const [lineItemObject, setLineItemObject] = useState<ILineItemObject | null>(
		null
	);

    const onChange: TableProps<MaterialTypeObject>['onChange'] = (
        _pagination,
        _filters,
        sorter,
        extra
    ) => {
        const sorterColumn = (sorter as SorterResult<MaterialTypeObject>)?.column;

        if (extra.action === 'sort' && !sorterColumn) {
            setSort('');
            const page = limit;
            const filter = find;
            const object: any = {};
            if (filter) {
                object['find'] = find;
            }
            if (page) {
                object['page'] = limit;
            }

            setSearchParams({...object});
        }
        if (extra.action === 'sort' && sorterColumn) {
            const orderType = (sorter as SorterResult<MaterialTypeObject>)?.order;

            const column =
                orderType === 'ascend' || orderType === undefined
                    ? sorterColumn?.key
                    : '-' + sorterColumn?.key;

            if (column) {
                setSort(() => column);
                const filter = find;
                const page = limit;
                const object: any = {};
                if (filter) {
                    object['find'] = find;
                }
                if (page) {
                    object['page'] = limit;
                }
                setSearchParams({ordering: column, ...object});
            }
        }
    };

    const onSearch = (value: string) => {
        if (value != '') {
            setFind(() => value);
            const page = limit;
            const order = sort;
            const object: any = {};
            if (order) {
                object['ordering'] = order;
            }
            if (page) {
                object['page'] = 1;
            }

            setSearchParams({search: value, ...object});
        }
        if (value == '') {
            setFind(() => '');
            const page = limit;
            const order = sort;
            const object: any = {};
            if (order) {
                object['ordering'] = order;
            }
            if (page) {
                object['page'] = limit;
            }

            setSearchParams({...object});
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        const formData = new FormData();

		const selectedRows = selectedRequests.map((item) => {
			return {
				material_order_request: item.id,
				type: item.type.id,
				shape: item.shape.id,
				order: item.id_order_line_item[2],
				sales_order_line_items: [item.id_order_line_item[0]],
				height: item.height,
				height_unit: item.height_unit?.id,
				width: item.width,
				width_unit: item.width_unit?.id,
				outer_diameter: item.outer_diameter,
				outer_diameter_unit: item.outer_diameter_unity?.id,
				inner_diameter: item.inner_diameter,
				inner_diameter_unit: item.inner_diameter_unity?.id,
				length: item.length,
				length_unit: item.length_unit?.id,
				quantity_ordered: item.quantity_ordered,
				vendor_part_number: item.vendor_number,
				unit_price: null,
				wall_thickness: item.wall_thickness,
				wall_thickness_unit: item.wall_thickness_unit?.id,
			};
		});
		formData.append('material', JSON.stringify(selectedRows));

		await addMaterialOrderDraft({
			material: selectedRows,
		})
			.unwrap()
			.then(async (res) => {
				for (const itemMaterial of selectedRequests) {
					await updateMaterialRequestMutation({
						id: itemMaterial.id,
						values: { status: 'Reserved' },
					});
				}

                navigate(`/material/orders/add/${res.id}`);
            })
            .catch(() => {
                setIsModalOpen(false);
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: 'The material request cannot be converted.',
                    placement: 'topRight',
                });
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        GetAsyncMaterialRequested();
    }, [search]);

    async function GetAsyncMaterialRequested() {
        const object: any = {};

        const getMaterialUUIDFromQueryString = searchParams.get('search');

        if (sort) {
            object['ordering'] = sort;
        }

        const page = searchParams.get('page');

        if (page) {
            object['page'] = page;
        }

        if (find) {
            object['search'] = find;
        }

        if (!find && getMaterialUUIDFromQueryString) {
            object['search'] = getMaterialUUIDFromQueryString;
        }

        setLoad(true);
        const response = await getLazyMaterial({...object});
        setLoad(false);
        setCount(() => (response.data?.count ? response.data.count : 0));
        setMaterialRequest(response.data ? response.data.results : []);

        if (
            getMaterialUUIDFromQueryString &&
            response.data &&
            response.data.results.length > 0
        ) {
            const material_request = response.data.results.find(
                (item: MaterialTypeObject) => item.id == getMaterialUUIDFromQueryString
            );

            if (material_request && material_request.status === 'Submitted') {
                setEnable(true);
                setSelectedRequests([material_request]);

                setSelectedRowKeys([getMaterialUUIDFromQueryString]);
            } else {
                setEnable(false);
            }
        }
    }

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Vendor',
            key: 'vendor__display_name',
            render: (record: MaterialTypeObject) => record.vendor?.display_name,
            sorter: true,
            width: 150,
        },
        {
            title: 'Related Sales Order Line Item',
            key: 'sale_order_name',
            width: '270px',
            render: (record: MaterialTypeObject) => (
                <>
                    <Button
                        onClick={() => navigate(`/orders/line-items/${record.order_line_item?.id}`)}
                        type="link"
                        className="flex justify-start truncate"
                        style={{textAlign: 'start', width: '100%', padding: 0}}
                    >
                        {record.order_line_item?.name}
                    </Button>
                    {record.order_line_item && record.order_line_item.cancelled_quantity > 0  && record.order_line_item.status !== 'Cancelled' ? (
                        <Tag color={getOrderLineItemStatusTagColor("Quantity Changed")}>
                            <WarningOutlined style={{ fontSize: '0.7rem' }} /> Quantity Changed
                        </Tag>
                    ) : record.order_line_item && record.order_line_item.status !== 'In Progress' &&
                        record.order_line_item.status !== 'Completed' && record.order_line_item.status !== 'Received' && (
                        <Tag color={getOrderLineItemStatusTagColor(record.order_line_item.status)}>
                            {record.order_line_item?.status}
                        </Tag>
                    )}
                </>
            ),
            sorter: false,
        },
        {
            title: 'Quantity',
            key: 'quantity_ordered',
            width: 120,
            render: (record: MaterialTypeObject) => `${record.quantity_ordered}`,
            sorter: true,
        },
        {
            title: 'Material Type',
            key: 'type__display_name',
            width: 150,
            render: (record: MaterialTypeObject) => record.type?.display_name,
            sorter: true,
        },
        {
            title: 'Material Shape',
            key: 'shape__display_name',
            width: 150,
            render: (record: MaterialTypeObject) => `${record.shape?.display_name}`,
            sorter: true,
        },
        {
            title: 'Material Size',
            width: 125,
            render: (record: MaterialTypeObject) => record?.display_size,
            sorter: false,
        },
        {
            title: 'Length',
            key: 'length',
            width: 140,
            render: (record: MaterialTypeObject) =>
                `${record.length} ${record.length_unit?.name}`,
            sorter: true,
        },
        {
            title: 'Status',
            key: 'status',
            width: 150,
            render: (record: MaterialTypeObject) => (
                <>
                    {record?.status}
                    {record?.material && record.material?.material_order && (
                        <Button
                            onClick={() =>
                                navigate(`/material/orders/${record.material?.material_order}`)
                            }
                            type="link"
                            className="flex justify-start truncate"
                            style={{
                                // width: '150px',
                                fontSize: '13px',
                                padding: 0,
                            }}
                        >
                            View Material Order
                        </Button>
                    )}
                </>
            ),
            sorter: true,
        },
        {
            title: 'Additional Request Reason',
            key: 'is_additional_request',
            width: 160,
            render: (record: MaterialTypeObject) => (
                <>
                    {record?.is_additional_request && (
                        <span>{record?.additional_request_reason?.name}</span>
                    )}
                </>
            ),
            sorter: true,
        },
        {
            title: 'Created by',
            width: 150,
            render: (record: MaterialTypeObject) => (
                <div
                    style={{
                        width: '130px',
                        textOverflow: 'ellipsis',
                        overflowX: 'hidden',
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span>{record?.created_by}</span>
                </div>
            ),
            sorter: false,
        },
        {
            title: 'Created Date',
            width: 150,
            key: 'created_time',
            render: (record: MaterialTypeObject) =>
                dayjs(record?.created_time).format('MMM DD YYYY'),
            sorter: true,
        },
    ];

    const rowSelection = {
        onChange: (selectedKeys: any, selectedRows: any) => {
            const dif = selectedKeys;
            setSelectedRequests(() => selectedRows);
            if (selectedRows.length > 0 && dif.length > 0) {
                setEnable(true);
            } else {
                setEnable(false);
            }

            setSelectedRowKeys(selectedKeys);
        },
        selectedRowKeys,
        getCheckboxProps: (record: MaterialTypeObject) => ({
            disabled: record.status !== 'Submitted', // Column configuration not to be checked
            name: record.status,
        }),
    };

    const emptyTable = () => {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                    <span style={{color: '#000'}}>
						There are currently no materials to be displayed.
					</span>
                }
            />
        );
    };

    return (
        <>
            <Modal
                title="Convert to Material Order"
                centered={true}
                open={isModalOpen}
                onOk={handleOk}
                okText="Submit"
                onCancel={handleCancel}
            >
                <p>
                    Would you like to create a new material order based on the selected
                    Material Requests?
                </p>
            </Modal>
            <div className="flex flex-col md:flex-row">
                <div className="search-input">
                    <Search
                        placeholder="Search"
                        allowClear
                        onSearch={onSearch}
                        style={{width: '100%'}}
                    />
                </div>
                <Button
                    className="md:ml-2"
                    onClick={showModal}
                    disabled={!enable}
                    icon={<FileSyncOutlined/>}
                >
                    Convert to Material Order
                </Button>
            </div>

            {selectedRequests.length > 0 && <div className="selected-item"></div>}

			<Card className="shadow-sm" bodyStyle={{ padding: 0, height: '60vh' }}>
				<div
					style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}
				>
					<CustomResponsiveTable
						columns={tableColumns}
						rowSelection={rowSelection}
						data={materialRequests}
						excludeWidthColumn={true}
						loading={load}
						onChange={onChange}
						emptyTable={emptyTable}
						pagination={
							count
								? {
										pageSize: 20,
										pageSizeOptions: [],
										total: count,
										current: Number(searchParams.get('page')) || 1,
										showSizeChanger: false,
										onChange: (page) => {
											const filter = find;
											const sorting = sort;
											const object: any = {};
											if (filter) {
												object['find'] = find;
											}
											if (sorting) {
												object['ordering'] = sorting;
											}
											setLimit(page);
											setSearchParams({ page: page.toString(), ...object });
										},
										defaultPageSize: 20,
								  }
								: false
						}
					/>
				</div>
			</Card>

			{lineItemObject && (
				<MaterialRequestModal
					showModal={lineItemObject ? true : false}
					closeModal={() => {
						setLineItemObject(null);
					}}
					lineItem={lineItemObject}
					refetchLineItem={GetAsyncMaterialRequested}
					orderName={lineItemObject?.orderName}
					materialRequestData={materialRequests.find(
						(item) => item.id === lineItemObject.materialRequestId
					)}
				/>
			)}
		</>
	);
};

export default MaterialRequestTable;
