import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Table, Card, Empty, TableProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddMaterialModalRequest } from '../../../redux/slices/material-request';
import type {
	ColumnsType,
	SorterResult,
	TableRowSelection,
} from 'antd/es/table/interface';
import { RootState } from '../../../redux/store';
import {
	useGetMaterialRequestQuery,
	useLazyGetMaterialRequestQuery,
} from '../../../redux/api/material-request';
import { MaterialTypeObject } from '../../../interfaces/Material.Interface';

import CustomResponsiveTable from '../../CustomResponsiveTable/index.tsx';

interface RemnantProps {
	onAddItem: (requests: MaterialTypeObject[]) => void;
}

const AddFromMaterialRequestModal = (props: RemnantProps) => {
	const dispatch = useDispatch();

	const materialRequests = useGetMaterialRequestQuery({ search: 'Submitted' });

	const visible = useSelector(
		(state: RootState) => state.materialRequest.addMaterialRequestModalVisible
	);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [data, setData] = useState<MaterialTypeObject[]>([]);
	const [selectedMaterialObjects, setSelectedMaterialObjects] = useState<
		MaterialTypeObject[]
	>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(0);

	const [getLazyMaterial, getMaterialRequestResults] =
		useLazyGetMaterialRequestQuery();

	const getNewMaterialsRequest = async (page: number) => {
		const response = await getLazyMaterial({
			page: page.toString(),
			search: 'Submitted',
		});

		if (response?.data) {
			setData(response?.data.results);
		}
	};

	const closeModal = () => {
		dispatch(closeAddMaterialModalRequest());
	};

	const onOk = () => {
		props.onAddItem(selectedMaterialObjects);
		closeModal();
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		const newSelectedObjects: MaterialTypeObject[] = [];
		newSelectedRowKeys.forEach((element) => {
			const item = data.find((obj) => {
				return obj.id === element;
			});

			if (item) newSelectedObjects.push(item);
		});

		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedMaterialObjects(newSelectedObjects);
	};

	const onChange: TableProps<MaterialTypeObject>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		const sorterColumn = (sorter as SorterResult<MaterialTypeObject>)?.column;

		if (extra.action === 'sort' && sorterColumn === undefined) {
			// props.onSort(undefined)
			// getNewMaterialsRequest(undefined);
			return;
		}

		if (extra.action === 'sort') {
			const orderType = (sorter as SorterResult<MaterialTypeObject>)?.order;

			orderType === 'ascend' || orderType === undefined
				? sorterColumn?.key
				: '-' + sorterColumn?.key;
			// props.onSort(column);
			// getNewMaterialsRequest(column);
		}
	};

	const columns: ColumnsType<any> = [
		{
			title: 'Vendor',
			key: 'vendor',
			render: (record: MaterialTypeObject) => {
				return record.vendor?.display_name;
			},
			sorter: (a, b) =>
				a?.vendor?.display_name.localeCompare(
					b?.vendor?.display_name ? b.vendor?.display_name : ''
				),
		},
		{
			title: 'Related Sales Order Line Item',
			render: (record: MaterialTypeObject) => {
				return record.order_line_item?.name;
			},
		},
		{
			title: 'Quantity',
			key: 'quantity_ordered',
			render: (record: MaterialTypeObject) => {
				return record.quantity_ordered;
			},
			sorter: (a, b) => a.quantity_ordered - b.quantity_ordered,
		},
		{
			title: 'Material Type',
			render: (record: MaterialTypeObject) => {
				return typeof record.type === 'object'
					? record.type?.display_name
					: record.type;
			},
			sorter: (a, b) => {
				if (a.type?.display_name < b.type?.display_name) return -1;
				if (a.type?.display_name > b.type?.display_name) return 1;
				return 0;
			},
		},
		{
			title: 'Material Shape',
			render: (record: MaterialTypeObject) => {
				return record.shape?.display_name;
			},
			sorter: (a, b) =>
				a?.shape?.display_name.localeCompare(b?.shape?.display_name),
		},
		{
			title: 'Material Size',
			dataIndex: 'display_size',
			sorter: false,
		},
		{
			title: 'Length',
			dataIndex: 'length',
			sorter: (a, b) => a.length - b.length,
		},
		{
			title: 'Status',
			dataIndex: 'status',
		},
	];

	const rowSelection: TableRowSelection<MaterialTypeObject> = {
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
			{
				key: 'odd',
				text: 'Select Odd Row',
				onSelect: (changeableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
						if (index % 2 !== 0) {
							return false;
						}
						return true;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				},
			},
			{
				key: 'even',
				text: 'Select Even Row',
				onSelect: (changeableRowKeys) => {
					let newSelectedRowKeys = [];
					newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
						if (index % 2 !== 0) {
							return true;
						}
						return false;
					});
					setSelectedRowKeys(newSelectedRowKeys);
				},
			},
		],
	};

	useEffect(() => {
		if (materialRequests?.data) {
			setData(materialRequests?.data?.results);
			setTotal(materialRequests.data?.count ? materialRequests.data?.count : 0);
		}
	}, [materialRequests]);

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no materials to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<>
			<Modal
				title="Add From Material Request"
				open={visible}
				onOk={onOk}
				onCancel={closeModal}
				width={1400}
				centered
				okText="Add Selected"
			>
				<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<CustomResponsiveTable
							onChange={onChange}
							rowSelection={rowSelection}
							columns={columns}
							data={data}
							scroll={{ y: `calc(95vh - 250px)` }}
							loading={getMaterialRequestResults?.isFetching}
							pagination={{
								pageSize: 20,
								current: currentPage,
								pageSizeOptions: [],
								total: total,
								onChange: (page) => {
									// props.onChangePage(page)
									setCurrentPage(page);
									getNewMaterialsRequest(page);
								},
								defaultPageSize: 20,
							}}
							emptyTable={emptyTable}
						/>
					</div>
				</Card>
			</Modal>
		</>
	);
};

export default AddFromMaterialRequestModal;
