import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
	authToken: string | null;
}

const initialState: UserState = {
	authToken: localStorage.getItem('auth_token')
		? localStorage.getItem('auth_token')
		: null,
};

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAuthToken: (state, action: PayloadAction<string>) => {
			state.authToken = action.payload;
			localStorage.setItem('auth_token', action.payload);
		},
		clearAuthToken: (state) => {
			state.authToken = null;
			localStorage.removeItem('auth_token');
		},
	},
});

// Action creators are generated for each case reducer function
export const { setAuthToken, clearAuthToken } = user.actions;

export default user.reducer;
