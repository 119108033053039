import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
	loading: boolean;
}

const index = (props: Props) => {
	return (
		<Flex
			gap="middle"
			align="center"
			justify="space-around"
			style={{ flexGrow: 1, width: '100%' }}
		>
			<Spin
				spinning={props.loading}
				indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
			/>
		</Flex>
	);
};

export default index;
