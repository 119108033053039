import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';

import { useLazyGetOrderLineItemsQuery } from '../../redux/api/order-line-item';
import { SelectProps } from 'antd/es/select';
import _ from 'lodash';
import { Lineitem } from '../../interfaces/Ordel.Interface';

interface IProps extends SelectProps {
	material_item_id?: string;
}

const SalesOrderLineItemSelect: React.FC<IProps> = (props) => {
	const [soLineItemData, setSoLineItemData] = useState<Lineitem[] | undefined>(
		[]
	);

	const [getLineItemsData] = useLazyGetOrderLineItemsQuery();

	const filterLineItemsData = (data: Lineitem[] | undefined) => {
		// Disabled temporarily, enable when it is needed
		// return data?.filter(
		// 	(l_item) =>
		// 		l_item?.material === props?.material_item_id || l_item.material === null
		// );

		return data;
	};

	const getOrderLineItems = async (search?: string) => {
		const res = await getLineItemsData({
			page_size: 100,
			search: search,
			status: 'Received,In Progress',
		});
		const filtered_data = filterLineItemsData(res?.data?.results);
		setSoLineItemData(filtered_data);
	};

	useEffect(() => {
		getOrderLineItems();
	}, []);

	const debounceSalesOrderItem = useCallback(
		_.debounce((search: string) => getOrderLineItems(search), 600),
		[]
	);

	return (
		<Select
			mode="multiple"
			allowClear={true}
			placeholder="Select line item"
			showSearch
			onSearch={debounceSalesOrderItem}
			filterOption={false}
			{...props}
		>
			{soLineItemData?.map((item, index) => (
				<Select.Option key={index} value={item.id}>
					{item?.order?.name} - {item.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default SalesOrderLineItemSelect;
