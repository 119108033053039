import { Button, Tag, Card, TableProps, Empty } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
	DataTableMaterialOrder,
	OrderMaterial,
} from '../../interfaces/Material.Interface';
import { SorterResult } from 'antd/es/table/interface';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const MaterialOrdersTable = (props: DataTableMaterialOrder) => {
	const onChange: TableProps<OrderMaterial>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		const sorterColumn = (sorter as SorterResult<OrderMaterial>)?.column;

		if (extra.action === 'sort' && sorterColumn === undefined) {
			props.onSort(undefined);
			return;
		}

		if (extra.action === 'sort') {
			const orderType = (sorter as SorterResult<OrderMaterial>)?.order;
			// const columnKey = (sorter as SorterResult<OrderMaterial>)?.columnKey;
			const column =
				orderType === 'ascend' || orderType === undefined
					? sorterColumn?.key
					: '-' + sorterColumn?.key;
			if (column) {
				props.onSort(column?.toString());
			}
		}
	};
	const tableColumns = [
		{
			title: 'Vendor',
			render: (record: OrderMaterial) => `${record?.vendor?.display_name}`,
			key: 'vendor__display_name',
			sorter: true,
		},
		{
			title: 'Vendor Order Number',
			render: (record: OrderMaterial) =>
				`${record?.vendor_order_number ? record?.vendor_order_number : ''}`,
			key: 'vendor_order_number',
			sorter: true,
		},
		{
			title: 'Created',
			render: (record: OrderMaterial) =>
				dayjs(record?.created_time).format('MMM DD YYYY'),
			key: 'created_time',
			sorter: true,
		},
		{
			title: 'Status',
			render: (record: OrderMaterial) => (
				<Tag color={record.status === 'Draft' ? 'orange' : 'green'}>
					{record.status}
				</Tag>
			),
			key: 'status',
			sorter: (a: OrderMaterial, b: OrderMaterial) =>
				a.status.localeCompare(b.status),
		},
		{
			title: '',
			render: (record: OrderMaterial) => (
				<Link to={`/material/orders/${record?.id}`}>
					<Button>Details</Button>
				</Link>
			),
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no material orders to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<CustomResponsiveTable
					columns={tableColumns}
					data={props.data}
					loading={props.isLoading}
					onChange={onChange}
					pagination={{
						pageSize: 20,
						current: props.currentPage,
						pageSizeOptions: [],
						total: props.total,
						onChange: (page) => {
							props.onChangePage(page);
						},
						defaultPageSize: 20,
						showSizeChanger: false,
					}}
					emptyTable={emptyTable}
				/>
			</div>
		</Card>
	);
};

export default MaterialOrdersTable;
