import React from 'react';

import { Modal } from 'antd';

import FormMaterialRequest from '../../../Forms';

import { MaterialTypeObject } from '../../../../interfaces/Material.Interface';

export interface ILineItemObject {
	id: string;
}

interface IProps {
	showModal: boolean;
	closeModal: () => void;
	lineItem: ILineItemObject;
	refetchLineItem: () => void;
	orderName: string | undefined;
	materialRequestData?: MaterialTypeObject;
}

import PartInformation from '../PartInformation';

import { useGetOrderLineItemByIdQuery } from '../../../../redux/api/order';

const MaterialRequestModal: React.FC<IProps> = ({
	showModal,
	closeModal,
	lineItem,
	orderName,
	refetchLineItem,
	materialRequestData,
}: IProps) => {
	const { data } = useGetOrderLineItemByIdQuery(
		{ id: lineItem.id as string },
		{ skip: !lineItem.id || !materialRequestData }
	);

	// If this modal is opened for editing the material request, it will fetch the line item data
	// because the "lineItem" prop doesn't contain all the necessary data for the PartInformation component.
	const getLineItemData = data ? data : lineItem;

	return (
		<>
			<Modal
				title={
					materialRequestData ? 'Edit Material Request' : 'Request Material'
				}
				centered
				open={showModal}
				// onOk={() => closeModal()}
				onCancel={() => {
					closeModal();
				}}
				width={1100}
				destroyOnClose={true}
				footer={[]}
			>
				<div className="select-material">
					<PartInformation lineItem={getLineItemData} />
					<FormMaterialRequest
						id={lineItem.id}
						order={orderName as string}
						closeModal={() => closeModal()}
						refetchLineItem={refetchLineItem}
						defaultMaterialRequestData={materialRequestData}
						disabled={
							materialRequestData && materialRequestData?.status !== 'Submitted'
								? true
								: false
						}
					/>
				</div>
			</Modal>
		</>
	);
};

export default MaterialRequestModal;
