import ToolHolderModelsTable from '../../components/ToolHolderModelsTable';
import AddToolHolderModel from '../../components/modals/AddToolHolderModel';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { showAddToolHolderModelModal } from '../../redux/slices/toolHolderModel';

import Header from '../../components/Header';

import { Link } from 'react-router-dom';

function ToolHolderModels() {
	const dispatch = useDispatch();
	return (
		<>
			<Header
				title="Tool Holder Models"
				showOnClickButton
				buttonText={'Add Tool Holder Model'}
				icon={<PlusOutlined />}
				handleOnClick={() => dispatch(showAddToolHolderModelModal())}
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Tool Holder Models',
					},
				]}
			/>

			<ToolHolderModelsTable />

			<AddToolHolderModel
				refetch={function (): void {
					throw new Error('Function not implemented.');
				}}
			/>
		</>
	);
}

export default ToolHolderModels;
