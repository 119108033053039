import {Lineitem} from "../interfaces/Ordel.Interface.ts";

export function getProductionMethodTagColor(name: string) {
    const colors = [
        {name: 'Fastlane', color: 'green'},
        {name: 'QuickTurn', color: 'blue'},
        {name: 'Swiss', color: 'magenta'},
        {name: 'Traditional', color: 'purple'},
    ];

    const color = colors.find((x) => x.name == name);
    if (color) {
        return color.color;
    }

    return 'default';
}

export function getOrderStatusTagColor(status: string) {
    const colors = [
        {status: 'Received', color: 'default'},
        {status: 'In Progress', color: 'blue'},
        {status: 'Completed', color: 'green'},
        {status: 'Partially On Hold', color: 'gold'},
        {status: 'On Hold', color: 'gold'},
        {status: 'Partially Cancelled', color: 'gold'},
        {status: 'Partially On Hold & Cancelled', color: 'gold'},
        {status: 'Cancelled', color: 'red'},
    ];

    const color = colors.find((x) => x.status == status);
    if (color) {
        return color.color;
    }

    return 'default';
}

export function getOrderLineItemStatusTagColor(status: string) {
    const colors = [
        {status: 'Received', color: 'default'},
        {status: 'In Progress', color: 'blue'},
        {status: 'Completed', color: 'green'},
        {status: 'On Hold', color: 'gold'},
        {status: 'Cancelled', color: 'red'},
        {status: 'Quantity Changed', color: 'orange'},
    ];

    const color = colors.find((x) => x.status == status);
    if (color) {
        return color.color;
    }

    return 'default';
}

export function getOrderLineItemProductionStatusTagColor(status: string) {
    const colors = [
        {status: 'Not Started', color: 'volcano'},
        {status: 'In Progress', color: 'blue'},
        {status: 'Completed', color: 'green'},
    ];

    const color = colors.find((x) => x.status == status);
    if (color) {
        return color.color;
    }

    return 'default';
}

export function getTotalPartsQty(lineItems: Lineitem[]) {
    return lineItems.reduce((totalQty, item) =>
        totalQty + (item.cancelled_quantity > 0 ? item.updated_quantity : item.quantity), 0);
}
