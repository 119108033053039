import 'react';
import {
	Button,
	Checkbox,
	Form,
	message,
	Modal,
	Select,
	Typography,
} from 'antd';

import { useUpdateOrderLineItemMutation } from '../../../redux/api/order.ts';
import { GetQueryId, Lineitem } from '../../../interfaces/Ordel.Interface.ts';
import { useState } from 'react';
import { statuses } from '../../../config/constants.ts';
import { useCompleteOrderLineItemMutation } from '../../../redux/api/order-line-item.ts';
import { CheckCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

interface Props {
	disabled: boolean;
	lineItem: Lineitem;
	refetchLineItem: () => void;
}

const OrderLineItemStatuses = (props: Props) => {
	const [messageApi, contextHolder] = message.useMessage();

	const [form] = Form.useForm();

	const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);

	const [updateOrderLineItem, updateOrderLineItemResult] =
		useUpdateOrderLineItemMutation();
	const [completeOrderLineItem, completeOrderLineItemResult] =
		useCompleteOrderLineItemMutation();

	async function handleChangeStatus(field_name: string, value: string) {
		if (props.lineItem) {
			updateOrderLineItem({
				id: props.lineItem.id,
				values: {
					[field_name]: value,
				},
			})
				.unwrap()
				.then(() => {
					props.refetchLineItem();

					messageApi.open({
						type: 'success',
						content: `Status updated successfully`,
					});
				})
				.catch((error) =>
					messageApi.open({
						type: 'error',
						content: error.data?.message,
					})
				);
		}
	}

	function handleModalClose() {
		setIsChecklistModalOpen(false);
		form.resetFields();
	}

	async function handleCompleteOrder(lineItemId: string) {
		const queryId: GetQueryId = {
			id: lineItemId,
		};

		completeOrderLineItem(queryId)
			.unwrap()
			.then((data) => {
				handleModalClose();
				props.refetchLineItem();

				messageApi.open({
					type: 'success',
					content: data?.message,
				});
			})
			.catch((error) =>
				messageApi.open({
					type: 'error',
					content: error.data?.message,
				})
			);
	}

	return !props.lineItem ? null : (
		<>
			<div className="flex flex-col gap-3 wrap-no-wrap">
				{statuses.map((category, index) => {
					function hideStatusCategory() {
						if (
							category.field_name === 'keyence_programming_status' &&
							props.lineItem.is_keyence_program_required === false
						) {
							return true;
						}

						if (
							category.field_name === 'tooling_ordering_status' &&
							props.lineItem.is_additional_tooling_required === false
						) {
							return true;
						}

						return false;
					}

					const currentStatus =
						Object.entries(props.lineItem).filter(
							(x) => x[0] === category.field_name
						).length > 0
							? Object.entries(props.lineItem).filter(
									(x) => x[0] === category.field_name
							  )[0][1]
							: null;

					return hideStatusCategory() ? null : (
						<div key={index}>
							<Text strong>{category.label}</Text>
							<Select
								style={{
									width: '100%',
								}}
								placeholder="Select a status"
								value={currentStatus}
								popupMatchSelectWidth={false}
								onChange={(value) =>
									handleChangeStatus(category.field_name, value)
								}
								disabled={props.disabled || updateOrderLineItemResult.isLoading}
							>
								{category.statuses.map((status, index) => (
									<Select.Option key={index} value={status}>
										{status}
									</Select.Option>
								))}
							</Select>
						</div>
					);
				})}

				{props.lineItem.machining_status === 'Completed' ||
				props.lineItem.status === 'Completed' ? (
					<Button
						type="primary"
						onClick={() => setIsChecklistModalOpen(true)}
						disabled={props.disabled}
					>
						{props.lineItem.status === 'Completed' ? (
							<>
								<CheckCircleFilled /> Completed
							</>
						) : (
							<>Mark as Completed</>
						)}
					</Button>
				) : null}
			</div>

			<Modal
				open={isChecklistModalOpen}
				centered
				width={640}
				onCancel={handleModalClose}
				title="Line Item Completion Checklist"
				footer={null}
			>
				<div className="pt-4">
					<Form
						form={form}
						layout="vertical"
						onFinish={() => handleCompleteOrder(props.lineItem.id)}
					>
						<Form.Item
							name="fai-completed-checkbox"
							valuePropName="checked"
							rules={[
								{
									type: 'boolean',
									required: true,
									message: 'Required',
									validator: async (_, checked) => {
										if (!checked) {
											return Promise.reject();
										}
									},
								},
							]}
						>
							<Checkbox>
								The FAI Report has been completed and/or a Keyence/CMM Report
								exists
							</Checkbox>
						</Form.Item>
						<Form.Item
							name="required-quantity-verified-checkbox"
							valuePropName="checked"
							rules={[
								{
									type: 'boolean',
									required: true,
									message: 'Required',
									validator: async (_, checked) => {
										if (!checked) {
											return Promise.reject();
										}
									},
								},
							]}
						>
							<Checkbox>
								The required quantity of parts were dropped off at the shipping
								department
							</Checkbox>
						</Form.Item>

						<div className="flex flex-row justify-end gap-1 pt-2">
							<Button htmlType="button" onClick={handleModalClose}>
								Cancel
							</Button>
							<Button
								type="primary"
								disabled={props.lineItem.status === 'Completed'}
								loading={completeOrderLineItemResult?.isLoading}
								htmlType="submit"
							>
								Mark as Completed
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
			{contextHolder}
		</>
	);
};

export default OrderLineItemStatuses;
