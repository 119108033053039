import PaginationComponent from '../Footers/Pagination.tsx';
import {Empty, Tag, TableProps, Tabs, Select, message} from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { Lineitem } from '../../interfaces/Ordel.Interface';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { useGetOrderLineItemsQuery } from '../../redux/api/order-line-item.ts';
import {
	getOrderLineItemProductionStatusTagColor,
	getProductionMethodTagColor,
} from '../../utils';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';
import { useEffect, useState } from 'react';
import { productionQueueTabs } from '../../config/constants.ts';
import {useUpdateOrderLineItemMutation} from "../../redux/api/order.ts";

const ProductionQueueTables = () => {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [lineItemQuery, setLineItemQuery] = useState({});
	const [clickedRowKey, setClickedRowKey] = useState<string|null>(null);
	const [displayTag, setDisplayTag] = useState(true);
	const [updateOrderLineItem] = useUpdateOrderLineItemMutation();


	// Initialize query from URL parameters
	useEffect(() => {
		setLineItemQuery({
			queue: searchParams.get('queue'),
			page: searchParams.get('page'),
			ordering: searchParams.get('ordering'),
			is_queues: true,
		});
	}, [searchParams]);

	const { data, isLoading, isFetching, refetch } = useGetOrderLineItemsQuery(
		lineItemQuery,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const refetchData = () => {
		// Refetch the data
		refetch();
	};

	const handleTableChange: TableProps<Lineitem>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		if (extra.action === 'sort') {
			const orderType = (sorter as SorterResult<Lineitem>)?.order;
			const columnKey = (sorter as SorterResult<Lineitem>)?.columnKey;
			const sorterColumn = (sorter as SorterResult<Lineitem>)?.column;

			const column =
				orderType === 'ascend' || orderType === undefined
					? columnKey
					: '-' + columnKey;
			if (column !== undefined && sorterColumn !== undefined) {
				searchParams.set('ordering', column?.toString());
			} else {
				searchParams.set('ordering', 'required_partner_ship_date');
			}
			navigate({ search: searchParams.toString() });
		}
	};

	const tableColumns: ColumnsType<any> = [
		{
			title: 'Preview',
			render: (record: Lineitem) => (
				<img className="h-16" src={record.thumbnail_file} />
			),
			key: 'thumbnail',
		},
		{
			title: 'Name',
			key: 'name',
			render: (record: Lineitem) => (
				<Link to={`/orders/line-items/${record?.id}`}>{record.name}</Link>
			),
			sorter: (a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			},
		},
		{
			title: 'Order',
			key: 'order_name',
			render: (record: Lineitem) => (
				<Link to={`/orders/${record.order.id}`}>{record.order.name}</Link>
			),
			sorter: (a, b) => {
				if (a.order.name < b.order.name) return -1;
				if (a.order.name > b.order.name) return 1;
				return 0;
			},
		},
		{
			title: 'Quantity',
			key: 'quantity',
			render: (record: Lineitem) => `${record.quantity}`,
			sorter: (a, b) => a.quantity - b.quantity,
		},
		{
			title: 'Method',
			key: 'partsbadger_production_method',
			render: (record: Lineitem) =>
				record.partsbadger_production_method ? (
					<Tag
						color={getProductionMethodTagColor(
							record.partsbadger_production_method
						)}
					>
						{record.partsbadger_production_method}
					</Tag>
				) : null,
			sorter: (a, b) => {
				if (a.partsbadger_production_method < b.partsbadger_production_method)
					return -1;
				if (a.partsbadger_production_method > b.partsbadger_production_method)
					return 1;
				return 0;
			},
		},
		{
			title: 'Received Date',
			key: 'created_time',
			render: (record: Lineitem) =>
				record?.created_time
					? dayjs(record.created_time).format('YYYY-MM-DD')
					: '',
			sorter: (a, b) => {
				const dateA = dayjs(a.created_time).format('YYYY-MM-DD') || '';
				const dateB = dayjs(b.created_time).format('YYYY-MM-DD') || '';
				return dateA.localeCompare(dateB);
			},
		},
		{
			title: 'Required Ship Date',
			key: 'required_partner_ship_date',
			render: (record: Lineitem) =>
				record?.required_partner_ship_date
					? dayjs(record.required_partner_ship_date).format('YYYY-MM-DD')
					: '',
			sorter: (a, b) => {
				const dateA =
					dayjs(a.required_partner_ship_date).format('YYYY-MM-DD') || '';
				const dateB =
					dayjs(b.required_partner_ship_date).format('YYYY-MM-DD') || '';
				return dateA.localeCompare(dateB);
			},
		},
	];

	const tableFooter = () => {
		return (
			<div className="flex justify-between items-center">
				<div className="ml-auto">
					<PaginationComponent
						pageSize={20}
						total={data?.count}
						showSizeChanger={false}
					/>
				</div>
			</div>
		);
	};

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no line items to be displayed.
					</span>
				}
			/>
		);
	};

	// Update URL when tab changes
	const handleTabChange = (key: string) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('queue', key);
		setSearchParams(newSearchParams);
		setLineItemQuery({ ...lineItemQuery, queue: key });
	};

	useEffect(() => {
		if (
			!searchParams.has('queue') ||
			!searchParams.get('page') ||
			!searchParams.has('ordering')
		) {
			setSearchParams({
				...searchParams,
				queue: 'ballooning_status',
				page: '1',
				ordering: 'required_partner_ship_date',
			});
			setLineItemQuery({
				...lineItemQuery,
				queue: 'ballooning_status',
				page: 1,
				ordering: 'required_partner_ship_date',
			});
		}
	}, []);

	const handleShowSelectStatus = (id: string) => {
		if (clickedRowKey === id) {
			setDisplayTag(!displayTag);
		} else {
			setClickedRowKey(id);
			setDisplayTag(false);
		}
	};

	const handleStatusChange = (id: string, value: string, field_name: string) => {
		// Update the status of the line item
		const payload = {
			id: id,
			values: { [field_name]: value },
		};

		updateOrderLineItem(payload)
			.unwrap()
			.then(() => {
				const text = `Successfully updated ${field_name.toUpperCase().replace(/_/g, " ")} to ${value}`
				message.success(text);
				refetchData();
			})
			.catch(() =>
				message.error(`Failed to update ${field_name} status`)
			);

		setDisplayTag(true);
		setClickedRowKey(null);
	};

	return isLoading ? null : (
		<div>
			<Tabs
				activeKey={searchParams.get('queue') || 'ballooning_status'}
				size="small"
				type="card"
				tabBarStyle={{ marginBottom: 0 }}
				onChange={handleTabChange}
				items={productionQueueTabs.map(({ label, field_name }) => {
					return {
						label: label,
						key: field_name,
						children: (
							<div className="bg-white border-r border-l border-b w-full pt-4">
								<CustomResponsiveTable
									columns={[
										...tableColumns,
										{
											title: `${label} Status`,
											key: field_name,
											fixed: 'right',
											render: (record: Lineitem) => (
												<div onClick={() => handleShowSelectStatus(record.id)} style={{ cursor: 'pointer' }}>
													{clickedRowKey === record.id && !displayTag ? (
														<div>
															<p style={{ whiteSpace: 'normal' }}>
																Select production status:
															</p>
															<Select
																value={record[field_name]}
																onSelect={(value) => handleStatusChange(record.id, value, field_name)}
																style={{ width: 200 }}
																onClick={(e) => e.stopPropagation()}
															>
																<Select.Option value="Not Started">Not Started</Select.Option>
																<Select.Option value="In Progress">In Progress</Select.Option>
																<Select.Option value="Completed">Completed</Select.Option>
															</Select>
														</div>
													) : (
														<Tag
															style={{ whiteSpace: 'normal' }}
															color={getOrderLineItemProductionStatusTagColor(record[field_name])}
														>
															{record[field_name]}
														</Tag>
													)}
												</div>
											),
											sorter: (a: Lineitem, b: Lineitem) => {
												if (a[field_name] < b[field_name]) return -1;
												if (a[field_name] > b[field_name]) return 1;
												return 0;
											},
										},
									]}
									data={data?.results}
									pagination={false}
									loading={isLoading || isFetching}
									footer={tableFooter}
									scroll={{ y: `calc(95vh - 250px)` }}
									onChange={handleTableChange}
									emptyTable={emptyTable}
								/>
							</div>
						),
					};
				})}
			/>
		</div>
	);
};

export default ProductionQueueTables;
