import logoWithERP from '../../assets/fastlane_logo.png';
import logoPB from '../../assets/partsbadger.png';

import { useWindowSize } from '../../hooks/useWindowSize';

interface Props {
	style?: React.CSSProperties;
}

export const Logo = ({ style }: Props) => {
	const { width } = useWindowSize();
	return (
		<div>
			<img
				src={width && width <= 992 ? logoPB : logoWithERP}
				alt="logo"
				style={style}
			/>
		</div>
	);
};
