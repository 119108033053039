import { createSlice } from '@reduxjs/toolkit';

interface StateProps {
	addManufacturingProcessModalVisible: boolean;
	editManufacturingProcessModalVisible: boolean;
}

const initialState: StateProps = {
	addManufacturingProcessModalVisible: false,
	editManufacturingProcessModalVisible: false,
};

export const manufacturing = createSlice({
	name: 'manufacturing',
	initialState,
	reducers: {
		showAddManufacturingProcessModal: (state) => {
			state.addManufacturingProcessModalVisible = true;
		},
		closeAddManufacturingProcessModal: (state) => {
			state.addManufacturingProcessModalVisible = false;
		},
		showEditManufacturingProcessModal: (state) => {
			state.editManufacturingProcessModalVisible = true;
		},
		closeEditManufacturingProcessModal: (state) => {
			state.editManufacturingProcessModalVisible = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	showAddManufacturingProcessModal,
	closeAddManufacturingProcessModal,
	showEditManufacturingProcessModal,
	closeEditManufacturingProcessModal,
} = manufacturing.actions;

export default manufacturing.reducer;
