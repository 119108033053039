import { Empty } from 'antd';
import { useGetMillAutomationSystemsQuery } from '../../redux/api/versabuilt';
import { Versabuilt } from '../../interfaces/VersaBuilt.Interface';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const VersaBuiltTable = () => {
	const { data, isLoading } = useGetMillAutomationSystemsQuery();

	const tableColumns = [
		{
			title: 'Name',
			render: (record: Versabuilt) => record?.display_name,
		},
		{
			title: 'Machine',
			render: (record: Versabuilt) => record?.machine?.name,
		},
		{
			title: 'VSC IP Address',
			render: (record: Versabuilt) => record?.vsc_ip_address,
		},
		{
			title: 'Robot IP Address',
			render: (record: Versabuilt) => record?.robot?.ip_address,
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no Machines to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CustomResponsiveTable
				columns={tableColumns}
				data={data}
				pagination={false}
				loading={isLoading}
				emptyTable={emptyTable}
			/>
		</div>
	);
};

export default VersaBuiltTable;
