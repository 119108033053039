import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Card,
	Col,
	Descriptions,
	Form,
	Row,
	Modal,
	InputNumber,
	notification,
	Tag,
	Button,
	Select,
	Typography,
} from 'antd';
import {
	useAddMaterialOrderConfirmationMutation,
	useGetMaterialOrderByIdQuery,
	useUpdateMaterialMutation,
	useGetPOPDFMutation, usePatchMaterialOrderMutation,
} from '../../../redux/api/material';

import { useParams } from 'react-router';
import MaterialTable from '../../../components/MaterialTable';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Upload from 'antd/es/upload/Upload';
import {
	LoadingOutlined,
	UploadOutlined,
	EditOutlined,
	DownloadOutlined,
} from '@ant-design/icons';
import { RootState } from '../../../redux/store';
import {
	showStatusModal,
	closeStatusModal,
} from '../../../redux/slices/materialOrderLineItem';
import { MaterialObject } from '../../../interfaces/Material.Interface';
import { showAddMaterialModal } from '../../../redux/slices/material';

import AddMaterialRemnantModal from '../../../components/modals/AddMaterialRemnantModal';

import { closeAddSalesOrderModal } from '../../../redux/slices/salesorder';
import {
	useGetOrderByIdQuery,
	useGetOrdersQuery,
} from '../../../redux/api/order';

import { STAFF_PORTAL_URL } from '../../../config/constants';

import Header from '../../../components/Header';

const { Paragraph } = Typography;


export interface MaterialFile {
	file: string | Blob;
}

const MaterialOrderDetails = () => {
	const { id } = useParams();

	const { data, refetch } = useGetMaterialOrderByIdQuery(
		{ id: id as string },
		{ skip: !id }
	);

	const ordersQuery = useGetOrdersQuery({});

	const dispatch = useDispatch();
	const lineItemStatusModalVisible = useSelector(
		(state: RootState) => state.materialOrderLineItemModel.statusModalVisible
	);
	const salesOrderModalVisible = useSelector(
		(state: RootState) => state.salesorder.addSalesOrderModalVisible
	);

	const [material, setMaterial] = useState<MaterialObject | null>(null);
	const [materialData, setMaterialData] = useState<MaterialObject[]>([]);
	const [statusWasUpdated, setStatusWasUpdated] = useState<boolean>(false);
	const [salesOrder, setSalesOrder] = useState<string | null>(null);
	const [salesOrderLineItem, setSalesOrderLineItem] = useState<string | null>(
		null
	);

	const order_retrieve = useGetOrderByIdQuery(
		{ id: salesOrder as string },
		{ skip: !salesOrder }
	);

	const [selectedForRemnant, setSelectedForRemnant] = useState<{
		id: string;
		maxLength: number;
		maxQuantity: number;
	}>();

	const PARTIALLY_DELIVERED = 'Partially Delivered';

	const [updateMaterialOrder, updateMaterialOrderResult] =
		useUpdateMaterialMutation();
	const [downloadPoPdf, downloadPoPdfResult] = useGetPOPDFMutation();

	const [patchMaterialOrder] = usePatchMaterialOrderMutation();

	const [addOrderConfirmation, result] =
		useAddMaterialOrderConfirmationMutation();

	const orderConfirmationUploadProps = {
		// accept: 'application/pdf',
		customRequest: async ({ file }: MaterialFile) => {
			const formData = new FormData();

			formData.append('order_confirmation', file);

			await addOrderConfirmation({ id: id, values: formData });
		},
		multiple: false,
		showUploadList: false,
	};

	const uploadCert = async (material: MaterialObject, file: any) => {
		const formData = new FormData();
		formData.append('cert', file);
		await updateMaterialOrder({ id: material.id, values: formData });
	};

	const openNotification = (message: string, type: string) => {
		if (type === 'success')
			return notification.success({ message: message, placement: 'topRight' });
		if (type === 'error')
			return notification.error({ message: message, placement: 'topRight' });

		return notification.info({ message: message, placement: 'topRight' });
	};

	useEffect(() => {
		if (data && !statusWasUpdated) {
			setMaterialData(data.material);
		}

		setStatusWasUpdated(false);
	}, [data]);

	useEffect(() => {
		if (result.isSuccess) {
			refetch();
		}

		if (result.isError) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			openNotification(result.error.data, 'error');
		}
	}, [result]);

	const updateMaterial = () => {
		return materialData.map((obj: MaterialObject) => {
			if (obj.id === updateMaterialOrderResult.data.id) {
				return {
					...obj,
					status: updateMaterialOrderResult.data.status,
					delivered_quantity: updateMaterialOrderResult.data.delivered_quantity,
					cert: updateMaterialOrderResult.data.cert,
				};
			}
			return obj;
		});
	};

	useEffect(() => {
		if (updateMaterialOrderResult.status === 'fulfilled') {
			if (updateMaterialOrderResult.isSuccess) {
				setMaterialData(updateMaterial());
				refetch();
				openNotification('Updated correctly', 'success');
			} else {
				openNotification('Something went wrong', 'error');
			}
		}

		if (
			updateMaterialOrderResult.status === 'rejected' &&
			updateMaterialOrderResult.isError
		) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			openNotification(updateMaterialOrderResult.error.data, 'error');
		}

		dispatch(closeAddSalesOrderModal());
	}, [updateMaterialOrderResult]);

	useEffect(() => {
		refetch();
	}, []);

	const isAvailable = (id: string) => {
		const selectedMaterialOrder = materialData.find((item) => {
			return item.order_line_item?.id == id && item?.id === material?.id;
		});

		const get_order = order_retrieve?.data;

		const getLineItem = get_order?.line_items?.find((item) => {
			return item.id === id;
		});

		if (selectedMaterialOrder) {
			return false;
		}

		if (getLineItem?.material || getLineItem?.material_request) {
			return true;
		}

		return false;
	};

	const updateMaterialOrderSalesOrderLineItem = async (
		material_id: string,
		salesOrderLineItems: string[]
	) => {
		// const formData = new FormData();

		const payload = { sales_order_line_items: salesOrderLineItems };

		// formData.append('sales_order_line_items', salesOrderLineItems);

		// formData.append('old_order_line_item_id', material?.order_line_item?.id);

		await updateMaterialOrder({ id: material_id, values: payload });
	};

	const handlePatchMaterialOrder = async (id: string, values: any) => {
		await patchMaterialOrder({ id: id, values: values });
		refetch();
	}

	return (
		<>
			<Header
				title="Material Order Details"
				showLinkButton={data?.status === 'Draft'}
				buttonText={'Edit Draft'}
				icon={<EditOutlined />}
				link={`/material/orders/add/${data?.id}`}
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: <Link to="/material/orders"> Material Orders </Link>,
					},
					{
						title: 'Material Order Details',
					},
				]}
			/>

			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Card
						title="Order Details"
						extra={
							<div className="flex flex-col md:flex-row justify-between">
								<div className="flex flex-col xl:flex-row">
									<span className="c-card-title mt-2 md:mt-0">Created by:</span>{' '}
									{data?.created_by} |{' '}
									<span className="md:mr-1">
										{dayjs(data?.created_time).format('MMMM DD, YYYY hh:mmA')}{' '}
									</span>
								</div>
								<div className="flex flex-col xl:flex-row mt-2 md:mt-0">
									<span className="c-card-title">Modified by:</span>{' '}
									{data?.modified_by} |{' '}
									<span className="md:mr-1">
										{dayjs(data?.modified_time).format('MMMM DD, YYYY hh:mmA')}
									</span>
								</div>
							</div>
						}
					>
						<Descriptions
							column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }}
						>
							<Descriptions.Item
								label="Material Vendor"
								className="font-semibold"
							>
								{data?.vendor?.display_name}
							</Descriptions.Item>

							<Descriptions.Item
								label="Requisition Number"
								className="font-semibold"
							>
								<Paragraph editable={{ onChange: (value: string) => {
										if (data && data.id) handlePatchMaterialOrder(data.id, {requisition_number: value});
									} }}>
									{data?.requisition_number !== 'undefined'
										? data?.requisition_number
										: ''}
								</Paragraph>

							</Descriptions.Item>

							<Descriptions.Item
								label={'Create Purchase Order'}
								className="font-semibold"
							>
								{data?.create_purchase_order ? 'Yes' : 'No'}
							</Descriptions.Item>

							<Descriptions.Item label="Order Date" className="font-semibold">
								{dayjs(data?.order_date).format('MMM-DD-YY')}
							</Descriptions.Item>

							<Descriptions.Item
								label="Vendor Order Number"
								className="font-semibold"
							>
								<Paragraph editable={{ onChange: (value: string) => {
										if (data && data.id) handlePatchMaterialOrder(data.id, {vendor_order_number: value});
									}}}>
									{data?.vendor_order_number ? data?.vendor_order_number : ''}
								</Paragraph>
							</Descriptions.Item>

							<Descriptions.Item label="Shipping Cost" className="font-semibold">
								<b>$ {Number(data?.shipping_cost)}</b>
							</Descriptions.Item>

							{data?.status !== 'Draft' && (
								<Descriptions.Item
									label="Order Confirmation"
									className="font-semibold"
								>
									{data?.order_confirmation?.file ? (
										<a
											href={data?.order_confirmation?.file}
											target="_blank"
											rel="noreferrer"
										>
											View
										</a>
									) : (
										<Form>
											<Form.Item name="order_confirmation" noStyle>
												<Upload {...orderConfirmationUploadProps}>
													<Link to="">
														<UploadOutlined /> Upload File{' '}
														{result.isLoading ? <LoadingOutlined /> : null}
													</Link>
												</Upload>
											</Form.Item>
										</Form>
									)}
								</Descriptions.Item>
							)}

							{data?.purchase_order_number ? (
								<Descriptions.Item label="P.O. PDF">
									<Button
										style={{ marginTop: -8 }}
										type={'link'}
										loading={downloadPoPdfResult.isLoading}
										onClick={() => {
											const newFormData = new FormData();
											newFormData.append('poId', data?.purchase_order_number);
											downloadPoPdf(newFormData);
										}}
									>
										<DownloadOutlined /> Download File
									</Button>
								</Descriptions.Item>
							) : (
								<Descriptions.Item label="P.O. PDF" >
									---
								</Descriptions.Item>
							)}

							<Descriptions.Item label="Total" className="font-semibold">
								<b>$ {Number(data?.total_amount)}</b>
							</Descriptions.Item>

							<Descriptions.Item label="Status">
								<Tag color={data?.status === 'Draft' ? 'orange' : 'success'}>
									{data?.status}
								</Tag>
							</Descriptions.Item>

							{data?.purchase_order_number ? (
								<Descriptions.Item
									label="P.O. Number"
									className="font-semibold"
								>
									<Paragraph
										copyable={{
											text: data?.purchase_order_name ? data?.purchase_order_name : data?.purchase_order_number,
											tooltips: ['Copy', 'Copied to clipboard'],
										}}
									>
										<Link
											to={`${STAFF_PORTAL_URL}/purchase-orders/${data?.purchase_order_number}/`}
											target="_blank"
											rel="noreferrer"
										>
											{data?.purchase_order_name ? data?.purchase_order_name : data?.purchase_order_number}

										</Link>
									</Paragraph>
								</Descriptions.Item>
							) : (
								<Descriptions.Item label="P.O. Number">
									---
								</Descriptions.Item>
							)}

						</Descriptions>
					</Card>
				</Col>
				<Col span={24}>
					<Card title="Material Order Line Items" className="card-padding">
						<MaterialTable
							data={materialData}
							showVendorNumber={false}
							showMaterialCertUploader={data?.status === 'Draft' ? false : true}
							isLoading={false}
							showMaterialSelectButton={false}
							showPromisedDeliveryDate={true}
							onSelectMaterial={function (): void {
								throw new Error('Function not implemented.');
							}}
							onChangeStatus={(material: MaterialObject) => {
								setMaterial(material);
								if (material.status === PARTIALLY_DELIVERED) {
									return dispatch(showStatusModal());
								} else {
									updateMaterialOrder({
										id: material.id,
										values: { status: material.status },
									});
									setStatusWasUpdated(true);
								}
							}}
							onUploadCert={(material: MaterialObject, file: any) => {
								uploadCert(material, file);
							}}
							showRemnantBtn={data?.status === 'Draft' ? false : true}
							onSelectMaterialForRemnant={(material) => {
								setSelectedForRemnant(material);
								dispatch(showAddMaterialModal());
							}}
							onEditLineItem={(
								material_id: string,
								line_items_array: string[]
							) => {
								console.log('onEditLineItem', material_id, line_items_array);
								updateMaterialOrderSalesOrderLineItem(
									material_id,
									line_items_array
								);
								// setMaterial(material);

								// if (material.order) {
								// 	setSalesOrder(material?.order);
								// }
								// if (material.order_line_item) {
								// 	setSalesOrderLineItem(material?.order_line_item?.id);
								// } else {
								// 	setSalesOrderLineItem(null);
								// }
								// dispatch(showAddSalesOrderModal());
							}}
						/>
					</Card>
				</Col>
			</Row>
			<Modal
				centered
				title="Edit Sales Order Line Item"
				open={salesOrderModalVisible}
				onCancel={() => {
					dispatch(closeAddSalesOrderModal());
				}}
				footer={[
					<Button
						key="back"
						onClick={() => {
							dispatch(closeAddSalesOrderModal());
						}}
					>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={false}
						onClick={() => {
							// updateMaterialOrderSalesOrderLineItem();
						}}
					>
						Submit
					</Button>,
				]}
			>
				<Row gutter={[8, 8]}>
					<Col span={12}>
						Sales Order
						<Select
							allowClear={true}
							placeholder="Select order"
							defaultValue={salesOrder}
							value={salesOrder}
							onChange={(value) => {
								setSalesOrder(value);
								setSalesOrderLineItem(null);
							}}
							style={{ width: '100%' }}
						>
							{ordersQuery?.data?.results?.map((order, index) => (
								<Select.Option key={index} value={order.id}>
									{order?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
					<Col span={12}>
						Related Sales Order Line Item
						<Select
							allowClear={true}
							placeholder="Select line item"
							defaultValue={salesOrderLineItem}
							value={salesOrderLineItem}
							onChange={(item) => {
								setSalesOrderLineItem(item);
							}}
							style={{ width: '100%' }}
						>
							{order_retrieve?.data?.line_items?.map((item, index) => (
								<Select.Option
									key={index}
									value={item.id}
									disabled={isAvailable(item.id)}
								>
									{item?.name}
								</Select.Option>
							))}
						</Select>
					</Col>
				</Row>
			</Modal>
			<Modal
				closable={false}
				title="Partially Delivered"
				centered
				open={lineItemStatusModalVisible}
				onOk={() => {
					if (material?.delivered_quantity) {
						updateMaterialOrder({
							id: material.id,
							values: {
								status: material.status,
								delivered_quantity: material.delivered_quantity,
							},
						});
						setMaterial(null);
						setStatusWasUpdated(true);
					}
					dispatch(closeStatusModal());
				}}
				onCancel={() => {
					dispatch(closeStatusModal());
				}}
				width={400}
			>
				<p className="mb-3 mt-5">Delivered Quantity</p>

				<InputNumber
					min={0}
					max={material?.quantity_ordered}
					style={{ width: '100%' }}
					value={material?.delivered_quantity}
					onChange={(value) => {
						setMaterial(
							Object.assign({}, material, { delivered_quantity: value })
						);
					}}
				/>
			</Modal>

			<AddMaterialRemnantModal
				material={selectedForRemnant}
				refetch={() => {
					return;
				}}
			/>
			{/* <SalesOrderLineItemModal material={material ? material : undefined} orders={ordersQuery ? ordersQuery.data : []}/> */}
		</>
	);
};

export default MaterialOrderDetails;
