import { Button, Empty } from 'antd';
import { useGetMachinesQuery } from '../../redux/api/machines';
import { Link } from 'react-router-dom';
import { MachineObject } from '../../interfaces/Machines.interfaces';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const MachinesTable = () => {
	const { data, isLoading } = useGetMachinesQuery({});

	const tableColumns = [
		{
			title: 'Name',
			render: (record: MachineObject) => record.name,
		},
		{
			title: 'Manufacturer',
			render: (record: MachineObject) =>
				record?.machine_model.manufacturer.name,
		},
		{
			title: 'Model',
			render: (record: MachineObject) => record?.machine_model.model,
		},
		{
			title: 'IP Address',
			render: (record: MachineObject) => record.ip_address,
		},
		{
			title: 'Mill Automation System',
			render: (record: MachineObject) =>
				record?.mill_automation_system?.name as string,
		},
		{
			title: '',
			render: (record: MachineObject) => (
				<Link to={`/machines/${record.id}`}>
					<Button type="primary">Details</Button>
				</Link>
			),
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no Machines to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CustomResponsiveTable
				columns={tableColumns}
				data={data}
				pagination={false}
				loading={isLoading}
				emptyTable={emptyTable}
			/>
		</div>
	);
};

export default MachinesTable;
