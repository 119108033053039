import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithInterceptor} from '../helpers'

// Define a service using a base URL and expected endpoints
export const api = createApi({
	reducerPath: 'api',
	baseQuery: baseQueryWithInterceptor,
	tagTypes: ['getMaterialOrders', "getMaterialRemnant"],
	endpoints: () => ({})
});
