import { configureStore } from '@reduxjs/toolkit';
import reducers from '../slices';
import { api } from '../slices/api';

const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware)
});

export type appDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
