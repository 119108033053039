import { Card, Col, Row } from 'antd';
import MachineCycleTimeTable from '../../components/MachineCycleTimeTable';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';

function MachineCycleTime() {
	return (
		<>
			<Header
				title="Machine Cycle Times"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Machine Cycle Times',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
						<MachineCycleTimeTable />
					</Card>
				</Col>
			</Row>
		</>
	);
}

export default MachineCycleTime;
