import { useState } from 'react';
import { Button, Input } from 'antd';

function ConnectDevice() {
	const [isConnected, setIsConnected] = useState<boolean | null>(null);

	interface DeviceInterface {
		selectConfiguration: (config: number) => Promise<void>;
		claimInterface: (interfaceNumber: number) => Promise<void>;
		open: () => Promise<void>;
		controlTransferOut: (res: ResponseControlOut) => Promise<void>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		transferIn: (endpointNumber: number, length: number) => Promise<any>;
	}

	interface ResponseControlOut {
		requestType: string;
		recipient: string;
		request: number;
		value: number;
		index: number;
	}

	const onRequestUSBAccessHandler = async () => {
		let device: DeviceInterface;

		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const selectedDevice = await (navigator as any).usb.requestDevice({
				filters: [{ serialNumber: '00000000011C' }],
			});
			device = selectedDevice;
			await device.open(); // Begin a session.
			await device.selectConfiguration(1); // Select configuration #1 for the device.
			await device.claimInterface(1); // Request exclusive control over interface #2.
			await device.controlTransferOut({
				requestType: 'class',
				recipient: 'interface',
				request: 0x22,
				value: 0x01,
				index: 0x02,
			}); // Ready to receive data
			const result = await device.transferIn(5, 64); // Waiting for 64 bytes of data from endpoint #5.
			const decoder = new TextDecoder();
			console.log('Received: ' + decoder.decode(result.data));
			setIsConnected(true);
		} catch (error) {
			console.error(error);
			setIsConnected(false);
		}
	};

	const onRequestBluetoothAccessHandler = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const device = await (navigator as any).bluetooth.requestDevice({
				filters: [{ serialNumber: '00000000011C' }],
			});
			console.log(device);
			setIsConnected(true);
		} catch (error) {
			console.error(error);
			setIsConnected(false);
		}
	};

	return (
		<>
			<Button onClick={onRequestUSBAccessHandler}>Request USB Access</Button>
			<Button onClick={onRequestBluetoothAccessHandler}>
				Request Bluetooth Access
			</Button>
			<pre>
				{JSON.stringify(
					isConnected ? 'Device Connected' : 'Not Connected',
					null,
					3
				)}
			</pre>
			<Input type="text" />
		</>
	);
}

export default ConnectDevice;
