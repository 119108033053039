import { Col, Row } from 'antd';
import OrdersTable from '../../components/OrdersTable';

import Header from '../../components/Header';
import { Link } from 'react-router-dom';

function FastlaneOrders() {
	return (
		<>
			<Header
				title="Orders"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Orders',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<OrdersTable />
				</Col>
			</Row>
		</>
	);
}

export default FastlaneOrders;
