import { useEffect } from 'react';
import { Modal, Form, Row, Col, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddToolHolderModelModal } from '../../../redux/slices/toolHolderModel';
import {
	useAddToolHolderModelMutation,
	useGetToolHolderColletSizesQuery,
	useGetToolHolderStylesQuery,
	useGetToolHolderTypesQuery,
} from '../../../redux/api/tool-holders';
import { useGetManufacturersQuery } from '../../../redux/api/core';
import { RootState } from '../../../redux/store';

export interface HolderModel {
	refetch: () => void;
}

const AddToolHolderModel = (props: HolderModel) => {
	const dispatch = useDispatch();

	const visible = useSelector(
		(state: RootState) => state.toolHolderModel.addToolHolderModelModalVisible
	);

	const getManufacturers = useGetManufacturersQuery();
	const getToolHolderColletSizes = useGetToolHolderColletSizesQuery();
	const getToolHolderStyles = useGetToolHolderStylesQuery();
	const getToolHolderTypes = useGetToolHolderTypesQuery();

	const [form] = Form.useForm();

	const [addToolHolderModel, result] = useAddToolHolderModelMutation();

	const closeModal = () => {
		form.resetFields();
		dispatch(closeAddToolHolderModelModal());
	};

	const handleOk = async () => {
		try {
			const values = await form.validateFields();

			await addToolHolderModel(values);
		} catch (error) {
			console.log('Validate Failed:', error);
		}
	};

	/*
  const onChange = (e) => {
    setValue(e.target.value);
  };*/

	useEffect(() => {
		if (result.isSuccess) {
			closeModal();

			props.refetch();
		}
	}, [result]);

	return (
		<>
			<Modal
				title="Add Tool Holder Model"
				width={720}
				open={visible}
				onOk={handleOk}
				onCancel={closeModal}
				bodyStyle={{ paddingTop: '1rem' }}
				forceRender
				centered={true}
				maskClosable={false}
			>
				<Form form={form} layout="vertical" requiredMark={false}>
					<Row gutter={[8, 8]}>
						<Col xs={24} xxl={6} span={6}>
							<Form.Item
								name="manufacturer"
								label="Manufacturer"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder="Select the Manufacturer"
									options={getManufacturers?.data?.map((item) => {
										return { value: item.id, label: item.name };
									})}
								></Select>
							</Form.Item>
						</Col>
						<Col xs={24} xxl={6} span={6}>
							<Form.Item
								name="type"
								label="Type"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder="Select the Type"
									options={getToolHolderTypes?.data?.map((item) => {
										return { value: item.id, label: item.name };
									})}
								></Select>
							</Form.Item>
						</Col>
						<Col xs={24} xxl={6} span={6}>
							<Form.Item
								name="style"
								label="Style"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder="Select the Style"
									options={getToolHolderStyles?.data?.map((item) => {
										return { value: item.id, label: item.name };
									})}
								></Select>
							</Form.Item>
						</Col>
						<Col xs={24} xxl={6} span={6}>
							<Form.Item
								name="collet_size"
								label="Collet Size"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder="Select the Collet Size"
									options={getToolHolderColletSizes?.data?.map((item) => {
										return { value: item.id, label: item.name };
									})}
								></Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col xs={24} xxl={8} span={8}>
							<Form.Item
								name="model"
								label="Model Number"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} xxl={16} span={16}>
							<Form.Item
								name="url"
								label="Link to Webpage"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default AddToolHolderModel;
