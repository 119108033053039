import { createSlice } from '@reduxjs/toolkit';

interface MaterialState {
	addSalesOrderModalVisible: boolean;
	refreshSalesOrderLineItem: boolean
}

const initialState: MaterialState = {
	addSalesOrderModalVisible: false,
	refreshSalesOrderLineItem: false
};

export const salesOrder = createSlice({
	name: 'material',
	initialState,
	reducers: {
		showAddSalesOrderModal: (state) => {
			state.addSalesOrderModalVisible = true;
		},
		closeAddSalesOrderModal: (state) => {
			state.addSalesOrderModalVisible = false;
		},
		setRefreshSalesOrderLineItem:	(state, action) => {
			state.refreshSalesOrderLineItem = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { showAddSalesOrderModal, closeAddSalesOrderModal, setRefreshSalesOrderLineItem } = salesOrder.actions;

export default salesOrder.reducer;
