import { Button, Form, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import {useQueryParameters} from "../../../hooks";

const FormItem = Form.Item;

export const ReceivingFiler = () => {
	const queryParams = useQueryParameters();
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const onFinish = (values: { search: string; production_method: string; }) => {
		if (values.search) {
			queryParams.set('search', values.search);
		}
		if (values.production_method) {
			queryParams.set('production_method', values.production_method);
		}
		// remove param page if exists
		queryParams.delete('page');

		navigate({ search: queryParams.toString() });
	};

	return (
		<div>
			<Form
				name="order-filter"
				form={form}
				layout={'horizontal'}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
			>
				<div className="flex flex-wrap">
					<div className="w-full sm:w-1/2 md:w-2/3 lg:w-3/4 xl:w3/4 px-2">
						<FormItem
							label="Search"
							name={'search'}
							initialValue={queryParams.get('search')}
						>
							<Input.Search placeholder="Enter Sales Order or Purchase Order number" allowClear />
						</FormItem>
					</div>
					<div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-2">
						<Form.Item>
							<Button
								onClick={() => {
									form.setFieldValue('search', '');
									form.setFieldValue('production_method', '');
									navigate(location.pathname, { replace: true });
								}}
							>
								Reset
							</Button>
							<Button
								type="primary"
								htmlType={'submit'}
								style={{ marginLeft: 8 }}
							>
								Filter
							</Button>
						</Form.Item>
					</div>
				</div>
			</Form>
		</div>
	);
};
