import { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Card,
	Col,
	DatePicker,
	Divider,
	Form,
	InputNumber,
	Row,
} from 'antd';
import { useGetMaterialQuery } from '../../../redux/api/material';
import {
	useAddOrderMutation,
} from '../../../redux/api/order';
//import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal/Modal';
import MaterialTable from '../../../components/MaterialTable';
import { MaterialObject } from '../../../interfaces/Material.Interface';

const AddOrder = () => {
	//const navigate = useNavigate();

	const [isMaterialModalVisible, setIsMaterialModalVisible] = useState(false);

	const [form] = Form.useForm();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
	//const [/*fieldValues,*/ setFieldValues] = useState<any[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [orderConfirmationFile /*, setOrderConfirmationFile*/] = useState<
		string | Blob
	>('');
	const [lineItemKey, setLineItemKey] = useState<string>('');

	const materialQuery = useGetMaterialQuery();

	const  result = useAddOrderMutation();

	/*
  const uploadProps = {
    accept: "application/pdf",
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    multiple: false,
    beforeUpload: () => false,
    onChange: ({ file }) => {
      setOrderConfirmationFile(file);
    },
  };*/

	const onFinish = async () => {
		try {
			const values = await form.validateFields();

			const formData = new FormData();

			formData.append('vendor', values.vendor);
			formData.append('vendor_order_number', values.vendor_order_number);
			formData.append('purchase_order_number', values.purchase_order_number);
			formData.append('order_date', dayjs(values.order_date).toISOString());
			formData.append(
				'promised_delivery_date',
				dayjs(values.promised_delivery_date).toISOString()
			);

			for (const line_item of values.line_items) {
				formData.append('line_item', JSON.stringify(line_item));
			}

			formData.append('order_confirmation', orderConfirmationFile);

			// await addOrder(formData);

			console.log(values);
		} catch (error) {
			console.log('Validate Failed:', error);
		}
	};

	function onSelectMaterial(value: string) {
		setIsMaterialModalVisible(false);

		form.setFieldValue(['line_items', lineItemKey, 'material'], value);

		// setFieldValues(
		//   produce(fieldValues, (draft) => {
		//     draft.find(
		//       (field) =>
		//         field.name[0] === "line_items" &&
		//         field.name[1] === key &&
		//         field.name[2] === fieldName
		//     ).value = value;
		//   })
		// );
		return true;
	}

	useEffect(() => {
		if (result) {
		//	navigate(`/orders/${result.}`);
		}
	}, [result]);

	return (
		<>
			<Alert
				type="warning"
				showIcon={true}
				description="Work in progress. Stuff might break..."
			/>
			<div className="flex flex-column justify-between py-4">
				<h1 className="text-2xl font-bold m-0">Add Fastlane Order</h1>
			</div>
			<Card className="shadow-sm">
				<Form
					name="dynamic_form_complex"
					form={form}
					// fieldValues={fieldValues}
					onFinish={onFinish}
					layout="vertical"
					autoComplete="off"
					requiredMark={false}
				>
					<Row gutter={[8, 8]}>
						<Col xl={4} lg={6}>
							<Form.Item
								name="order_date"
								label="Order Date"
								initialValue={dayjs()}
								rules={[
									{
										required: true,
										message: 'Required',
									},
								]}
							>
								<DatePicker className="w-full" />
							</Form.Item>
						</Col>
						<Col xl={4} lg={6}>
							<Form.Item
								name="shop_due_date"
								label="Shop Due Date"
								rules={[
									{
										required: true,
										message: 'Required',
									},
								]}
							>
								<DatePicker className="w-full" />
							</Form.Item>
						</Col>
					</Row>
					{/* <Row>
            <Col span={24}>
              <Form.Item
                name="order_confirmation"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Order Confirmation</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row> */}
					<Divider style={{ margin: 0, padding: '1rem' }} />
					<Form.List
						name="line_items"
						// initialValue={[{ quantity: 1, material: null }]}
					>
						{(fields, { add, remove }) => (
							<>
								{fields.map(({ key, name, /* value,*/ ...restField }) => {
									const material = form.getFieldValue([
										'line_items',
										name,
										'material',
									]);

									return (
										<>
											<Row key={key} gutter={[16, 8]}>
												<Col span={2}>
													<Form.Item
														{...restField}
														name={[name, 'quantity']}
														label="Qty"
														initialValue={1}
														rules={[
															{
																required: true,
																message: 'Required',
															},
														]}
													>
														<InputNumber min={1} style={{ width: '100%' }} />
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														{...restField}
														label="Material"
														name={[name, 'material']}
														rules={[
															{
																required: true,
																message: 'Required',
															},
														]}
													>
														{material ? (
															<div className="flex items-center">
																<span>
																	{
																		materialQuery?.data?.results.find(
																			(item) => item.id === material
																		)?.display_name
																	}
																</span>
																<Button
																	type="link"
																	onClick={() => {
																		setLineItemKey(name.toString());
																		setIsMaterialModalVisible(true);
																	}}
																>
																	Change
																</Button>
															</div>
														) : (
															<Button
																onClick={() => {
																	setLineItemKey(name.toString());
																	setIsMaterialModalVisible(true);
																}}
															>
																Select Material
															</Button>
														)}
													</Form.Item>
												</Col>
												<Col></Col>

												<Col span={1}>
													<MinusCircleOutlined onClick={() => remove(name)} />
												</Col>
											</Row>
											<Divider style={{ margin: 0, padding: '1rem 0 0 0' }} />
										</>
									);
								})}
								<Form.Item>
									<Button
										type="default"
										onClick={() => add()}
										block
										icon={<PlusOutlined />}
									>
										Add Line Item
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
						>
							Submit
						</Button>
					</Form.Item>
					{/* <pre>{JSON.stringify(form.getFieldsValue(), null, 3)}</pre> */}
				</Form>
			</Card>
			<Modal
				title="Select Material"
				width={960}
				open={isMaterialModalVisible}
				style={{ padding: 0 }}
				onCancel={() => setIsMaterialModalVisible(false)}
				bodyStyle={{ paddingTop: '1rem' }}
				footer={false}
				maskClosable={false}
			>
				<MaterialTable
					data={materialQuery?.data?.results as MaterialObject[]}
					showMaterialSelectButton={true}
					onSelectMaterial={onSelectMaterial}
					isLoading={false}
					showVendorNumber={false}
					showMaterialCertUploader={false} 
					onChangeStatus={function (material: MaterialObject): void {
							console.log(material);
							throw new Error('Function not implemented.');
						} 
					}
				/>
			</Modal>
		</>
	);
};

export default AddOrder;
