import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface PageHeader {
	name: string;
	addButton: boolean;
}

const PageHeader = (props: PageHeader) => {
	return (
		<div className="flex flex-column justify-between py-4">
			<h1 className="text-2xl font-bold m-0">{props.name}</h1>
			{props.addButton ? (
				<Button icon={<PlusOutlined />} type="default">
					Add {props.name}
				</Button>
			) : null}
		</div>
	);
};

export default PageHeader;
