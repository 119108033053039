import { OrderQuery, useGetOrdersQuery } from '../../redux/api/order';
import PaginationComponent from '../Footers/Pagination.tsx';
import { Card, Tag, Empty, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { OrderListProps } from '../../interfaces/Ordel.Interface';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router';
import { SalesOrderFilter } from '../../pages/Orders/SalesOrderFilter.tsx';
import { useQueryParameters } from '../../hooks';
import dayjs from 'dayjs';
import {
	getOrderStatusTagColor,
	getProductionMethodTagColor,
} from '../../utils';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const OrdersTable = () => {
	const navigate = useNavigate();
	const queryParams = useQueryParameters();
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const searchUrl = queryParams.get('search') || '';
	const ordering = queryParams.get('ordering') || '-created_time';
	const production_method = queryParams.get('production_method') || '';
	const status = queryParams.get('status') || '';

	const orderQuery: OrderQuery = {};

	if (currentPageUrl) {
		orderQuery.page = currentPageUrl;
	}

	if (searchUrl) {
		orderQuery.search = searchUrl;
	}

	if (production_method) {
		orderQuery.production_method = production_method;
	}

	if (ordering && ordering !== '') {
		orderQuery.ordering = ordering;
	}

	if (status && status !== '') {
		orderQuery.status = status;
	}

	const { data, error, isLoading, isFetching } = useGetOrdersQuery(orderQuery, {
		refetchOnMountOrArgChange: true,
	});

	if (error) {
		navigate('/orders', { replace: true });
	}

	if (!data) {
		return <div>Loading...</div>;
	}

	const { count, results } = data;

	const handleTableChange: TableProps<OrderListProps>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		if (_filters['status']) {
			if (_filters['status'].length > 0) {
				queryParams.set('status', _filters['status']?.toString());
			}
			navigate({ search: queryParams.toString() });
		} else {
			queryParams.delete('status');
			navigate({ search: queryParams.toString() });
		}

		if (extra.action === 'sort') {
			const orderType = (sorter as SorterResult<OrderListProps>)?.order;
			const columnKey = (sorter as SorterResult<OrderListProps>)?.columnKey;
			const sorterColumn = (sorter as SorterResult<OrderListProps>)?.column;

			const column =
				orderType === 'ascend' || orderType === undefined
					? columnKey
					: '-' + columnKey;
			if (column !== undefined && sorterColumn !== undefined) {
				queryParams.set('ordering', column?.toString());
			} else {
				queryParams.delete('ordering');
			}
			navigate({ search: queryParams.toString() });
		}
	};

	const tableColumns: ColumnsType<OrderListProps> = [
		{
			title: 'Order name',
			// dataIndex: 'name',
			key: 'name',
			render: (record: OrderListProps) => (
				<Link to={`/orders/${record?.id}`}>{record.name}</Link>
			),
			sorter: (a, b) => {
				if (a < b) return -1;
				if (a > b) return 1;
				return 0;
			},
		},
		{
			title: 'Line Items Qty',
			dataIndex: 'line_items_qty',
			key: 'line_items_qty',
			sorter: false,
		},
		{
			title: 'Total Parts Qty',
			dataIndex: 'total_qty_in_line_item',
			key: 'total_qty_in_line_item',
			sorter: false,
		},
		{
			title: 'Received Date',
			key: 'created_time',
			render: (record: OrderListProps) =>
				record?.created_time
					? dayjs(record.created_time).format('YYYY-MM-DD')
					: '',
			sorter: (a, b) => {
				const dateA = dayjs(a.created_time).format('YYYY-MM-DD') || '';
				const dateB = dayjs(b.created_time).format('YYYY-MM-DD') || '';
				return dateA.localeCompare(dateB);
			},
		},
		{
			title: 'Soonest Due Date',
			dataIndex: 'soonest_due_date',
			key: 'soonest_due_date',
			sorter: false,
		},
		{
			title: 'Production Method',
			key: 'production_method',
			render: (record: OrderListProps) => {
				return record.methods?.map(
					(item: { name: string; qty: number }, index: number) => {
						return (
							<Tag key={index} color={getProductionMethodTagColor(item.name)}>
								{item.name || 'Not Set'} ({item.qty})
							</Tag>
						);
					}
				);
			},
		},
		{
			title: 'Status',
			key: 'status',
			render: (record: OrderListProps) => (
				<Tag color={getOrderStatusTagColor(record.status)}>{record.status}</Tag>
			),
			sorter: true,
			onFilter: (value: boolean | React.Key, record) => record.status === value,
			filters: [
				{
					text: 'Received',
					value: 'Received',
				},
				{
					text: 'In Progress',
					value: 'In Progress',
				},
				{
					text: 'Completed',
					value: 'Completed',
				},
				{
					text: 'Partially On Hold',
					value: 'Partially On Hold',
				},
				{
					text: 'On Hold',
					value: 'On Hold',
				},
				{
					text: 'Partially Cancelled',
					value: 'Partially Cancelled',
				},
				{
					text: 'Partially On Hold & Cancelled',
					value: 'Partially On Hold & Cancelled',
				},
				{
					text: 'Cancelled',
					value: 'Cancelled',
				},
			],
		},
	];

	const tableFooter = () => {
		return (
			<div className="flex justify-between items-center">
				<div className="ml-auto">
					<PaginationComponent pageSize={20} total={count} />
				</div>
			</div>
		);
	};

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no orders to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div>
			<div>
				<SalesOrderFilter />
			</div>
			<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<CustomResponsiveTable
						columns={tableColumns}
						data={results}
						pagination={false}
						loading={!data || isLoading || isFetching}
						footer={tableFooter}
						onChange={handleTableChange}
						emptyTable={emptyTable}
					/>
				</div>
			</Card>
		</div>
	);
};

export default OrdersTable;
