import { Pagination, PaginationProps } from 'antd';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

const PaginationComponent = (props: PaginationProps) => {
	const history = useNavigate();
	const searchParams = new URLSearchParams(useLocation().search);
	const page = searchParams?.get('page');
	const current = page ? parseInt(page) : 1;

	return (
		<div style={{ margin: 'auto', textAlign: 'center' }}>
			<Pagination
				showSizeChanger={false}
				current={current}
				onChange={(page) => {
					searchParams.set('page', page.toString());
					history(`?${searchParams.toString()}`, { replace: true });
				}}
				{...props}
			/>
		</div>
	);
};

export default PaginationComponent;
