import { Link, useLocation } from 'react-router-dom';
import { Button, Layout, Menu, MenuProps } from 'antd';
import { menu } from '../../config/Menu';
import { ReactNode, useEffect, useState } from 'react';
import { Logo } from '../../components/Logo';
import { clearAuthToken } from '../../redux/slices/user';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LogoutOutlined } from '@ant-design/icons';
import { useWindowSize } from '../../hooks/useWindowSize';

const { Sider } = Layout;
type Props = { children: ReactNode };
export default function MainLayout({ children }: Props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [current, setCurrent] = useState(location.pathname);

	const { width } = useWindowSize();

	function handleLogout() {
		dispatch(clearAuthToken());
	}

	const onClick: MenuProps['onClick'] = (e) => {
		setCurrent(e.key);
	};

	const extractUUID = (path: string) => {
		const uuidRegex =
			/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
		const match = path.match(uuidRegex);
		return match ? match[0] : null;
	};

	useEffect(() => {
		const path = location.pathname;
		const uuid = extractUUID(path);

		if (uuid) {
			// If UUID is present, filter it out from substrings
			const substringsToCheck = path
				.split('/')
				.filter((item) => item !== '' && item !== uuid);

			let match: { path: string; label: string } | undefined;

			menu.some((item) => {
				if (item.children) {
					match = item.children.find((subitem) => {
						const containsAll = substringsToCheck.every((substring) =>
							subitem.path.includes(substring)
						);
						return containsAll;
					});

					return Boolean(match);
				}
			});

			if (match) {
				setCurrent(match.path);
			}
		} else {
			// If no UUID, set the current path
			setCurrent(path);
		}
	}, [location.pathname]);

	const defaultOpenKeys = menu
		.filter((item) => item.children?.find((subitem) => subitem.path == current))
		.map((item) => item.label);

	return (
		<Layout style={{ height: '100%', minHeight: '100vh' }}>
			<Layout>
				<Sider
					breakpoint="lg"
					theme="light"
					// onBreakpoint={(broken) => {
					//   console.log(broken);
					// }}
					// onCollapse={(collapsed, type) => {
					//   console.log(collapsed, type);
					// }}
				>
					<div className={'cursor-pointer mt-2'} onClick={() => navigate('/')}>
						<Logo
							style={{
								display: 'flex',
								margin: 'auto',
								width: '140px',
							}}
						/>
					</div>
					<div className="flex flex-col justify-between">
						<Menu
							theme="light"
							mode="inline"
							onClick={onClick}
							selectedKeys={[current]}
							defaultOpenKeys={defaultOpenKeys}
							items={menu.map((item) => {
								return {
									key: item.label,
									label: item.path ? (
										<Link to={item?.path}>{item?.label}</Link>
									) : (
										item?.label
									),
									children: item?.children?.map((child) => {
										return {
											key: child.path,
											label: <Link to={child.path}>{child?.label}</Link>,
										};
									}),
								};
							})}
							style={{ marginBottom: 'auto !important' }}
						/>
						<div className="flex justify-center">
							<Button className="logout-button" onClick={handleLogout}>
								{width && width <= 992 ? (
									<>
										<LogoutOutlined />
									</>
								) : (
									<>
										<LogoutOutlined /> Log Out
									</>
								)}
							</Button>
						</div>
					</div>
				</Sider>
				<Layout>
					<div className="flex justify-around h-full">{children}</div>
				</Layout>
			</Layout>
		</Layout>
	);
}
