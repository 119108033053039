import { Flex, Typography } from 'antd';

const { Title } = Typography;

interface Props {
	height: string;
	width: string;
	length: string;
	volume: string;
	bbox_volume: string;
}

export const PartDimensions = (props: Props) => {
	function mm_to_in(value: number | string) {
		return (Number(value) / 25.4).toFixed(3);
	}

	function mm3_to_in3(value: string) {
		return (Number(value) / 16387).toFixed(3);
	}
	return (
		<Flex vertical className="mb-10">
			<Title level={5}>Dimensions</Title>
			<Flex wrap="wrap" style={{ columnGap: '2rem', rowGap: '1rem' }}>
				<Flex vertical={true} align="start">
					<span className="text-slate-400">Height</span>
					<span>{mm_to_in(props.height)} in.</span>
				</Flex>

				<Flex vertical={true} align="start">
					<span className="text-slate-400">Width</span>
					<span>{mm_to_in(props.width)} in.</span>
				</Flex>

				<Flex vertical={true} align="start">
					<span className="text-slate-400">Length</span>
					<span>{mm_to_in(props.length)} in.</span>
				</Flex>

				<Flex vertical={true} align="start">
					<span className="text-slate-400">Part Volume</span>
					<span>
						{mm3_to_in3(props.volume)} in<sup>3</sup>
					</span>
				</Flex>

				<Flex vertical={true} align="start">
					<span className="text-slate-400">Bounding Box Volume</span>
					<span>
						{mm3_to_in3(props.bbox_volume)} in<sup>3</sup>
					</span>
				</Flex>
			</Flex>
		</Flex>
	);
};
