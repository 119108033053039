import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store/index.ts';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { BrowserRouter } from 'react-router-dom';
import { blue } from '@ant-design/colors';

import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

if (import.meta.env.VITE_ENVIRONMENT) {
	Sentry.init({
		environment: import.meta.env.VITE_ENVIRONMENT,
		dsn: 'https://7496b02daec2675936d16cf0e5d0570f@o307418.ingest.sentry.io/4506158153400320',
		integrations: [
			new CaptureConsoleIntegration({
				// array of methods that should be captured
				// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
				levels: ['error'],
			}),
			// new Sentry.BrowserTracing({
			//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			// }),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<BrowserRouter>
			<React.StrictMode>
				<StyleProvider hashPriority="high">
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: '#1c2a38',
								colorLink: blue.primary,
								colorLinkHover: blue[3],
								colorPrimaryBg: '#e0e6ec',
								colorPrimaryBgHover: '#ecf0f4',
								fontFamily: 'Inter',
								fontWeightStrong: 700,
								borderRadius: 4,
								colorBorder: '#cbcbcb',
								colorBorderSecondary: '#eaeaea',
							},
							components: {
								Table: {
									headerBg: '#ffffff',
								},
							},
						}}
					>
						<App />
					</ConfigProvider>
				</StyleProvider>
			</React.StrictMode>
		</BrowserRouter>
	</Provider>
);
