import { Button, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useGetToolHoldersQuery } from '../../redux/api/tool-holders';
import { ToolObject } from '../../interfaces/ToolHolder.Interface';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const ToolHolderTable = () => {
	const { data, isLoading } = useGetToolHoldersQuery();

	const tableColumns = [
		{
			title: 'Manufacturer',
			render: (record: ToolObject) =>
				record?.tool_holder_model?.manufacturer?.name,
		},
		{
			title: 'TMS ID',
			render: (record: ToolObject) => record?.tms_id,
		},
		{
			title: 'Model',
			render: (record: ToolObject) => record?.tool_holder_model?.model,
		},
		{
			title: 'Type',
			render: (record: ToolObject) => record?.tool_holder_model?.type?.name,
		},
		{
			title: 'Style',
			render: (record: ToolObject) => record?.tool_holder_model?.style?.name,
		},
		{
			title: 'Bore Diameter',
			render: (record: ToolObject) =>
				record?.tool_holder_model?.shank_diameter_inch,
		},
		{
			render: () => <Button icon={<PlusOutlined />}>Add Qty</Button>,
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no tool holders to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CustomResponsiveTable
				columns={tableColumns}
				data={data}
				pagination={false}
				loading={isLoading}
				emptyTable={emptyTable}
			/>
		</div>
	);
};

export default ToolHolderTable;
