import PaginationComponent from '../Footers/Pagination.tsx';
import { Card, Modal, Empty, Tag, Typography, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { Lineitem } from '../../interfaces/Ordel.Interface';
import { ColumnsType, SorterResult } from 'antd/es/table/interface';
import { useNavigate } from 'react-router';
import { SalesOrderFilter } from '../../pages/Orders/SalesOrderFilter.tsx';
import { useQueryParameters } from '../../hooks';
import dayjs from 'dayjs';
import {
	LineItemQuery,
	useGetOrderLineItemsQuery,
} from '../../redux/api/order-line-item.ts';
import {
	getOrderLineItemStatusTagColor,
	getProductionMethodTagColor,
} from '../../utils/index.ts';
import ThreeDViewer from '../ThreeDViewer/index.tsx';
import { useState } from 'react';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const { Text } = Typography;

interface IProps {
	filterByPreviouslyOrderedParts?: boolean;
	lineItemId?: string;
	hideFilters?: boolean;
}

const OrderLineItemsTable = (props: IProps) => {
	const navigate = useNavigate();
	const queryParams = useQueryParameters();
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const searchUrl = queryParams.get('search') || '';
	const ordering = queryParams.get('ordering') || '';
	const production_method = queryParams.get('production_method') || '';
	const status = queryParams.get('status') || '';
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUrl, setSelectedUrl] = useState('');

	const lineItemQuery: LineItemQuery = {};
	if (currentPageUrl) {
		lineItemQuery.page = currentPageUrl;
	}

	if (searchUrl) {
		lineItemQuery.search = searchUrl;
	}

	if (production_method) {
		lineItemQuery.production_method = production_method;
	}

	if (ordering && ordering !== '') {
		lineItemQuery.ordering = ordering;
	}

	if (status && status !== '') {
		lineItemQuery.status = status;
	}

	if (props?.filterByPreviouslyOrderedParts && props?.lineItemId) {
		lineItemQuery.previously_ordered_parts = props?.lineItemId;
	}

	const { data, error } = useGetOrderLineItemsQuery(lineItemQuery);

	if (error) {
		navigate('/orders/line-items', { replace: true });
	}

	if (!data) {
		return <div>Loading...</div>;
	}

	const { count, results } = data;

	const handleTableChange: TableProps<Lineitem>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		if (_filters['status']) {
			if (_filters['status'].length > 0) {
				queryParams.set('status', _filters['status']?.toString());
			}
			navigate({ search: queryParams.toString() });
		} else {
			queryParams.delete('status');
			navigate({ search: queryParams.toString() });
		}

		if (extra.action === 'sort') {
			const orderType = (sorter as SorterResult<Lineitem>)?.order;
			const columnKey = (sorter as SorterResult<Lineitem>)?.columnKey;
			const sorterColumn = (sorter as SorterResult<Lineitem>)?.column;

			const column =
				orderType === 'ascend' || orderType === undefined
					? columnKey
					: '-' + columnKey;
			if (column !== undefined && sorterColumn !== undefined) {
				queryParams.set('ordering', column?.toString());
			} else {
				queryParams.delete('ordering');
			}
			navigate({ search: queryParams.toString() });
		}
	};

	const showModal = (url: string) => {
		setSelectedUrl(url);
		setIsModalOpen(true);
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1600);
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const tableColumns: ColumnsType<any> = [
		{
			title: 'Preview',
			render: (record: Lineitem) => (
				<img
					className="h-24"
					src={record.thumbnail_file}
					onClick={() => showModal(record.file_3djs)}
					style={{ cursor: 'pointer' }}
				/>
			),
			key: 'thumbnail',
		},
		{
			title: 'Name',
			key: 'name',
			render: (record: Lineitem) => (
				<Link to={`/orders/line-items/${record?.id}`}>{record.name}</Link>
			),
			sorter: (a, b) => {
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			},
		},
		{
			title: 'Order',
			key: 'order_name',
			render: (record: Lineitem) => (
				<Link to={`/orders/${record.order.id}`}>{record.order.name}</Link>
			),
			sorter: (a, b) => {
				if (a.order.name < b.order.name) return -1;
				if (a.order.name > b.order.name) return 1;
				return 0;
			},
		},
		{
			title: 'Quantity',
			key: 'quantity',
			render: (record: Lineitem) => `${record.quantity}`,
			sorter: (a, b) => a.quantity - b.quantity,
		},
		{
			title: 'Method',
			key: 'partsbadger_production_method',
			render: (record: Lineitem) => (
				<Tag
					color={getProductionMethodTagColor(
						record.partsbadger_production_method
					)}
				>
					{record.partsbadger_production_method || 'Not Set'}
				</Tag>
			),
			sorter: (a, b) => {
				if (a.partsbadger_production_method < b.partsbadger_production_method)
					return -1;
				if (a.partsbadger_production_method > b.partsbadger_production_method)
					return 1;
				return 0;
			},
		},
		{
			title: 'Material',
			key: 'material',
			render: (record: Lineitem) =>
				record.material ? (
					<Text type="success">
						<CheckCircleFilled className="mr-2" />
						Selected
					</Text>
				) : (
					<Text type="warning">
						<WarningFilled className="mr-2" />
						Not Selected
					</Text>
				),
			sorter: (a, b) => a.material - b.material,
		},
		{
			title: 'Received Date',
			key: 'created_time',
			render: (record: Lineitem) =>
				record?.created_time
					? dayjs(record.created_time).format('YYYY-MM-DD')
					: '',
			sorter: (a, b) => {
				const dateA = dayjs(a.created_time).format('YYYY-MM-DD') || '';
				const dateB = dayjs(b.created_time).format('YYYY-MM-DD') || '';
				return dateA.localeCompare(dateB);
			},
		},
		{
			title: 'Required Ship Date',
			key: 'required_partner_ship_date',
			render: (record: Lineitem) =>
				record?.required_partner_ship_date
					? dayjs(record.required_partner_ship_date).format('YYYY-MM-DD')
					: '',
			sorter: (a, b) => {
				const dateA =
					dayjs(a.required_partner_ship_date).format('YYYY-MM-DD') || '';
				const dateB =
					dayjs(b.required_partner_ship_date).format('YYYY-MM-DD') || '';
				return dateA.localeCompare(dateB);
			},
		},
		{
			title: 'Status',
			key: 'status',
			render: (record: Lineitem) => (
				<Tag color={getOrderLineItemStatusTagColor(record.status)}>
					{record.status}
				</Tag>
			),
			sorter: true,
			onFilter: (value: boolean | React.Key, record) => record.status === value,
			// filterMultiple: false,
			filters: !props?.hideFilters
				? [
						{
							text: 'Received',
							value: 'Received',
						},
						{
							text: 'In Progress',
							value: 'In Progress',
						},
						{
							text: 'Completed',
							value: 'Completed',
						},
						{
							text: 'On Hold',
							value: 'On Hold',
						},
						{
							text: 'Cancelled',
							value: 'Cancelled',
						},
				  ]
				: undefined,
		},
	];

	const tableFooter = () => {
		return (
			<div className="flex justify-between items-center">
				<div className="ml-auto">
					<PaginationComponent
						pageSize={20}
						total={count}
						showSizeChanger={false}
					/>
				</div>
			</div>
		);
	};

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no line items to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div>
			<div>{!props?.hideFilters && <SalesOrderFilter />}</div>
			<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<CustomResponsiveTable
						columns={tableColumns}
						data={results}
						pagination={false}
						loading={!data}
						footer={tableFooter}
						scroll={{ y: `calc(95vh - 250px)` }}
						onChange={handleTableChange}
						emptyTable={emptyTable}
					/>
				</div>
			</Card>

			<Modal
				title="3D Model Preview"
				open={isModalOpen}
				footer={null}
				closable={true}
				onCancel={handleClose}
				width={800}
			>
				{isModalOpen && (
					<ThreeDViewer url={selectedUrl} canvasStyle={{ height: '600px' }} />
				)}
			</Modal>
		</div>
	);
};

export default OrderLineItemsTable;
