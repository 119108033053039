import 'react';
import { Col, Row } from 'antd';

import Header from '../../components/Header/index.tsx';
import { Link } from 'react-router-dom';
import ProductionQueueTables from '../../components/ProductionQueueTables/index.tsx';

const ProductionQueues = () => {
	return (
		<>
			<Header
				title="Production Queues"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Production Queues',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<ProductionQueueTables />
				</Col>
			</Row>
		</>
	);
};

export default ProductionQueues;
