import {ArrowRightOutlined, QuestionCircleTwoTone, WarningOutlined} from '@ant-design/icons';

import {
	Card,
	Button,
	message,
	Typography,
	Divider,
	Select,
	Flex,
	Tooltip,
	Tag,
	Row,
	Col,
} from 'antd';
import { useEffect, useState } from 'react';

import { DetailCard } from '../../../interfaces/TableDataMaterial.interfaces';

import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { FileDownloads } from '../FileDownloads';
import {
	getOrderLineItemStatusTagColor,
	getProductionMethodTagColor,
} from '../../../utils';
import Thumbnail from '../Thumbnail';
import { useUpdateOrderLineItemMutation } from '../../../redux/api/order';

const { Option } = Select;

const { Title, Text } = Typography;

export const CardItem = ({
	lineItem,
	refetchOrder,
	isLoading,
	status_order,
	ready_for_production,
}: DetailCard) => {
	const [isDisabled, setIsDisabled] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const [updateOrderLineItem, updateOrderLineItemResult] =
		useUpdateOrderLineItemMutation();

	useEffect(() => {
		if (status_order === 'Cancelled') {
			setIsDisabled(true);
		}
		if (!ready_for_production) {
			setIsDisabled(true);
		}
	}, []);

	const [selectedValue, setSelectedValue] = useState('');

	const handleSelectChange = (value: string) => {
		setSelectedValue(value);
	};

	const handleUpdateProductionMethod = async () => {
		const payload = {
			id: lineItem.id,
			values: { partsbadger_production_method: selectedValue },
		};

		updateOrderLineItem(payload)
			.unwrap()
			.then(() => {
				messageApi.open({
					type: 'success',
					content: 'Production method updated successfully',
				});

				refetchOrder();
			})
			.catch(() =>
				messageApi.open({
					type: 'error',
					content: `Failed to update production method`,
				})
			);
	};

	return (
		<div className="mb-2">
			<Card
				loading={isLoading}
				size="default"
				title={null}
				bodyStyle={{ padding: 0 }}
			>
				<Flex
					className="w-full py-3 px-5"
					wrap="wrap"
					align="baseline"
					justify="space-between"
				>
					<div className="flex flex-col sm:flex-row items-baseline gap-4">
						<Text strong>{lineItem?.name}</Text>
						{lineItem.status === 'On Hold' ||
						lineItem.status === 'Completed' ||
						lineItem.status === 'Cancelled' ? null : (
							<div className="flex justify-center items-center">
								{lineItem.partsbadger_production_method !== null &&
								lineItem.partsbadger_production_method.trim() !== '' &&
								lineItem.partsbadger_production_method.toLowerCase() !==
									'no' ? (
									<Tag
										color={getProductionMethodTagColor(
											lineItem.partsbadger_production_method
										)}
									>
										{lineItem.partsbadger_production_method}
									</Tag>
								) : (
									<div className="hidden md:flex items-center justify-between">
										<Select value={selectedValue} onSelect={handleSelectChange}>
											<Option value="">Select Production Method</Option>
											<Option value={'Fastlane'}>Fastlane</Option>
											<Option value={'QuickTurn'}>QuickTurn</Option>
											<Option value={'Swiss'}>Swiss</Option>
											<Option value={'Traditional'}>Traditional</Option>
										</Select>
										{selectedValue && (
											<Button
												type="primary"
												className="ml-2"
												onClick={handleUpdateProductionMethod}
												loading={updateOrderLineItemResult.isLoading}
												disabled={updateOrderLineItemResult.isLoading}
											>
												Confirm
											</Button>
										)}
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						{lineItem && lineItem.cancelled_quantity > 0 && lineItem.status !== "Cancelled" ? (
							<Tag color={getOrderLineItemStatusTagColor("Quantity Changed")}>
								<WarningOutlined style={{ fontSize: '0.7rem' }} /> Quantity Changed
							</Tag>
						) : null }
						<Tag color={getOrderLineItemStatusTagColor(lineItem?.status)}>
							{lineItem?.status}
						</Tag>
					</div>
				</Flex>

				<Divider style={{ margin: 0, padding: 0 }} />

				<div className="flex flex-col sm:flex-row p-5 gap-4">
					<div className="w-full sm:w-48">
						<Thumbnail lineItem={lineItem} />
					</div>

					<div className="grow w-full mb-5">
						<Title level={5}>Details</Title>
						<Row className="mb-10" gutter={[16, 16]}>
							<Col md={6} lg={4} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">Material</span>
									<span>{lineItem?.material_name}</span>
								</Flex>
							</Col>
							<Col md={6} lg={4} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">Surface Finish</span>
									<span>{lineItem?.finish_name}</span>
								</Flex>
							</Col>
							<Col md={6} lg={3} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">Coatings</span>
									<span>{lineItem?.coating_name}</span>
								</Flex>
							</Col>
							<Col md={6} lg={3} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">Quantity</span>
									{lineItem?.cancelled_quantity > 0 && lineItem.status !== "Cancelled" ? (
										<div className="flex flex-row items-baseline gap-1">
											<Text delete>{lineItem?.quantity}</Text>
											<Text>
												<ArrowRightOutlined style={{fontSize: '0.7rem'}}/>
											</Text>
											<Text strong mark>{lineItem?.updated_quantity}</Text>
										</div>
									) : (
										<span>{lineItem?.quantity}</span>
									)}
								</Flex>
							</Col>
							<Col md={6} lg={5} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">
										Required Shop Ship Date{' '}
										<Tooltip title="This is the required ship date for PartsBadger Production">
											<QuestionCircleTwoTone />
										</Tooltip>
									</span>

									<span>
										{dayjs(lineItem.required_partner_ship_date).format(
											'dddd, MMM D YYYY'
										)}
									</span>
								</Flex>
							</Col>
							<Col md={6} lg={5} sm={12} span={24}>
								<Flex vertical={true} align="start">
									<span className="text-slate-400">
										Required Part Ship Date{' '}
										<Tooltip title="This is the date show to the customer on the sales order">
											<QuestionCircleTwoTone />
										</Tooltip>
									</span>

									<span className="font-bold">
										{dayjs(lineItem.required_part_ship_date).format(
											'dddd, MMM D YYYY'
										)}
									</span>
								</Flex>
							</Col>
						</Row>

						<Flex wrap="wrap" className="gap-12">
							<FileDownloads
								lineItem={lineItem}
								isDisabled={isDisabled}
								refetchOrder={refetchOrder}
								enableBalloonedDrawings={false}
							/>

							{/* <Material lineItem={lineItem} isDisabled={isDisabled} /> */}
						</Flex>

						<div className={'mt-4'}>
							<Title level={5}>Quoting Notes</Title>
							<Text>{lineItem.partsbadger_production_quoting_notes || 'None'}</Text>
						</div>
					</div>
				</div>

				<Divider style={{ margin: 0, padding: 0 }} />
				<div className="flex justify-end p-4">
					<Link to={`/orders/line-items/${lineItem.id}`}>
						<Button className="button-view-details">Details</Button>
					</Link>
				</div>
			</Card>

			{contextHolder}
		</div>
	);
};
