import {
	Button,
	Col,
	Input,
	InputNumber,
	Row,
	Select,
	message,
	Form,
} from 'antd';
import {
	useGetMaterialShapesQuery,
	useGetMaterialTypesQuery,
	useGetMaterialVendorsQuery,
} from '../../redux/api/material';
import { useGetUnitsOfMeasureQuery } from '../../redux/api/core';
import {
	useAddMaterialRequestMutation,
	useUpdateMaterialRequestMutation,
} from '../../redux/api/material-request';
import { useUpdateOrderLineItemMutation } from '../../redux/api/order';
import { MaterialTypeObject } from '../../interfaces/Material.Interface';

interface FormMaterialProps {
	id: string;
	order: string;
	closeModal: () => void;
	refetchLineItem: () => void;
	defaultMaterialRequestData?: MaterialTypeObject;
	disabled: boolean;
}

const FormMaterialRequest = ({
	id,
	order,
	closeModal,
	refetchLineItem,
	defaultMaterialRequestData,
	disabled,
}: FormMaterialProps) => {
	const { data: shapesData } = useGetMaterialShapesQuery();
	const { data: vendorsData } = useGetMaterialVendorsQuery();
	const { data: measureData } = useGetUnitsOfMeasureQuery();
	const { data: typeData } = useGetMaterialTypesQuery();

	const [updateOrderLineItem] = useUpdateOrderLineItemMutation();

	const [messageApi, contextHolder] = message.useMessage();
	const [addMaterialRequest] = useAddMaterialRequestMutation();
	const [updateMaterialRequest] = useUpdateMaterialRequestMutation();
	const [form] = Form.useForm();

	const handleSubmit = async () => {
		await form
			.validateFields()
			.then(async (values) => {
				const data = {
					...values,
					new_material_name: '',
					new_material_more_info: '',
					line_item: id,
					outer_diameter_unity: values?.outer_diameter_unit,
					inner_diameter_unity: values?.inner_diameter_unit,
					shape: values?.shape?.value,
					outer_diameter:
						values?.shape?.label == 'Tube' || values?.shape?.label == 'Round'
							? values.outer_diameter
							: null,
					inner_diameter:
						values?.shape?.label == 'Tube' ? values?.inner_diameter : null,
					sale_order_name: order,
					order_line_item: id,
				};

				if (defaultMaterialRequestData) {
					await updateMaterialRequest({
						id: defaultMaterialRequestData?.id,
						values: data,
					})
						.unwrap()
						.then(async () => {
							await messageApi
								.open({
									type: 'success',
									content: 'Material request updated successfully',
									duration: 1,
								})
								.then(() => {
									closeModal();
								});
							form.resetFields();

							refetchLineItem();
						})
						.catch(() => {
							messageApi.open({
								type: 'error',
								content: 'Unable to update the material request at this time!',
							});
						});
				} else {
					await addMaterialRequest(data)
						.unwrap()
						.then(async (request_material: MaterialTypeObject) => {
							const material_req_values = {
								id: id,
								values: {
									material_request: request_material?.id,
								},
							};

							await updateOrderLineItem(material_req_values);

							await messageApi
								.open({
									type: 'success',
									content: 'Material request created successfully',
									duration: 1,
								})
								.then(() => {
									closeModal();
								});

							form.resetFields();

							refetchLineItem();
						})
						.catch((err) => {
							console.log('err', err);
							messageApi.open({
								type: 'error',
								content: 'Unable to create the material request at this time!',
							});
						});
				}
			})
			.catch((errorInfo) => {
				form.scrollToField(errorInfo.errorFields[0].name);

				messageApi.open({
					type: 'error',
					content: 'Required fields are empty!',
				});
			});
	};

	const selectAfter = (
		name:
			| 'height_unit'
			| 'width_unit'
			| 'outer_diameter_unit'
			| 'inner_diameter_unit'
			| 'length_unit'
			| 'outer_diameter'
			| 'inner_diameter'
			| 'wall_thickness'
			| 'wall_thickness_unit'
	) => (
		<Form.Item name={name} style={{ width: 60, height: 5 }}>
			<Select
				disabled={disabled}
				options={measureData?.map((measure) => ({
					label: measure.name,
					value: measure.id,
				}))}
			></Select>
		</Form.Item>
	);

	const formatInitialValues = () => {
		return {
			...defaultMaterialRequestData,
			type: defaultMaterialRequestData?.type?.id,
			shape: defaultMaterialRequestData
				? {
						label: defaultMaterialRequestData?.shape?.display_name,
						value: defaultMaterialRequestData?.shape?.id,
				  }
				: null,
			vendor: defaultMaterialRequestData?.vendor?.id,
			height: defaultMaterialRequestData?.height,
			height_unit: defaultMaterialRequestData?.height_unit?.id,
			width: defaultMaterialRequestData?.width,
			width_unit: defaultMaterialRequestData?.width_unit?.id,
			outer_diameter: defaultMaterialRequestData?.outer_diameter,
			outer_diameter_unit: defaultMaterialRequestData?.outer_diameter_unity?.id,
			inner_diameter: defaultMaterialRequestData?.inner_diameter,
			inner_diameter_unit: defaultMaterialRequestData?.inner_diameter_unity?.id,
			wall_thickness: defaultMaterialRequestData?.wall_thickness,
			wall_thickness_unit: defaultMaterialRequestData?.wall_thickness_unit?.id,
			length: defaultMaterialRequestData?.length,
			length_unit: defaultMaterialRequestData?.length_unit?.id,
		};
	};

	return (
		<>
			{contextHolder}
			<Form
				form={form}
				style={{ width: '100%' }}
				// onFinish={handleSubmit}
				initialValues={formatInitialValues()}
				layout="vertical"
			>
				<Row
					className="material-custom"
					gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
				>
					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item
							label="Material Type"
							name="type"
							style={{ height: '35px' }}
							rules={[
								{
									required: true,
									message: 'Required',
								},
							]}
						>
							<Select
								options={typeData?.map((type) => ({
									label: type.display_name,
									value: type.id,
								}))}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item
							name="shape"
							label="Material Shape"
							rules={[
								{
									required: true,
									message: 'Required',
								},
							]}
						>
							<Select
								labelInValue
								options={shapesData?.map((shape) => ({
									label: shape.display_name,
									value: shape.id,
								}))}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item
							label="Material Vendor"
							name="vendor"
							rules={[
								{
									required: true,
									message: 'Required',
								},
							]}
						>
							<Select
								style={{ width: '100%' }}
								options={vendorsData?.map((vendor) => ({
									label: vendor.display_name,
									value: vendor.id,
								}))}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>

					<Form.Item noStyle dependencies={['shape']}>
						{({ getFieldValue }) => {
							return (
								(getFieldValue('shape')?.label == 'Plate' ||
									getFieldValue('shape')?.label == 'Rectangle' ||
									getFieldValue('shape')?.label == 'Angle' ||
									getFieldValue('shape')?.label == 'Channel') && (
									<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
										<Form.Item
											label="Material Height"
											name="height"
											rules={[
												{
													required: true,
													message: 'Required',
												},
											]}
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0.001}
												max={1000}
												step={0.125}
												addonAfter={selectAfter('height_unit')}
												disabled={disabled}
											/>
										</Form.Item>
									</Col>
								)
							);
						}}
					</Form.Item>

					<Form.Item noStyle dependencies={['shape']}>
						{({ getFieldValue }) => {
							return (
								(getFieldValue('shape')?.label == 'Plate' ||
									getFieldValue('shape')?.label == 'Rectangle' ||
									getFieldValue('shape')?.label == 'Angle' ||
									getFieldValue('shape')?.label == 'Channel') && (
									<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
										<Form.Item
											label="Material Width"
											name="width"
											rules={[
												{
													required: true,
													message: 'Required',
												},
											]}
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0.001}
												max={1000}
												step={0.125}
												addonAfter={selectAfter('width_unit')}
												disabled={disabled}
											/>
										</Form.Item>
									</Col>
								)
							);
						}}
					</Form.Item>

					<Form.Item noStyle dependencies={['shape']}>
						{({ getFieldValue }) => {
							return (
								(getFieldValue('shape')?.label == 'Round' ||
									getFieldValue('shape')?.label == 'Tube') && (
									<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
										<Form.Item
											label="Material Outer Diameter"
											name="outer_diameter"
											rules={[
												{
													required: true,
													message: 'Required',
												},
											]}
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0.001}
												max={1000}
												step={0.125}
												addonAfter={selectAfter('outer_diameter_unit')}
												disabled={disabled}
											/>
										</Form.Item>
									</Col>
								)
							);
						}}
					</Form.Item>

					<Form.Item noStyle dependencies={['shape']}>
						{({ getFieldValue }) => {
							return (
								getFieldValue('shape')?.label == 'Tube' && (
									<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
										<Form.Item
											label="Material Inner Diameter"
											name="inner_diameter"
											rules={[
												{
													required: true,
													message: 'Required',
												},
											]}
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0.001}
												max={1000}
												step={0.125}
												addonAfter={selectAfter('inner_diameter_unit')}
												disabled={disabled}
											/>
										</Form.Item>
									</Col>
								)
							);
						}}
					</Form.Item>

					<Form.Item noStyle dependencies={['shape']}>
						{({ getFieldValue }) => {
							return (
								(getFieldValue('shape')?.label == 'Angle' ||
									form.getFieldValue('shape')?.label == 'Channel') && (
									<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
										<Form.Item
											label="Material Wall Thickness"
											name="wall_thickness"
											rules={[
												{
													required: true,
													message: 'Required',
												},
											]}
										>
											<InputNumber
												style={{ width: '100%' }}
												min={0.001}
												max={10.0}
												step={0.125}
												addonAfter={selectAfter('wall_thickness_unit')}
												disabled={disabled}
											/>
										</Form.Item>
									</Col>
								)
							);
						}}
					</Form.Item>

					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item
							label="Material Length"
							name="length"
							rules={[
								{
									required: true,
									message: 'Required',
								},
							]}
						>
							<InputNumber
								style={{ width: '100%' }}
								min={0.001}
								max={1000}
								step={0.125}
								addonAfter={selectAfter('length_unit')}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item
							label="Quantity"
							name="quantity_ordered"
							rules={[
								{
									required: true,
									message: 'Required',
								},
							]}
						>
							<InputNumber
								style={{ width: '100%' }}
								min={1}
								max={1000}
								step={1}
								precision={0}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} lg={7} xl={7} xxl={7} span={7}>
						<Form.Item label="Vendor Part Number" name="vendor_number">
							<Input placeholder="xx" disabled={disabled} />
						</Form.Item>
					</Col>
					<Col xs={24} xxl={21}>
						<Form.Item name="notes" label="Notes">
							<Input.TextArea disabled={disabled} />
						</Form.Item>
					</Col>

					<div className="flex justify-end w-full md:items-end lg:h-48">
						<Button
							className="mr-2"
							onClick={() => {
								closeModal();
							}}
							key="back"
						>
							Cancel
						</Button>

						<Button
							className="create-material-button"
							type="primary"
							style={{ boxShadow: 'none' }}
							onClick={() => {
								handleSubmit();
							}}
							disabled={disabled}
						>
							{defaultMaterialRequestData ? 'Update' : 'Send Request'}
						</Button>
					</div>
				</Row>
			</Form>
		</>
	);
};

export default FormMaterialRequest;
