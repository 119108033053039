import { useEffect, useState } from 'react';
import { Card, Col, Row, Input } from 'antd';
import MaterialTable from '../../../components/MaterialTable';
import { useDispatch } from 'react-redux';
import { useGetMaterialRemnantQuery } from '../../../redux/api/material';
import { showAddMaterialModal } from '../../../redux/slices/material';
import { PlusOutlined } from '@ant-design/icons';
import AddMaterialRemnantModal from '../../../components/modals/AddMaterialRemnantModal';
import type { SearchProps } from 'antd/es/input';
import { useNavigate, useLocation } from 'react-router-dom';
import { MaterialRemenant } from '../../../interfaces/MaterialRemenat';

import Header from '../../../components/Header';
import { Link } from 'react-router-dom';

interface MaterialQuery {
	page?: number;
	search?: string;
	ordering?: string;
	remanente?: string;
	hide_draft_and_used_materials?: boolean;
}

const { Search } = Input;

function Material() {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const searchUrl = queryParams.get('search') || '';
	const ordering = queryParams.get('ordering') || '';

	const dispatch = useDispatch();

	const materialQuery: MaterialQuery = { hide_draft_and_used_materials: true };

	if (currentPageUrl) {
		materialQuery.page = currentPageUrl;
	}

	if (searchUrl && searchUrl !== '') {
		materialQuery.search = searchUrl;
	}

	if (ordering && ordering !== '') {
		materialQuery.ordering = ordering;
	}

	const { data, isLoading, refetch } =
		useGetMaterialRemnantQuery(materialQuery);

	const [materials, setMaterials] = useState<MaterialRemenant[]>([]);

	const [currentSearch, setCurrentSearch] = useState<string>(
		searchUrl === 'true' ? 'remnant' : searchUrl
	);
	const [currentPage, setCurrentPage] = useState<number>(currentPageUrl);
	const [total, setTotal] = useState<number>(0);

	const refetchData = () => {
		refetch();
	};

	const onSearch: SearchProps['onSearch'] = async (value: string) => {
		if (value.toLocaleLowerCase() === 'remnant') {
			queryParams.set('search', 'true');
		} else if (value !== '') {
			queryParams.set('search', value);
		} else {
			queryParams.delete('search');
		}
		queryParams.set('page', '1');
		setCurrentSearch(value);
		navigate({ search: queryParams.toString() });
		setCurrentPage(1);
	};

	const pageChanged = async (page: number) => {
		queryParams.set('page', page.toString());
		navigate({ search: queryParams.toString() });
		setCurrentPage(page);
	};

	useEffect(() => {
		if (data) {
			setMaterials(data.results);
			setTotal(data.count ? data.count : 0);
		}
	}, [data]);

	return (
		<>
			<Header
				title="Material Inventory"
				showOnClickButton
				buttonText={'Add Material Remnant'}
				icon={<PlusOutlined />}
				handleOnClick={() => dispatch(showAddMaterialModal())}
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Material Inventory',
					},
				]}
			/>

			<Row>
				<Col span={24}>
					<div className="search-input">
						<Search
							placeholder="Search"
							onSearch={onSearch}
							style={{ width: '100%' }}
							defaultValue={currentSearch}
						/>
					</div>
					<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
						<MaterialTable
							data={materials}
							pagination={true}
							total={total}
							currentPage={currentPage}
							onChangePage={(page: number) => {
								pageChanged(page);
							}}
							onSort={(column: string | undefined) => {
								if (column) {
									queryParams.set('ordering', column);
								} else {
									queryParams.delete('ordering');
								}
								navigate({ search: queryParams.toString() });
							}}
							isLoading={isLoading}
							showVendorNumber={true}
							showMaterialSelectButton={false}
							showMaterialCertUploader={false}
							onSelectMaterial={function (id: string): void {
								console.log(id);
								throw new Error('Function not implemented.');
							}}
							onChangeStatus={function (material: MaterialRemenant): void {
								console.log(material);
								throw new Error('Function not implemented.');
							}}
						></MaterialTable>
					</Card>
				</Col>
			</Row>
			<AddMaterialRemnantModal
				materialsData={data?.results}
				refetch={refetchData}
			/>
		</>
	);
}

export default Material;
