import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './layout/Main';
import PrivateRoutes from './routes/PrivateRoutes';
import { Login } from './pages/Auth/Login';
// import { useLazyGetMachinesQuery } from './redux/api/machines';
import { useEffect } from 'react';
// import { setAuthToken } from './redux/slices/user';

type RootState = {
	user: {
		authToken: string;
	};
};

function App() {
	const user = useSelector((state: RootState) => state.user);
	// const dispatch = useDispatch();
	// const [getMachines] = useLazyGetMachinesQuery();

	function CheckAuth({ children }: { children: JSX.Element }): JSX.Element {
		if (!user.authToken) {
			return <Navigate to="/login" />;
		}

		return children;
	}

	async function ReviewTokenAuth() {
		try {
			// const data: any = await getMachines({});

			// if (data.error?.status == 401) {
			// 	dispatch(setAuthToken(''));
			// }
		} catch (error) {
			console.log(error, 'el error');
		}
	}

	useEffect(() => {
		ReviewTokenAuth();
	}, [user]);

	return (
		<>
			<CheckAuth>
				<MainLayout>
					<PrivateRoutes />
				</MainLayout>
			</CheckAuth>

			<Routes>
				<Route path="/login" element={<Login />} />
			</Routes>
		</>
	);
}

export default App;
