import {
    DeleteOutlined,
    PlusOutlined,
    SaveOutlined
} from '@ant-design/icons';
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Row,
    Select,
} from 'antd';
import {
    useAddMaterialOrderMutation,
    useDeleteMaterialOrderMutation,
    useGetMaterialOrderByIdQuery,
    useGetMaterialShapesQuery,
    useGetMaterialTypesQuery,
    useGetMaterialVendorsQuery,
    useUpdateMaterialOrderMutation,
} from '../../../redux/api/material';
import {useEffect, useState} from 'react';
import {useGetUnitsOfMeasureQuery} from '../../../redux/api/core';

import {useNavigate, useParams} from 'react-router';
import dayjs, {Dayjs} from 'dayjs';
import {Link} from 'react-router-dom';
import AddFromMaterialRequest from '../../../components/modals/AddFromMaterialRequest';
import {showAddMaterialModalRequest} from '../../../redux/slices/material-request';
import {useDispatch} from 'react-redux';
import {MaterialTypeObject, IMaterialOrderLineItem, IMaterialOrder} from '../../../interfaces/Material.Interface';
import SalesOrderLineItemSelect from '../../../components/SalesOrderLineItemSelect';
import ShowPrices from "../../../components/ShowPrices";

const {confirm} = Modal;

interface FormState {
    height: boolean;
    width: boolean;
    outerDiameter: boolean;
    innerDiameter: boolean;
    wallThickness: boolean;
    length: boolean;
}

const AddMaterialOrder = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const {id} = useParams();
    const {data, refetch} = useGetMaterialOrderByIdQuery(
        {id: id as string},
        {skip: !id}
    );

    const dispatch = useDispatch();

    const materialVendorsQuery = useGetMaterialVendorsQuery();
    const materialTypesQuery = useGetMaterialTypesQuery();

    const materialShapesQuery = useGetMaterialShapesQuery();

    const [addMaterialOrder, result] = useAddMaterialOrderMutation();

    const [orderDate, setOrderDate] = useState<Dayjs>(dayjs());
    const [updateMaterialOrder, updateMaterialOrderResult] =
        useUpdateMaterialOrderMutation();
    const [deleteMaterialOrder, deleteMaterialOrderResult] =
        useDeleteMaterialOrderMutation();

    const [deletedLineItems, setDeletedLineItems] = useState<
        IMaterialOrderLineItem[]
    >([]);

    const initialFormState: FormState = {
        height: true,
        width: true,
        outerDiameter: true,
        innerDiameter: true,
        wallThickness: true,
        length: true,
    };

    const [isRequired, setIsRequired] = useState<boolean>(true);

    const [materialOrderLineItems, setMaterialOrderLineItems] = useState<Array<IMaterialOrderLineItem>>([]);

    const onFinish = async (isDraft = false) => {
        // Submit the material order when the submit button is clicked
        await form
            .validateFields()
            .then(async (values) => {
                const payload: IMaterialOrder = {
                    vendor: values?.vendor,
                    requisition_number: values.requisition_number,
                    vendor_order_number: values.vendor_order_number || null,
                    order_date: dayjs(values.order_date).toISOString(),
                    promised_delivery_date: dayjs(
                        values.promised_delivery_date
                    ).toISOString(),
                    create_purchase_order:
                        values.create_purchase_order === undefined
                            ? 'true'
                            : values.create_purchase_order,
                    material: [],
                    shipping_cost: totals.shippingCost || 0,
                    status: isDraft ? 'Draft' : 'Awaiting delivery',
                    total_amount: Number(totals.totalAmount.toFixed(2)) || 0,
                };

                if (materialOrderLineItems.length === 0) {
                    return notification.error({
                        message: 'Add at least 1 order line item',
                        placement: 'topRight',
                    });
                } else {
                    const items: any[] = [];
                    materialOrderLineItems.map((item: IMaterialOrderLineItem) => {
                        items.push({
                            quantity_ordered: item.quantity_ordered,
                            material_type: item.material_type,
                            material_shape: item.material_shape,
                            height: item.height,
                            height_unit: item.height_unit,
                            width: item.width,
                            width_unit: item.width_unit,
                            outer_diameter: item.outer_diameter,
                            outer_diameter_unit: item.outer_diameter_unit,
                            inner_diameter: item.inner_diameter,
                            inner_diameter_unit: item.inner_diameter_unit,
                            wall_thickness: item.wall_thickness,
                            wall_thickness_unit: item.wall_thickness_unit,
                            length: item.length,
                            length_unit: item.length_unit,
                            sales_order_line_items: item.sales_order_line_items,
                            vendor_part_number: item.vendor_part_number,
                            unit_price: item.unit_price,
                            promised_delivery_date: dayjs(item.promised_delivery_date).toISOString(),
                        });
                    });

                    payload.material = items;
                }
                await addMaterialOrder(payload)
                    .unwrap()
                    .catch((error) => {
                        console.error('error', error);
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: `Error encountered while attempting to submit.`,
                            placement: 'topRight',
                        });
                    });
            })
            .catch((errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: `Required fields are empty!`,
                    placement: 'topRight',
                });
            });
    };

    const showConfirm = async () => {
        confirm({
            title: 'Delete Draft Material Order',
            content:
                'This action will permanently delete the Draft. Once deleted it cannot be recovered',
            onOk: async () => {
                await deleteMaterialOrder({id: id});
            },
            okText: 'Yes, Delete',
            okType: 'danger',
        });
    };

    const addNewItemFromMaterialRequest = async (requests: MaterialTypeObject[]) => {
        // Add the material order line items from the material request
        for (const request of requests) {
            const newMaterial: IMaterialOrderLineItem = {};
            if (request.id) {
                newMaterial.material_order_request = request.id;
            }
            if (request.quantity_ordered) {
                newMaterial.quantity_ordered = request.quantity_ordered;
            }
            if (request.type) {
                if (typeof request.type === 'object') {
                    newMaterial.material_type = request.type?.id;
                }
            }

            if (request.shape) {
                if (typeof request.shape === 'object') {
                    newMaterial.material_shape = request.shape?.id;
                }
            }

            if (request.height && request.height_unit) {
                newMaterial.height = request.height;
                newMaterial.height_unit = request.height_unit?.id;
            }

            if (request.width && request.width_unit) {
                newMaterial.width = request.width;
                newMaterial.width_unit = request.width_unit?.id;
            }

            if (request.outer_diameter && request.outer_diameter_unity) {
                newMaterial.outer_diameter = request.outer_diameter;
                newMaterial.outer_diameter_unit = request.outer_diameter_unity?.id;
            }

            if (request.inner_diameter && request.inner_diameter_unity) {
                newMaterial.inner_diameter = request.inner_diameter;
                newMaterial.inner_diameter_unit = request.inner_diameter_unity?.id;
            }

            if (request.wall_thickness && request.wall_thickness_unit) {
                newMaterial.wall_thickness = Number(request.wall_thickness)
                newMaterial.wall_thickness_unit = request.wall_thickness_unit?.id as string;
            }

            if (request.length && request.length_unit) {
                newMaterial.length = Number(request.length);
                newMaterial.length_unit = request.length_unit?.id;
            }

            if (request.vendor_number) {
                newMaterial.vendor_part_number = request.vendor_number;
            }

            if (request.unit_price) {
                newMaterial.unit_price = Number(request.unit_price);
            }

            if (request.promised_delivery_date) {
                newMaterial.promised_delivery_date = dayjs(request.promised_delivery_date);
            }
            materialOrderLineItems.push(newMaterial);
        }

        refreshTotals();

    };

    useEffect(() => {
        if (id === undefined) form.setFieldValue('material', [{}]);
    }, []);

    useEffect(() => {
        if (result.isSuccess) {
            navigate(`/material/orders/${result?.data?.id}`);
        }
    }, [result]);

    useEffect(() => {
        if (id) {
            // Set the form values when the data is available
            form.setFieldValue('vendor_order_number', data?.vendor_order_number);
            form.setFieldValue('vendor', data?.vendor?.id);
            form.setFieldValue('order_date', dayjs(data?.order_date || dayjs()));
            form.setFieldValue('create_purchase_order', data?.create_purchase_order);
            form.setFieldValue('requisition_number', data?.requisition_number);
            form.setFieldValue('shipping_cost', data?.shipping_cost);

            if (data?.material && data?.material.length && materialOrderLineItems.length === 0) {
                data?.material.map((material: any) => {
                    materialOrderLineItems.push({
                        "id": material.id,
                        "quantity_ordered": material.quantity_ordered,
                        "material_type": material.type?.id,
                        "material_shape": material.shape?.id,
                        "height": material.height,
                        "height_unit": material.height_unit?.id,
                        "width": material.width,
                        "width_unit": material.width_unit?.id,
                        "outer_diameter": material.outer_diameter,
                        "outer_diameter_unit": material.outer_diameter_unit?.id,
                        "inner_diameter": material.inner_diameter,
                        "inner_diameter_unit": material.inner_diameter_unit?.id,
                        "wall_thickness": material.wall_thickness,
                        "wall_thickness_unit": material.wall_thickness_unit,
                        "length": material.length,
                        "length_unit": material.length_unit?.id,
                        "sales_order_line_items": material.sales_order_line_items.map((item: { id: string }) => item.id),
                        "vendor_part_number": material.vendor_part_number,
                        "unit_price": material.unit_price,
                        "promised_delivery_date": material.promised_delivery_date ? dayjs(material.promised_delivery_date) : undefined,
                    });
                })

                refreshTotals(data?.shipping_cost);

            }
        }
    }, [id, data]);

    useEffect(() => {
        if (updateMaterialOrderResult.isSuccess) {
            if (updateMaterialOrderResult.data.status === 'Draft') {
                notification.success({
                    message: 'Draft Saved Successfully',
                    placement: 'top',
                });
            } else {
                notification.success({
                    message: 'Material Order Submitted Successfully',
                    placement: 'top',
                });
            }

            return navigate(`/material/orders/${id}`);
        }
        if (updateMaterialOrderResult.isError) {
            notification.error({
                message: 'Something went wrong',
                placement: 'topLeft',
            });
        }
    }, [updateMaterialOrderResult]);

    useEffect(() => {
        if (deleteMaterialOrderResult.isSuccess) {
            navigate(`/material/orders`);
        }
    }, [deleteMaterialOrderResult]);

    const saveChangesInDraftOrder = async (isDraft: boolean) => {
        // Save the changes to the material order when the save changes button is clicked
        await form
            .validateFields()
            .then(async (values) => {
                const payload: IMaterialOrder = {
                    vendor: values?.vendor,
                    requisition_number: values.requisition_number,
                    vendor_order_number: values.vendor_order_number || null,
                    order_date: dayjs(values.order_date).toISOString(),
                    promised_delivery_date: dayjs(
                        values.promised_delivery_date
                    ).toISOString(),
                    create_purchase_order:
                        values.create_purchase_order === undefined
                            ? 'true'
                            : values.create_purchase_order,
                    material: [],
                    shipping_cost: totals.shippingCost || 0,
                    status: isDraft ? 'Draft' : 'Awaiting delivery',
                    total_amount: Number(totals.totalAmount.toFixed(2)) || 0,
                    deletedLineItems: deletedLineItems,
                };
                if (materialOrderLineItems.length === 0) {
                    return notification.error({
                        message: 'Add at least 1 order line item',
                        placement: 'topRight',
                    });
                } else {
                    const items: any[] = [];
                    materialOrderLineItems.map((item: IMaterialOrderLineItem) => {
                        items.push({
                            id: item.id,
                            quantity_ordered: item.quantity_ordered,
                            material_type: item.material_type,
                            material_shape: item.material_shape,
                            height: item.height,
                            height_unit: item.height_unit,
                            width: item.width,
                            width_unit: item.width_unit,
                            outer_diameter: item.outer_diameter,
                            outer_diameter_unit: item.outer_diameter_unit,
                            inner_diameter: item.inner_diameter,
                            inner_diameter_unit: item.inner_diameter_unit,
                            wall_thickness: item.wall_thickness,
                            wall_thickness_unit: item.wall_thickness_unit,
                            length: item.length,
                            length_unit: item.length_unit,
                            vendor_part_number: item.vendor_part_number,
                            sales_order_line_items: item.sales_order_line_items,
                            unit_price: item.unit_price,
                            promised_delivery_date: dayjs(item.promised_delivery_date).toISOString()
                        });
                    });
                    payload.material = items;
                }
                await updateMaterialOrder({id: id, values: payload})
                    .unwrap()
                    .catch((error) => {
                        console.error('error', error);
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: `Error encountered while attempting to submit.`,
                            placement: 'topRight',
                        });
                    });
                refetch();
            })
            .catch((errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: `Required fields are empty!`,
                    placement: 'topRight',
                });
            });
    };

    const isDraft = () => {
        return data?.status === 'Draft';
    };

    const addMaterialOrderLineItem = async () => {
        await form
            .validateFields()
            .then(async (values) => {
                materialOrderLineItems.push({
                    quantity_ordered: values.quantity_ordered,
                    material_type: values.material_type,
                    material_shape: values.material_shape,
                    height: values.height,
                    height_unit: values.height_unit,
                    width: values.width,
                    width_unit: values.width_unit,
                    outer_diameter: values.outer_diameter,
                    outer_diameter_unit: values.outer_diameter_unit,
                    inner_diameter: values.inner_diameter,
                    inner_diameter_unit: values.inner_diameter_unit,
                    wall_thickness: values.wall_thickness,
                    wall_thickness_unit: values.wall_thickness_unit,
                    length: values.length,
                    length_unit: values.length_unit,
                    sales_order_line_items: values.sales_order_line_items,
                    vendor_part_number: values.vendor_part_number,
                    unit_price: values.unit_price,
                    promised_delivery_date: dayjs(values.promised_delivery_date)
                });
                // Reset the form fields
                form.resetFields([
                    "quantity_ordered",
                    "material_type",
                    "material_shape",
                    "height",
                    "width",
                    "outer_diameter",
                    "inner_diameter",
                    "wall_thickness",
                    "length",
                    "sales_order_line_items",
                    "vendor_part_number",
                    "unit_price",
                    "promised_delivery_date",
                ]);
                setFormState(initialFormState);
            })
            .catch((errorInfo) => {
                form.scrollToField(errorInfo.errorFields[0].name);
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: `Required fields are empty!`,
                    placement: 'topRight',
                });
            });

        refreshTotals();
    }

    const updateMaterialOrderLineItem = (index: number, key: string, value: string | number | any | null) => {
        // Update the materialOrderLineItem object
        const array = materialOrderLineItems.filter((item: any, i: number) => {
            if (i === index) {
                item[key] = value;
            }
            return item;
        });

        // Update the state
        setMaterialOrderLineItems(array);

        refreshTotals();
    }

    const removeMaterialOrderLineItem = (index: number) => {
        // Remove the materialOrderLineItem from the materialOrderLineItems array
        const material: IMaterialOrderLineItem = materialOrderLineItems[index];

        // Checking if the line item was saved in the database.
        if (material?.id) {
            setDeletedLineItems([...deletedLineItems, material]);
        }

        materialOrderLineItems.splice(index, 1);

        refreshTotals();
    }

    // Store the totals in the state
    const [totals, setTotals] = useState({
        subTotal: 0,
        shippingCost: 0,
        totalAmount: 0,
    });

    const refreshTotals = (shipping_cost?: number) => {
        // Calculate the values for the subTotal, shippingCost and totalAmount
        const subTotal = getSubTotal();
        const totalAmount = Number(subTotal) + Number(shipping_cost || totals.shippingCost);
        setTotals({
            subTotal: Number(subTotal),
            shippingCost: Math.round(Number(shipping_cost || totals.shippingCost) * 100) / 100,
            totalAmount: Math.round(totalAmount * 100) / 100,
        });
    }

    const getSubTotal = () => {
        // Calculate the subTotal from the materialOrderLineItems
        let subTotal = 0;
        materialOrderLineItems.forEach((item: IMaterialOrderLineItem) => {
            if (item.quantity_ordered && item.unit_price) {
                subTotal += item.quantity_ordered * item.unit_price;
            }
        });
        return Math.round(subTotal * 100) / 100;
    }

    const [formState, setFormState] = useState<FormState>(initialFormState);

    const handleShapeChange = (shape: string) => {
        let newFormState = { ...initialFormState };

        switch (shape) {
            case 'Plate':
            case 'Rectangle':
                newFormState = {
                    height: false,
                    width: false,
                    outerDiameter: true,
                    innerDiameter: true,
                    wallThickness: true,
                    length: false,
                };
                break;
            case 'Round':
                newFormState = {
                    height: true,
                    width: true,
                    outerDiameter: false,
                    innerDiameter: true,
                    wallThickness: true,
                    length: false,
                };
                break;
            case 'Tube':
                newFormState = {
                    height: true,
                    width: true,
                    outerDiameter: false,
                    innerDiameter: false,
                    wallThickness: true,
                    length: false,
                };
                break;
            case 'Angle':
            case 'Channel':
                newFormState = {
                    height: false,
                    width: false,
                    outerDiameter: true,
                    innerDiameter: true,
                    wallThickness: false,
                    length: false,
                };
                break;
            default:
                newFormState = initialFormState;
        }

        setFormState(newFormState);
    };

    return (
        <>
            <div className="flex flex-column justify-between py-4 mb-4">
                <h1 className="text-2xl font-bold m-0">
                    {isDraft() ? 'Edit Draft Material Order' : 'Add Material Order'}
                </h1>

                {data?.status === 'Draft' && (
                    <Link to={`/material/orders/${data?.id}`}>
                        <Button>Cancel</Button>
                    </Link>
                )}
            </div>

            <Form
                form={form}
                name="dynamic_form_complex"
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
                requiredMark={false}
            >
                <Card
                    title="Order Details"
                    extra={
                        <div className="flex flex-col md:flex-row">
                            <div>
                                <span className="c-card-title">Created by:</span>
                                {data?.created_by ? data?.created_by : '-'} |{' '}
                                {dayjs(data?.created_time).format('MMMM DD, YYYY hh:mmA')}
                            </div>
                            <div className="md:ml-2">
                                <span className="c-card-title">Modified by:</span>
                                {data?.modified_by ? data?.modified_by : '-'} |{' '}
                                {dayjs(data?.modified_time).format('MMMM DD, YYYY hh:mmA')}
                            </div>
                        </div>
                    }
                >
                    <Row gutter={[8, 8]}>
                        <Col xs={24} xl={6} lg={8}>
                            <Form.Item
                                name="vendor"
                                label="Material Vendor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select placeholder="Select a vendor">
                                    {materialVendorsQuery?.data?.map((type, index) => (
                                        <Select.Option key={index} value={type.id}>
                                            {type?.display_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={6} lg={8}>
                            <Form.Item name="requisition_number" label="Requisition Number">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={6} lg={8}>
                            <Form.Item name="vendor_order_number" label="Vendor Order Number">
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={6} lg={8}>
                            <Form.Item
                                name="order_date"
                                label="Order Date"
                                initialValue={orderDate}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <DatePicker
                                    className="w-full"
                                    onChange={(value) => {
                                        if (value) setOrderDate(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div style={{margin: 0, padding: '1rem'}}></div>

                <Card title={"Material Order Line Items"}>
                    <div className="overflow-x-auto">
                        <table className="w-full border">
                            <thead>
                                <tr className="border-b">
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '60px'}}>
                                        <div className="flex items-center justify-center">
                                            Quantity <span className={'ml-2 text-red-500'}>*</span>
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm">
                                        <div className="flex items-center justify-center">
                                            Material <span className={'ml-2 text-red-500'}>*</span>
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm">
                                        <div className="flex items-center justify-center">
                                            Shape <span className={'ml-2 text-red-500'}>*</span>
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Height {!formState.height && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Width {!formState.width && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Outer Diameter {!formState.outerDiameter && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Inner Diameter {!formState.innerDiameter && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Wall Thickness {!formState.wallThickness && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '135px'}}>
                                        <div className="flex items-center justify-center">
                                            Length {!formState.length && <span className={'ml-2 text-red-500'}>*</span>}
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm">
                                        <div className="flex items-center justify-center">Sales Order Line Items</div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '115px'}}>
                                        <div className="flex items-center justify-center">Vendor Part #</div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm">
                                        <div className="flex items-center justify-center">
                                            Unit Price <span className={'ml-2 text-red-500'}>*</span>
                                        </div>
                                    </th>
                                    <th className="p-2 border-r cursor-pointer text-sm" style={{minWidth: '145px'}}>
                                        <div className="flex items-center justify-center" title={'Promised Delivery Date'}>
                                            Delivery <span className={'ml-2 text-red-500'}>*</span>
                                        </div>
                                    </th>
                                    <td className="p-2 border-r cursor-pointer text-sm">
                                        <div className="flex items-center justify-center">
                                            Actions
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                        <tbody>
                        <tr className="text-center border-b text-sm">
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"quantity_ordered"}
                                    rules={[
                                        {
                                            required: isRequired,
                                            message: 'Required',
                                        },
                                    ]}
                                    className={"mb-4"}
                                >
                                    <InputNumber
                                        min={1}
                                        max={1000}
                                        step={1}
                                        precision={0}
                                        controls={false}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"material_type"}
                                    rules={[
                                        {
                                            required: isRequired,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a material"
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {materialTypesQuery?.data?.map((type) => (
                                            <Select.Option key={type.id} value={type.id}>
                                                {type?.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"material_shape"}
                                    rules={[
                                        {
                                            required: isRequired,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select a shape"
                                        dropdownMatchSelectWidth={false}
                                        onSelect={(_, option) => {
                                            if (option && option.children) handleShapeChange(option.children.toString());
                                        }}
                                    >
                                        {materialShapesQuery?.data?.map((shape, index) => (
                                            <Select.Option key={index} value={shape.id}>
                                                {shape?.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"height"}
                                    rules={[
                                        {
                                            required: !formState.height,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        max={1000.0}
                                        step={0.125}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"height_unit"} disabled={formState.height} />
                                        }
                                        disabled={formState.height}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"width"}
                                    rules={[
                                        {
                                            required: !formState.width,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        max={1000.0}
                                        step={0.125}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"width_unit"} disabled={formState.width} />
                                        }
                                        disabled={formState.width}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"outer_diameter"}
                                    rules={[
                                        {
                                            required: !formState.outerDiameter,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        max={1000.0}
                                        step={0.125}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"outer_diameter_unit"} disabled={formState.outerDiameter} />
                                        }
                                        disabled={formState.outerDiameter}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"inner_diameter"}
                                    rules={[
                                        {
                                            required: !formState.innerDiameter,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        max={1000.0}
                                        step={0.125}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"inner_diameter_unit"} disabled={formState.innerDiameter} />
                                        }
                                        disabled={formState.innerDiameter}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"wall_thickness"}
                                    rules={[
                                        {
                                            required: !formState.wallThickness,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        step={0.125}
                                        max={1000.0}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"wall_thickness_unit"} disabled={formState.wallThickness} />
                                        }
                                        disabled={formState.wallThickness}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"length"}
                                    rules={[
                                        {
                                            required: !formState.length,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.001}
                                        step={0.125}
                                        max={1000.0}
                                        addonAfter={
                                            <UnitOfMeasureSelector name={"length_unit"} disabled={formState.length} />
                                        }
                                        disabled={formState.length}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"sales_order_line_items"}
                                >
                                    <SalesOrderLineItemSelect
                                        material_item_id={"1"}
                                        style={{maxWidth: "330px", minWidth: "330px"}}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"vendor_part_number"}
                                >
                                    <Input />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"unit_price"}
                                    rules={[
                                        {
                                            required: isRequired,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.01}
                                        step={1.0}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border-r">
                                <Form.Item
                                    noStyle
                                    name={"promised_delivery_date"}
                                    rules={[
                                        {
                                            required: isRequired,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        disabledDate={(current) => {
                                            return current && current < orderDate;
                                        }}
                                    />
                                </Form.Item>
                            </td>
                            <td className="p-2 border">
                                <Button type="primary" onClick={async () => {
                                    setIsRequired(true);
                                    await addMaterialOrderLineItem();
                                }}>Add</Button>
                            </td>
                        </tr>


                        {materialOrderLineItems.map((item: IMaterialOrderLineItem, index) => (
                            <tr key={index} className="text-center border-b text-sm">
                                <td className="p-2 border-r">
                                    <Form.Item
                                        noStyle
                                        name={`quantity_ordered_${index}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                        initialValue={item.quantity_ordered}
                                    >
                                        <InputNumber
                                            min={1}
                                            max={1000}
                                            step={1}
                                            precision={0}
                                            controls={false}
                                            onChange={(value) => updateMaterialOrderLineItem(index, 'quantity_ordered', value)}
                                        />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border-r">
                                    <Select
                                        placeholder="Select a material"
                                        dropdownMatchSelectWidth={false}
                                        value={item.material_type}
                                        onSelect={(value) => updateMaterialOrderLineItem(index, 'material_type', value)}
                                    >
                                        {materialTypesQuery?.data?.map((type) => (
                                            <Select.Option key={type.id} value={type.id}>
                                                {type?.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </td>
                                <td className="p-2 border-r">
                                    <Select
                                        placeholder="Select a shape"
                                        dropdownMatchSelectWidth={false}
                                        value={item.material_shape}
                                        onSelect={(value) => updateMaterialOrderLineItem(index, 'material_shape', value)}
                                    >
                                        {materialShapesQuery?.data?.map((shape, index) => (
                                            <Select.Option key={index} value={shape.id}>
                                                {shape?.display_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </td>
                                <td className="p-2 border-r">
                                    {item.height ? (
                                        <Form.Item
                                            noStyle
                                            name={`height_${index}`}
                                            rules={[
                                                {
                                                    required: !formState.height,
                                                    message: 'Required',
                                                },
                                            ]}
                                            initialValue={item.height}
                                        >
                                            <InputNumber
                                                min={0.001}
                                                max={1000.0}
                                                step={0.125}
                                                addonAfter={
                                                    <UnitOfMeasureSelector
                                                        name={`height_unit_${index}`}
                                                        onSelect={(value: string) => updateMaterialOrderLineItem(index, 'height_unit', value)}
                                                        value={item.height_unit}
                                                    />
                                                }
                                                onChange={(value) => updateMaterialOrderLineItem(index, 'height', value)}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <span className={'text-gray-400 text-opacity-75 text-sm '}></span>
                                    )}
                                </td>
                                <td className="p-2 border-r">
                                    {item.width ? (
                                            <Form.Item
                                                noStyle
                                                name={`width_${index}`}
                                                rules={[
                                                    {
                                                        required: !formState.outerDiameter,
                                                        message: 'Required',
                                                    },
                                                ]}
                                                initialValue={item.width}
                                            >
                                                <InputNumber
                                                    min={0.001}
                                                    max={1000.0}
                                                    step={0.125}
                                                    addonAfter={
                                                        <UnitOfMeasureSelector
                                                            name={`width_unit_${index}`}
                                                            onSelect={(value: string) => updateMaterialOrderLineItem(index, 'width_unit', value)}
                                                            value={item.width_unit}
                                                        />
                                                    }
                                                    onChange={(value) => updateMaterialOrderLineItem(index, 'width', value)}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <span className={'text-gray-400 text-opacity-75 text-sm '}></span>
                                        )}
                                </td>
                                <td className="p-2 border-r">
                                    {item.outer_diameter ? (
                                        <Form.Item
                                            noStyle
                                            name={`outer_diameter_${index}`}
                                            rules={[
                                                {
                                                    required: !formState.outerDiameter,
                                                    message: 'Required',
                                                },
                                            ]}
                                            initialValue={item.outer_diameter}
                                        >
                                            <InputNumber
                                                min={0.001}
                                                max={1000.0}
                                                step={0.125}
                                                addonAfter={
                                                    <UnitOfMeasureSelector
                                                        name={`outer_diameter_unit_${index}`}
                                                        onSelect={(value: string) => updateMaterialOrderLineItem(index, 'outer_diameter_unit', value)}
                                                        value={item.outer_diameter_unit}
                                                    />
                                                }
                                                onChange={(value) => updateMaterialOrderLineItem(index, 'outer_diameter', value)}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <span className={'text-gray-400 text-opacity-75 text-sm '}></span>
                                    )}
                                </td>
                                <td className="p-2 border-r">
                                    {item.inner_diameter ? (
                                        <Form.Item
                                            noStyle
                                            name={`inner_diameter_${index}`}
                                            rules={[
                                                {
                                                    required: !formState.innerDiameter,
                                                    message: 'Required',
                                                },
                                            ]}
                                            initialValue={item.inner_diameter}
                                        >
                                            <InputNumber
                                                min={0.001}
                                                max={1000.0}
                                                step={0.125}
                                                addonAfter={
                                                    <UnitOfMeasureSelector
                                                        name={`inner_diameter_unit_${index}`}
                                                        onSelect={(value: string) => updateMaterialOrderLineItem(index, 'inner_diameter_unit', value)}
                                                        value={item.inner_diameter_unit}
                                                    />
                                                }
                                                onChange={(value) => updateMaterialOrderLineItem(index, 'inner_diameter', value)}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <span className={'text-gray-400 text-opacity-75 text-sm '}></span>
                                    )}
                                </td>
                                <td className="p-2 border-r">
                                       {item.wall_thickness ? (
                                        <Form.Item
                                            noStyle
                                            name={`wall_thickness_${index}`}
                                            rules={[
                                                {
                                                    required: !formState.wallThickness,
                                                    message: 'Required',
                                                },
                                            ]}
                                            initialValue={item.wall_thickness}
                                        >
                                            <InputNumber
                                                min={0.001}
                                                step={0.125}
                                                max={1000.0}
                                                addonAfter={
                                                    <UnitOfMeasureSelector
                                                        name={`wall_thickness_unit_${index}`}
                                                        onSelect={(value: string) => updateMaterialOrderLineItem(index, 'wall_thickness_unit', value)}
                                                        value={item.wall_thickness_unit as string}
                                                    />
                                                }
                                                onChange={(value) => updateMaterialOrderLineItem(index, 'wall_thickness', value)}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <span className={'text-gray-400 text-opacity-75 text-sm '}></span>
                                    )}
                                </td>
                                <td className="p-2 border-r">
                                    <InputNumber
                                        min={0.001}
                                        step={0.125}
                                        max={1000.0}
                                        addonAfter={
                                            <UnitOfMeasureSelector
                                                name={`length_unit_${index}`}
                                                value={item.length_unit}
                                                onSelect={(value: string) => updateMaterialOrderLineItem(index, 'length_unit', value)}
                                            />
                                        }
                                        value={item.length}
                                        onChange={(value) => updateMaterialOrderLineItem(index, 'length', value)}
                                    />
                                </td>
                                <td className="p-2 border-r">
                                    <SalesOrderLineItemSelect
                                        material_item_id={"1"}
                                        style={{width: '100%'}}
                                        onChange={(value) => updateMaterialOrderLineItem(index, 'sales_order_line_items', value)}
                                        value={item.sales_order_line_items}
                                    />
                                </td>
                                <td className="p-2 border-r">
                                    <Input value={item.vendor_part_number}
                                           onChange={(e) => updateMaterialOrderLineItem(index, 'vendor_part_number', e.target.value)}/>
                                </td>
                                <td className="p-2 border-r">
                                    <Form.Item
                                        noStyle
                                        name={`unit_price_${index}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                        initialValue={item.unit_price}
                                    >
                                        <InputNumber
                                            min={0.01}
                                            step={1.0}
                                            onChange={(value) => updateMaterialOrderLineItem(index, 'unit_price', value)}
                                        />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border-r">
                                    <Form.Item
                                        noStyle
                                        name={`promised_delivery_date_${index}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required',
                                            },
                                        ]}
                                        initialValue={item.promised_delivery_date}
                                    >
                                        <DatePicker
                                            disabledDate={(current) => {
                                                return current && current < orderDate;
                                            }}
                                            onChange={(date) => updateMaterialOrderLineItem(index, 'promised_delivery_date', date)}
                                        />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border">
                                    <Button type="primary" onClick={() => removeMaterialOrderLineItem(index)}>
                                        <DeleteOutlined/>
                                    </Button>
                                </td>
                            </tr>
                        ))}


                        </tbody>
                        <tfoot>
                        <tr className={'text-right border-b text-xl'}>
                            <td colSpan={12} className="p-2 border-r">
                                <label className="mr-2">Shipping Cost:</label>
                            </td>
                            <td colSpan={2} className="p-2 border-r">
                                <Form.Item
                                    noStyle={true}
                                    name="shipping_cost"
                                >

                                    <InputNumber min={1} style={{width: '100%'}} onChange={(value) => {
                                        if (value) {
                                            refreshTotals(value);
                                        }
                                    }}/>
                                </Form.Item>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>

                    <div className="flex flex-row p-4">
                        <div className={'w-1/3'} />
                        <div className={'w-2/3'}>
                            <div className="flex flex-row p-4">
                                <div className={'w-2/4'}>
                                    <Row key={'tax'}>
                                        <Col xl={12} className="flex flex-col my-4"/>
                                    </Row>
                                </div>
                                <div className={'w-2/4'}>
                                    <div className={'flex flex-col w-full'}>
                                        <Row key={'subtotal'}>
                                            <Col xl={12} className="flex flex-col my-4"/>
                                            <Col xl={12}>
                                                <div className={'flex flex-col justify-between '}>
                                                    <div className="flex flex-row justify-between   ">
                                                        <div className="text-sm">Subtotal</div>
                                                        <div className="text-sm">
                                                            <ShowPrices value={Number(totals.subTotal)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row key={'subtotal'}>
                                            <Col xl={12} className="flex flex-col my-4"/>
                                            <Col xl={12}>
                                                <div className={'flex flex-col justify-between '}>
                                                    <div className="flex flex-row justify-between   ">
                                                        <div className="text-sm">Shipping</div>
                                                        <div className="text-sm">
                                                            <ShowPrices value={Number(totals.shippingCost)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row key={'totals'}>
                                            <Col xl={12} className="flex flex-col my-4"/>
                                            <Col xl={12}>
                                                <div className={'flex flex-col justify-between '}>
                                                    <div
                                                        className="flex flex-row justify-between   pt-4"
                                                        style={{
                                                            borderTop: 'solid 1px #d9d9d9',
                                                        }}
                                                    >
                                                        <div className="font-bold text-xl">Total</div>
                                                        <div className="font-bold text-xl">
                                                            <ShowPrices value={Number(totals.totalAmount)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Divider style={{margin: 0, padding: '0.5rem'}}/>

                    <div style={{display: 'flex', float: "right"}}>
                        <div style={{marginRight: '10px', paddingTop: '15px'}}>
                            <Form.Item name="create_purchase_order" valuePropName="checked"
                                       style={{marginTop: '-1rem'}}>
                                <Checkbox defaultChecked>
                                    <b>Create Purchase Order</b>
                                </Checkbox>
                            </Form.Item>
                        </div>
                        {!isDraft() && (
                            <Form.Item>
                                <Link to="/material/orders">
                                    <Button style={{marginRight: '10px'}}>Cancel</Button>
                                </Link>
                            </Form.Item>
                        )}
                        {isDraft() ? (
                            <Form.Item>
                                <Button
                                    style={{marginRight: '10px'}}
                                    type="primary"
                                    loading={result?.isLoading}
                                    onClick={async () => {
                                        setIsRequired(false);
                                        await saveChangesInDraftOrder(false)
                                    }}
                                >
                                    Submit Material Order
                                </Button>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Button
                                    style={{marginRight: '10px'}}
                                    type="primary"
                                    onClick={async () => {
                                        setIsRequired(false);
                                        await onFinish(false)
                                    }}
                                    loading={result?.isLoading}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        )}
                        {isDraft() && (
                            <Form.Item>
                                <Button
                                    type="primary"
                                    loading={deleteMaterialOrderResult?.isLoading}
                                    danger
                                    ghost
                                    onClick={() => {
                                        showConfirm();
                                    }}
                                >
                                    Delete
                                </Button>
                            </Form.Item>
                        )}
                    </div>
                    {isDraft() ? (
                        <Form.Item>
                            <Button
                                style={{}}
                                type="default"
                                loading={updateMaterialOrderResult?.isLoading}
                                icon={<SaveOutlined/>}
                                onClick={async () => {
                                    setIsRequired(false);
                                    await saveChangesInDraftOrder(true)
                                }}
                            >
                                Save Changes
                            </Button>
                        </Form.Item>
                    ) : (
                        <>
                            <Button
                                type="default"
                                loading={updateMaterialOrderResult?.isLoading}
                                icon={<SaveOutlined/>}
                                onClick={async () => {
                                    setIsRequired(false);
                                    await onFinish(true)
                                }}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                className={'ml-4'}
                                type="default"
                                onClick={() => {
                                    dispatch(showAddMaterialModalRequest());
                                }}
                                icon={<PlusOutlined/>}
                            >
                                Add From Material Request
                            </Button>
                        </>
                    )}
                </Card>
            </Form>

            <AddFromMaterialRequest onAddItem={addNewItemFromMaterialRequest}/>
        </>
    );
};

interface UnitOfMeasureSelectorInterface {
    name: string;
    onSelect?: (value: string) => void;
    value?: string;
    disabled?: boolean;
}


const UnitOfMeasureSelector = (props: UnitOfMeasureSelectorInterface) => {
    const units = useGetUnitsOfMeasureQuery();

    return (
        <Form.Item
            noStyle
            name={props.name}
            rules={[
                {
                    required: !props.disabled,
                    message: 'Required',
                },
            ]}
            initialValue={props.value}
        >
            <Select
                showArrow={false}
                dropdownMatchSelectWidth={false}
                style={{width: 40}}
                disabled={props.disabled}
                onSelect={(key ) => {
                    if (props.onSelect) {
                        props.onSelect(key);
                    }
                }}
            >
                {units?.data?.map((unit) => (
                    <Select.Option key={unit.id} value={unit.id}>
                            <span style={{fontSize: '0.60rem'}}>
                                {unit.name}
                            </span>
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default AddMaterialOrder;
