import { Empty } from 'antd';
import { useGetToolHolderModelsQuery } from '../../redux/api/tool-holders';
import { Toolholdermodel } from '../../interfaces/ToolHolder.Interface';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const ToolHolderModelsTable = () => {
	const { data, isLoading } = useGetToolHolderModelsQuery();

	const tableColumns = [
		{
			title: 'Manufacturer',
			render: (record: Toolholdermodel) => record?.manufacturer?.name,
		},
		{
			title: 'Model',
			render: (record: Toolholdermodel) => record?.model,
		},
		{
			title: 'Type',
			render: (record: Toolholdermodel) => record?.type?.name,
		},
		{
			title: 'Style',
			render: (record: Toolholdermodel) => record?.style?.name,
		},
		{
			title: 'Bore Diameter',
			render: (record: Toolholdermodel) =>
				record?.shank_diameter_inch
					? `${record.shank_diameter_inch} in.`
					: record?.shank_diameter_mm
					? `${record.shank_diameter_mm} mm`
					: null,
		},
		{
			title: 'Quantity on Hand',
			render: (record: Toolholdermodel) => record?.quantity_on_hand,
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no tool holder models to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CustomResponsiveTable
				columns={tableColumns}
				data={data}
				pagination={false}
				loading={isLoading}
				emptyTable={emptyTable}
			/>
		</div>
	);
};

export default ToolHolderModelsTable;
