import {
	fetchBaseQuery,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';

import { clearAuthToken } from '../../redux/slices/user';

import { notification } from 'antd';


interface IErrorData {
	detail: string
}

export function isFetchBaseQueryError(
	error: unknown
): error is FetchBaseQueryError {
	return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(error: unknown): error is { data: string } {
	return typeof error === 'object' && error != null && 'data' in error;
}

const baseQuery = fetchBaseQuery({
	baseUrl: `${import.meta.env.VITE_API_URL}/shop/api/`,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).user.authToken;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
	credentials: 'include',
});

export const baseQueryWithInterceptor: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);

	const errorData = result.error?.data as IErrorData;

	// Response interceptor
	if (result.error && result.error.status === 401 && errorData?.detail === "Given token not valid for any token type") {
		notification.error({
			duration: 10,
			message: 'Session has expired',
			description: `Please log in again to continue using the app.`,
			placement: 'topRight',
		});
		// Handle 401 error
		api.dispatch(clearAuthToken());
	}

	return result;
};
