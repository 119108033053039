import ToolHolderTable from '../../components/ToolHolderTable';
import { PlusOutlined } from '@ant-design/icons';

import Header from '../../components/Header';

import { Link } from 'react-router-dom';

function ToolHolders() {
	return (
		<>
			<Header
				title="Tool Holders"
				showOnClickButton
				buttonText={'Add Tool Holder'}
				icon={<PlusOutlined />}
				// handleOnClick={() => console.log('Add a function')}
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Tool Holders',
					},
				]}
			/>

			<ToolHolderTable />
		</>
	);
}

export default ToolHolders;
