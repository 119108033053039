import { combineReducers } from 'redux';
import { material } from './material';
import { user } from './user';
import { api } from './api';
import { toolHolderModel } from './toolHolderModel';
import { materialOrderLineItemModel } from './materialOrderLineItem';
import { materialRequest } from './material-request';
import { salesOrder } from './salesorder';
import { manufacturing } from './manufacturing';

const reducers = combineReducers({
	user: user.reducer,
	material: material.reducer,
	materialRequest: materialRequest.reducer,
	toolHolderModel: toolHolderModel.reducer,
	materialOrderLineItemModel: materialOrderLineItemModel.reducer,
	salesorder: salesOrder.reducer,
	manufacturing: manufacturing.reducer,
	[api.reducerPath]: api.reducer,
});

export default reducers;
