import {api} from "../slices/api.ts";
import {UserListInterface} from "../../interfaces/User.interface.ts";

export interface UserQueryInterface {
    page?: number;
    search?: string;
    ordering?: string;
}

export const extendedApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<
            { count: number; results: UserListInterface[] },
            UserQueryInterface
        >({
            query: (params) => {
                const queryParams = new URLSearchParams();
                Object.entries(params).forEach(([key, value]) => {
                    if (value !== undefined) {
                        queryParams.append(key, value.toString());
                    }
                });
                if (queryParams.toString() === '') {
                    return 'users/';
                }
                return `users/?${queryParams.toString()}`;
            },
        }),
    }),
});

export const { useGetUsersQuery } = extendedApiSlice;