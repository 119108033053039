import 'react';
import {
	Button,
	Card,
	Checkbox,
	Descriptions,
	Empty,
	Flex,
	Form,
	message,
	Modal,
	notification,
	Table,
	Tag,
	Tooltip,
	Typography,
} from 'antd';

import { useParams } from 'react-router';
import {
	useGetOrderLineItemByIdQuery,
	useUpdateOrderLineItemMutation,
} from '../../../redux/api/order';
import { useCreateVSCProgramMutation } from '../../../redux/api/versabuilt';
import { useEffect, useState } from 'react';
import {
	ArrowRightOutlined,
	PlusOutlined,
	QuestionCircleTwoTone,
} from '@ant-design/icons';
import { useCreateManufacturingRouteMutation } from '../../../redux/api/order-line-item.ts';
import { ManufacturingProcess } from '../../../interfaces/Manufacturing.Interface.ts';
import Loading from '../../../components/Loading/index.tsx';
import { Link } from 'react-router-dom';
import { isErrorWithMessage } from '../../../redux/helpers';
import AddManufacturingProcessModal from '../../../components/AddManufacturingProcessModal/index.tsx';
import {
	closeAddManufacturingProcessModal,
	showAddManufacturingProcessModal,
} from '../../../redux/slices/manufacturing.ts';
import { useDispatch } from 'react-redux';

import Header from '../../../components/Header/index.tsx';
import dayjs from 'dayjs';

import OrderLineItemStatuses from '../OrderLineItemStatuses/index.tsx';
import { PartDimensions } from '../PartDimensions';
import { FileDownloads } from '../FileDownloads';
import { Material } from '../Material';
import Thumbnail from '../Thumbnail/index.tsx';
import { getOrderLineItemStatusTagColor } from '../../../utils';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';

import OrderLineItemsTable from '../../../components/OrderLineItemsTable/index.tsx';

const { Title, Text } = Typography;

const OrderLineItemDetails = () => {
	const { id } = useParams();
	const dispatch = useDispatch();

	const [notify, notificationContext] = notification.useNotification();
	const [messageApi, messageContext] = message.useMessage();
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const [, modalContext] = Modal.useModal();

	const [form] = Form.useForm();
	const [createVSCProgramForm] = Form.useForm();

	const [
		confirmVSCProgramCreationModalVisible,
		setConfirmVSCProgramCreationModalVisible,
	] = useState<boolean>(false);

	const [selectedProcess, setSelectedProcess] =
		useState<ManufacturingProcess>();

	const { data, isLoading, refetch } = useGetOrderLineItemByIdQuery(
		{ id: id as string },
		{ skip: !id }
	);

	const [updateOrderLineItem, updateOrderLineItemResult] =
		useUpdateOrderLineItemMutation();

	const [createVSCProgram, createVSCProgramResult] =
		useCreateVSCProgramMutation();

	async function onClickCreateVSCProgram(
		id: string,
		overwrite_current: boolean
	) {
		await createVSCProgram({
			id: id,
			body: {
				order_line_item: data?.id,
				manufacturing_process: selectedProcess?.id,
				overwrite_current: overwrite_current,
			},
		});
	}

	async function handleClickKeyenceProgramRequired(e: CheckboxChangeEvent) {
		const checked = e.target.checked;

		if (data) {
			await updateOrderLineItem({
				id: data.id,
				values: {
					is_keyence_program_required: checked,
				},
			});

			refetch();
		}
	}

	async function handleClickAdditionalToolingRequired(e: CheckboxChangeEvent) {
		const checked = e.target.checked;

		if (data) {
			await updateOrderLineItem({
				id: data.id,
				values: {
					is_additional_tooling_required: checked,
				},
			});

			refetch();
		}
	}

	const onClickCreateVSCProgramHandler = async (
		process: ManufacturingProcess
	) => {
		if (process?.machine?.mill_automation_system?.id) {
			const fields = await createVSCProgramForm.validateFields();

			onClickCreateVSCProgram(
				process.machine?.mill_automation_system?.id,
				fields.overwrite_current
			);
		}
	};

	const [createManufacturingRoute, result] =
		useCreateManufacturingRouteMutation();

	const onFormSubmit = async (values: ManufacturingProcess) => {
		await createManufacturingRoute({
			id: id,
			body: {
				...values,
				setups: values.setups.map((setup, i) => {
					return {
						...setup,
						setup_number: i + 1,
					};
				}),
			},
		});
	};

	useEffect(() => {
		if (result.isSuccess) {
			form.resetFields();
			dispatch(closeAddManufacturingProcessModal());
			refetch();
		}
	}, [result]);

	useEffect(() => {
		if (updateOrderLineItemResult.isSuccess) {
			console.log(updateOrderLineItemResult);
			messageApi.open({
				type: 'success',
				content: 'Line item updated successfully',
			});
		}

		if (updateOrderLineItemResult.isError) {
			console.log(updateOrderLineItemResult);
			messageApi.open({
				type: 'error',
				content: 'An error occurred',
			});
		}
	}, [updateOrderLineItemResult]);

	useEffect(() => {
		if (createVSCProgramResult.isSuccess) {
			createVSCProgramForm.resetFields();
			setConfirmVSCProgramCreationModalVisible(false);
		}

		if (createVSCProgramResult.isError) {
			notify.error({
				message: 'An Error Occurred',
				description: isErrorWithMessage(createVSCProgramResult.error)
					? createVSCProgramResult?.error?.data
					: null,
			});
			setConfirmVSCProgramCreationModalVisible(false);
		}
	}, [createVSCProgramResult]);

	useEffect(() => {
		if (data) {
			setIsDisabled(
				data?.status === 'On Hold' ||
					data?.status === 'Completed' ||
					data?.status === 'Cancelled'
			);
		}
	}, [data]);

	return (
		<>
			<Header
				title="Order Line Item Details"
				breadcrumbItems={[
					{
						title: <Link to="/orders"> Home </Link>,
					},
					{
						title: <Link to="/orders"> Orders </Link>,
					},
					{
						title: (
							<Link to={`/orders/${data?.order?.id}/`}> Order Details </Link>
						),
					},
					{
						title: 'Order Line Item Details',
					},
				]}
			/>
			<div>
				{!data || isLoading ? (
					<Loading loading={true} />
				) : (
					<>
						<div className="w-full">
							<div className="flex flex-col md:flex-row gap-3 mb-10 items-stretch">
								<Card className="flex-grow w-fit self-stretch">
									<div className="flex flex-col lg:flex-row gap-5">
										<div className="sm:w-80 lg:w-72">
											<Thumbnail lineItem={data} />
										</div>
										<div>
											<Title level={5}>Details</Title>
											<div className="flex flex-wrap mb-5 gap-x-8 gap-y-8">
												<Flex vertical={true} align="start">
													<span className="text-slate-400">Item Name</span>
													<span>{data?.name}</span>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">Order Name</span>
													<Link to={`/orders/${data?.order?.id}`}>
														{data?.order?.name}
													</Link>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">Material</span>
													<span>{data?.material_name}</span>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">Surface Finish</span>
													<span>{data?.finish_name}</span>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">Coatings</span>
													<span>{data?.coating_name}</span>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">Quantity</span>
													{data?.cancelled_quantity > 0 ? (
														<div className="flex flex-row items-baseline gap-1">
															<Text delete>{data?.quantity}</Text>
															<Text>
																<ArrowRightOutlined
																	style={{ fontSize: '0.7rem' }}
																/>
															</Text>
															<Text strong mark>
																{data?.updated_quantity}
															</Text>
														</div>
													) : (
														<span>{data?.quantity}</span>
													)}
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">
														Required Shop Ship Date{' '}
														<Tooltip title="This is the required ship date for PartsBadger Production">
															<QuestionCircleTwoTone />
														</Tooltip>
													</span>

													<span>
														{dayjs(data.required_partner_ship_date).format(
															'dddd, MMM D YYYY'
														)}
													</span>
												</Flex>

												<Flex vertical={true} align="start">
													<span className="text-slate-400">
														Required Part Ship Date{' '}
														<Tooltip title="This is the date show to the customer on the sales order">
															<QuestionCircleTwoTone />
														</Tooltip>
													</span>

													<span className="font-bold">
														{dayjs(data.required_part_ship_date).format(
															'dddd, MMM D YYYY'
														)}
													</span>
												</Flex>
											</div>

											<div className="mb-10">
												<PartDimensions
													height={data?.height}
													width={data?.width}
													length={data?.length}
													volume={data?.volume}
													bbox_volume={data?.bbox_volume}
												/>
											</div>
											<div className="flex  flex-col wrap-wrap gap-y-8 gap-x-32">
												<div>
													<FileDownloads
														lineItem={data}
														refetchOrder={refetch}
														isDisabled={
															data?.status === 'On Hold' ||
															data?.status === 'Completed' ||
															data?.status === 'Cancelled'
														}
														enableBalloonedDrawings={false}
													/>
												</div>
												<div>
													<Title level={5}>Quoting Notes</Title>
													<span>
														{data?.partsbadger_production_quoting_notes
															? data.partsbadger_production_quoting_notes
															: 'None'}
													</span>
												</div>
												<div>
													<Title level={5}>Vendor Requirements</Title>
													<Flex wrap="wrap" gap="small">
														{data?.vendor_requirements &&
														data?.vendor_requirements?.length > 0 ? (
															data?.vendor_requirements?.map((requirement) => (
																<Tag
																	key={requirement}
																	color="gold-inverse"
																	style={{ margin: 0 }}
																>
																	{requirement}
																</Tag>
															))
														) : (
															<>None</>
														)}
													</Flex>
												</div>
												<div>
													<Material
														lineItem={data}
														isDisabled={isDisabled}
														refetchLineItem={refetch}
													/>
												</div>
												<div>
													<Title level={5}>Production</Title>
													<div className="flex flex-col gap-3">
														<Checkbox
															defaultChecked={data?.is_keyence_program_required}
															disabled={isDisabled}
															onChange={handleClickKeyenceProgramRequired}
														>
															Keyence Program Required
														</Checkbox>
														<Checkbox
															defaultChecked={
																data?.is_additional_tooling_required
															}
															disabled={isDisabled}
															onChange={handleClickAdditionalToolingRequired}
														>
															Additional Tooling Required
														</Checkbox>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Card>
								<Card className="w-full md:w-96 self-stretch">
									<Flex
										align="baseline"
										justify="space-between"
										className="mb-3"
									>
										<Title level={5}>Production Status</Title>

										<Tag color={getOrderLineItemStatusTagColor(data?.status)}>
											{data?.status}
										</Tag>
									</Flex>
									<OrderLineItemStatuses
										disabled={isDisabled}
										lineItem={data}
										refetchLineItem={refetch}
									/>
								</Card>
							</div>
							<Flex vertical className="w-full mb-10">
								<Title level={4}>
									Order History{' '}
									<Tooltip title="The Order History is based on Part Name and Solid Model Volume.">
										<QuestionCircleTwoTone />
									</Tooltip>
								</Title>
								<Card>
									<OrderLineItemsTable
										filterByPreviouslyOrderedParts={true}
										lineItemId={id}
										hideFilters={true}
									/>
								</Card>
							</Flex>
							<Flex vertical className="w-full">
								<Flex justify="space-between">
									<Title level={4}>Manufacturing Processes</Title>
									{data?.manufacturing_route ? (
										<Button
											type="primary"
											icon={<PlusOutlined />}
											onClick={() =>
												dispatch(showAddManufacturingProcessModal())
											}
										>
											Add Process
										</Button>
									) : null}
								</Flex>
								{data?.manufacturing_route ? (
									<>
										{data?.manufacturing_route?.processes?.map(
											(process, index) => (
												<div key={index} className="mb-3">
													<Card>
														<Descriptions
															title={`Process ${process.process_number}`}
															size={'small'}
															style={{ marginBottom: '1rem' }}
															// extra={[
															// 	<Button
															// 		key={1}
															// 		type="link"
															// 		icon={<EditFilled />}
															// 		size="small"
															// 		onClick={() => {
															// 			setSelectedProcess(process);
															// 			dispatch(showEditManufacturingProcessModal());
															// 		}}
															// 	>
															// 		Edit
															// 	</Button>,
															// ]}
														>
															<Descriptions.Item label="Type">
																{process?.process_type?.display_name}
															</Descriptions.Item>
															<Descriptions.Item label="Machine">
																<Link to={`/machines/${process.machine.id}`}>
																	{process?.machine.name}
																</Link>
															</Descriptions.Item>
														</Descriptions>
														{process?.description ? (
															<Descriptions
																layout="vertical"
																style={{ marginBottom: '1rem' }}
															>
																<Descriptions.Item
																	label="Description"
																	style={{ paddingBottom: '0.5rem' }}
																>
																	{process?.description}
																</Descriptions.Item>
															</Descriptions>
														) : null}
														<Title level={5}>Setups</Title>
														<Table
															key={index}
															size="small"
															dataSource={process.setups}
															pagination={false}
															className="border mb-4"
															columns={[
																{
																	title: 'Setup No.',
																	width: 150,
																	render: (record) => (
																		<>
																			<Tag
																				color="geekblue"
																				style={{ fontWeight: 'bold' }}
																			>
																				{record.setup_number}
																			</Tag>
																			{record.is_dovetailing_setup ? (
																				<Tag
																					color="cyan"
																					style={{ fontWeight: 'bold' }}
																				>
																					Dovetailing
																				</Tag>
																			) : null}
																		</>
																	),
																},
																{
																	title:
																		process?.process_type?.display_name ===
																		'Milling'
																			? 'Vise'
																			: process?.process_type?.display_name ===
																			  'Turning'
																			? 'Spindle'
																			: null,
																	render: (record) =>
																		process?.process_type?.display_name ===
																		'Milling'
																			? `${record?.vise?.vise_number} - ${record?.vise?.mobility}`
																			: process?.process_type?.display_name ===
																			  'Turning'
																			? `${record?.spindle?.type}`
																			: null,
																},
																{
																	title: 'Jaw Set',
																	render: (record) =>
																		record?.jaw_set?.display_name,
																},
																{
																	title: 'Stock Facing Amount',
																	width: 200,
																	render: (record) =>
																		record?.stock_facing_amount
																			? `${record?.stock_facing_amount} ${record?.stock_facing_amount_unit?.name}`
																			: null,
																},
															]}
														/>

														{process?.can_create_vsc_program ? (
															<Button
																type="primary"
																onClick={() => {
																	setSelectedProcess(process);
																	setConfirmVSCProgramCreationModalVisible(
																		true
																	);
																}}
															>
																Create Robot Program
															</Button>
														) : null}
													</Card>
												</div>
											)
										)}
									</>
								) : (
									<Card>
										<Empty
											imageStyle={{ height: 60 }}
											description={
												<span>No Manufacturing Processes Defined</span>
											}
										>
											<Button
												type="primary"
												icon={<PlusOutlined />}
												onClick={() =>
													dispatch(showAddManufacturingProcessModal())
												}
											>
												Add Process
											</Button>
										</Empty>
									</Card>
								)}
							</Flex>
						</div>
					</>
				)}
			</div>

			{id ? (
				<AddManufacturingProcessModal
					onSubmit={onFormSubmit}
					loading={result.isLoading}
				/>
			) : null}

			{selectedProcess ? (
				<Modal
					title={'Proceed with Robot Program Creation?'}
					open={confirmVSCProgramCreationModalVisible}
					onCancel={() => setConfirmVSCProgramCreationModalVisible(false)}
					centered={true}
					width={720}
					styles={{ header: { marginBottom: '2rem' } }}
					closeIcon={false}
					maskClosable={!createVSCProgramResult.isLoading}
					cancelButtonProps={{
						onClick: () => setConfirmVSCProgramCreationModalVisible(false),
						disabled: createVSCProgramResult.isLoading,
					}}
					okText="Continue"
					okButtonProps={{
						form: 'create-vsc-program-form',
						htmlType: 'submit',
						type: 'primary',
						loading: createVSCProgramResult.isLoading,
						onClick: () => onClickCreateVSCProgramHandler(selectedProcess),
					}}
				>
					<Form
						form={createVSCProgramForm}
						name="create-vsc-program-form"
						layout="vertical"
						initialValues={{ overwrite_current: true }}
					>
						<Form.Item name="overwrite_current" valuePropName="checked">
							<Checkbox disabled={true}>Overwrite Current Program</Checkbox>
						</Form.Item>
						<Typography.Paragraph>
							WARNING: This will create a VSC configuration on{' '}
							<strong>
								{selectedProcess?.machine.mill_automation_system?.display_name}
							</strong>
							, and overwrite the current program.
						</Typography.Paragraph>
					</Form>
				</Modal>
			) : null}

			{messageContext}
			{notificationContext}
			{modalContext}
		</>
	);
};

export default OrderLineItemDetails;
