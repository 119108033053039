import { Col, Row, Input } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import { useGetMaterialOrdersQuery } from '../../../redux/api/material';
import MaterialOrdersTable from '../../../components/MaterialOrdersTable';
import { OrderMaterial } from '../../../interfaces/Material.Interface';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import type { SearchProps } from 'antd/es/input';

import Header from '../../../components/Header';

import { Link } from 'react-router-dom';

interface MaterialOrderQuery {
	page?: number;
	search?: string;
	ordering?: string;
}

const { Search } = Input;

function MaterialOrders() {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const searchUrl = queryParams.get('search') || '';
	const ordering = queryParams.get('ordering') || '';

	const materialQuery: MaterialOrderQuery = {};

	if (currentPageUrl) {
		materialQuery.page = currentPageUrl;
	}

	if (searchUrl) {
		materialQuery.search = searchUrl;
	}

	if (ordering && ordering !== '') {
		materialQuery.ordering = ordering;
	}

	const { data, isLoading } = useGetMaterialOrdersQuery(materialQuery, {
		refetchOnFocus: true,
		refetchOnMountOrArgChange: true,
	});

	const [materialOrders, setMaterialOrders] = useState<OrderMaterial[]>([]);

	const [currentSearch, setCurrentSearch] = useState<string>(searchUrl);
	const [currentPage, setCurrentPage] = useState<number>(currentPageUrl);

	const [total, setTotal] = useState<number>(0);

	const onSearch: SearchProps['onSearch'] = async (value: string) => {
		queryParams.set('search', value);
		queryParams.set('page', '1');
		setCurrentSearch(value);
		navigate({ search: queryParams.toString() });
		setCurrentPage(1);
	};

	const pageChanged = async (page: number) => {
		queryParams.set('page', page.toString());
		navigate({ search: queryParams.toString() });
		setCurrentPage(page);
	};

	useEffect(() => {
		if (data) {
			setMaterialOrders(data.results as OrderMaterial[]);
			setTotal(data.count ? data.count : 0);
		}
	}, [data]);

	return (
		<>
			<Header
				title="Material Orders"
				showLinkButton
				buttonText={'Add Material Order'}
				icon={<PlusOutlined />}
				link="/material/orders/add"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Material Orders',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<div className="search-input">
						<Search
							placeholder="Search"
							onSearch={onSearch}
							defaultValue={currentSearch}
							style={{ width: '100%' }}
						/>
					</div>
					<MaterialOrdersTable
						data={materialOrders}
						isLoading={isLoading}
						total={total}
						currentPage={currentPage}
						onChangePage={(page: number) => {
							pageChanged(page);
						}}
						onSort={(column: string | undefined) => {
							if (column) {
								queryParams.set('ordering', column);
							} else {
								queryParams.delete('ordering');
							}
							navigate({ search: queryParams.toString() });
						}}
					/>
				</Col>
			</Row>
		</>
	);
}

export default MaterialOrders;
