import { useEffect, useState } from 'react';
import Signature from 'signature_pad';
import {Button, notification} from 'antd';

interface SignatureProps {
    signature: (signature: string) => void;
    showSignature: boolean;
}
export const SignatureDefault = (props: SignatureProps) => {
    const [signaturePad, setSignaturePad] = useState<Signature | null>(null);
    const [savedSignature, setSavedSignature] = useState<string | null>(null);

    const readyPadSignature = () => {
        const wrapper = document.getElementById('signature-pad');
        const canvas = wrapper?.querySelector('canvas');

        if (canvas) {
            const context = canvas.getContext('2d');
            if (context) {
                canvas.width = 748;
                canvas.height = 125;
                context.scale(1, 1);
            }

            const tempSignaturePad = new Signature(canvas, {
                backgroundColor: 'rgb(255, 255, 255)',
            });
            setSignaturePad(tempSignaturePad);
        }
    };

    const handleSaveSignature = async () => {
        if (signaturePad) {
            // check if the signature pad is empty
            const data = signaturePad.toData();
            if (data.length === 0) {
                notification.error({
                    message: 'The signature is empty',
                    placement: 'topRight'
                });
                return;
            }

            const signatureData = signaturePad.toDataURL();
            setSavedSignature(signatureData);
            signaturePad.clear();
            props.signature(signatureData);
        }
    };

    const handleClearSignature = () => {
        if (signaturePad) {
            setSavedSignature(null);
            signaturePad.clear();
            props.signature('');
        }
    };

    useEffect(() => {
        readyPadSignature();
        setSavedSignature(null);
    }, []);

    return (
        <div className={'flex flex-col'}>
            <div className="flex flex-col items-center justify-center" style={{ border: '1px solid #000' }}>
                <div id="signature-pad">
                    <canvas className="signature-canvas"></canvas>
                </div>
            </div>
            <div>
                <Button type={'default'} onClick={handleClearSignature} className={'w-full'}>
                    Clear
                </Button>
                <Button type={'default'} onClick={handleSaveSignature} className={'w-full'}>
                    Trim
                </Button>
            </div>
            <div className="mt-4">
                {savedSignature && props.showSignature && (
                    <img width={'60%'} alt="saved-signature" src={savedSignature} />
                )}
            </div>
        </div>
    );
};
