//@ts-nocheck
import React, { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Spin } from 'antd';
import { NodeIndexOutlined, LoadingOutlined } from '@ant-design/icons';

import * as THREE from 'three';

import { ReactThreeFiber, Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Html, OrbitControls, OrthographicCamera, useCamera } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

export async function httpFetch<T>(endpoint: RequestInfo, options: any = {}, addHeaders: any = {}): Promise<T> {
    // setup default headers
    return fetch(`${endpoint}`, {
        ...options,
        headers: { ...addHeaders },
    }).then(res => {
        if (res.ok) return res.json() as Promise<T>;
        return Promise.reject(res);
    });
}

export async function get<T>(endpoint: string, fetchOptions: any = {}, addHeaders: any = {}) {
    return httpFetch<T>(endpoint, fetchOptions, addHeaders);
}

export type Orientation = {
    offsetFactor: {
        x: number;
        y: number;
        z: number;
    };
    axisAngle: {
        x: number;
        y: number;
        z: number;
    };
};

const TOP: Orientation = {
    offsetFactor: {
        x: 0,
        y: 0,
        z: 1,
    },
    axisAngle: {
        x: 0,
        y: 0,
        z: 0,
    },
};

const BOTTOM: Orientation = {
    offsetFactor: {
        x: 0,
        y: 0,
        z: -1,
    },
    axisAngle: {
        x: Math.PI,
        y: 0,
        z: 0,
    },
};

const FRONT: Orientation = {
    offsetFactor: {
        x: 0,
        y: -1,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: 0,
        z: 0,
    },
};

const BACK: Orientation = {
    offsetFactor: {
        x: 0,
        y: 1,
        z: 0,
    },
    axisAngle: {
        x: -(Math.PI / 2),
        y: 0,
        z: Math.PI,
    },
};

const LEFT: Orientation = {
    offsetFactor: {
        x: -1,
        y: 0,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: -(Math.PI / 2),
        z: 0,
    },
};

const RIGHT: Orientation = {
    offsetFactor: {
        x: 1,
        y: 0,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: Math.PI / 2,
        z: 0,
    },
};

type Props = JSX.IntrinsicElements['lineSegments'] & {
    threshold?: number;
    color?: ReactThreeFiber.Color;
};

export function Edges({ userData, children, geometry, threshold = 15, color = 'black', ...props }: Props) {
    const ref = React.useRef<THREE.LineSegments>(null!);
    React.useLayoutEffect(() => {
        const parent = ref.current.parent as THREE.Mesh;
        if (parent) {
            const geom = geometry || parent.geometry;
            if (geom !== ref.current.userData.currentGeom || threshold !== ref.current.userData.currentThreshold) {
                ref.current.userData.currentGeom = geom;
                ref.current.userData.currentThreshold = threshold;
                ref.current.geometry = new THREE.EdgesGeometry(geom, threshold);
            }
        }
    });
    return (
        <lineSegments ref={ref} raycast={() => null} {...props}>
            {children ? children : <lineBasicMaterial color={color} />}
        </lineSegments>
    );
}

export const cadShaderFragment = `
uniform float opacity;
uniform float ambientBrightness;     // the brightness of edge lighting (suggested default: 0.1, prefer 0.0 to 1.0)
uniform float directFactor;  // the brightness of front lighting (suggested default: 1.0, prefer 0.0 to 1.0)
uniform vec4 tint;

varying vec3 fPosition;
varying vec3 fNormal;
varying vec3 fColor;

void main()
{
    float normalDot = abs(dot(fNormal, normalize(-fPosition)));
    float lightAmount = mix(ambientBrightness, 1.0, normalDot);
    vec3 color = fColor * lightAmount;
    if (tint.w > 0.) {
        float tintAmount = mix(tint.w*0.1, tint.w, normalDot);
        color = mix(color, tint.xyz, tintAmount);
    }
    gl_FragColor = vec4(color, opacity);
}`;

export const cadShaderVertex = `
varying vec3 fNormal;
varying vec3 fPosition;
varying vec3 fColor;

const float lumaMin = 0.15;
const float lumaRange = 1. - lumaMin;

float getLumaFromColor(const in vec3 color) {
    return color.x * 0.299 + color.y * 0.587 + color.z * 0.114;
}

vec3 compressColor(vec3 color)
{
    float luma = getLumaFromColor(color);
    return color * lumaRange + lumaMin;
}

void main()
{
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    fNormal = normalize(normalMatrix * normal);
    fColor = compressColor(color);
    fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;
}`;

export const Shape: React.FC<{ shape: any; shapeId: any }> = ({ shapeId, shape }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <mesh onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)} geometry={shape as any}>
            <shaderMaterial
                attach="material"
                args={[
                    {
                        side: THREE.DoubleSide,
                        vertexColors: THREE.VertexColors,
                        uniforms: {
                            opacity: { type: 'f', value: 1.0 },
                            ambientBrightness: { type: 'f', value: 0.3 },
                            tint: { type: 'v4', value: new THREE.Vector4(0, 0, 0, 0) },
                        },
                        vertexShader: cadShaderVertex,
                        fragmentShader: cadShaderFragment,
                    },
                ]}
            />
        </mesh>
    );
};

export const Edge: React.FC<{ edge: any; edgeId: any }> = ({ edgeId, edge }) => {
    const [hovered, setHovered] = useState(false);

    //Todo fix geometry  prop
    //@ts-ignore
    return (
        <line geometry={edge as any} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
            <lineBasicMaterial linewidth={1.5} attach="material" color={hovered ? ('blue' as any) : ('black' as any)} />
        </line>
    );
};

function Box({ position, color, onClick }) {
    return (
        <mesh position={position} onClick={() => onClick()}>
            <boxBufferGeometry args={[10, 10, 10]} attach="geometry" />
            <meshPhongMaterial color={color} attach="material" />
        </mesh>
    );
}

const Viewcube = () => {
    const { gl, scene, camera, size } = useThree();
    const virtualScene = useMemo(() => new THREE.Scene(), []);
    const virtualCam = useRef();
    const ref = useRef();
    const [hover, set] = useState(null);
    const matrix = new THREE.Matrix4();

    const texture_front = useLoader(TextureLoader, '/textures/front.jpg');
    const texture_front_hover = useLoader(TextureLoader, '/textures/front-hover.jpg');
    const texture_back = useLoader(TextureLoader, '/textures/back.jpg');
    const texture_back_hover = useLoader(TextureLoader, '/textures/back-hover.jpg');
    const texture_top = useLoader(TextureLoader, '/textures/top.jpg');
    const texture_top_hover = useLoader(TextureLoader, '/textures/top-hover.jpg');
    const texture_bottom = useLoader(TextureLoader, '/textures/bottom.jpg');
    const texture_bottom_hover = useLoader(TextureLoader, '/textures/bottom-hover.jpg');
    const texture_left = useLoader(TextureLoader, '/textures/left.jpg');
    const texture_left_hover = useLoader(TextureLoader, '/textures/left-hover.jpg');
    const texture_right = useLoader(TextureLoader, '/textures/right.jpg');
    const texture_right_hover = useLoader(TextureLoader, '/textures/right-hover.jpg');

    useFrame(() => {
        matrix.copy(camera.matrix).invert();
        ref.current.quaternion.setFromRotationMatrix(matrix);
        gl.autoClear = true;
        gl.render(scene, camera);
        gl.autoClear = false;
        gl.clearDepth();
        gl.render(virtualScene, virtualCam.current);
    }, 1);

    const top_ = () => {
        const { offsetFactor, axisAngle } = TOP;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        // const positionTween = new TWEEN.Tween(cameraRef.current.position)
        //   .to(finishPosition, 300)
        //   .easing(TWEEN.Easing.Circular.Out);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        // rotate camera too!
        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
        // const quaternionTween = new TWEEN.Tween(cameraRef.current.quaternion)
        //   .to(finishQuaternion, 300)
        //   .easing(TWEEN.Easing.Circular.Out);

        // positionTween.start();
        // quaternionTween.start();
    };

    const bottom = () => {
        const { offsetFactor, axisAngle } = BOTTOM;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };

    const front = () => {
        const { offsetFactor, axisAngle } = FRONT;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };

    const back = () => {
        const { offsetFactor, axisAngle } = BACK;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };

    const left = () => {
        const { offsetFactor, axisAngle } = LEFT;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };

    const right = () => {
        const { offsetFactor, axisAngle } = RIGHT;

        const offsetUnit = camera.position.length();
        const offset = new THREE.Vector3(
            offsetUnit * offsetFactor.x,
            offsetUnit * offsetFactor.y,
            offsetUnit * offsetFactor.z
        );

        const center = new THREE.Vector3();
        const finishPosition = center.add(offset);

        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);

        const euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);

        const finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);

        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };

    return createPortal(
        <>
            <OrthographicCamera ref={virtualCam} makeDefault={false} position={[0, 0, 100]} />
            <mesh
                ref={ref}
                raycast={useCamera(virtualCam)}
                position={[size.width / 2 - 80, size.height / 2 - 80, 0]}
                onClick={e => {
                    const face = Math.floor(e.faceIndex / 2);
                    switch (face) {
                        case 0:
                            right();
                            break;
                        case 1:
                            left();
                            break;
                        case 2:
                            back();
                            break;
                        case 3:
                            front();
                            break;
                        case 4:
                            top_();
                            break;
                        case 5:
                            bottom();
                            break;
                    }
                }}
                onPointerOut={e => set(null)}
                onPointerMove={e => set(Math.floor(e.faceIndex / 2))}
            >
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 0 ? texture_front_hover : texture_front}
                    // color={hover === 0 ? 'hotpink' : 'white'}
                />
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 1 ? texture_back_hover : texture_back}
                    // color={hover === 1 ? 'hotpink' : 'white'}
                />
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 2 ? texture_top_hover : texture_top}
                    // color={hover === 2 ? 'hotpink' : 'white'}
                />
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 3 ? texture_bottom_hover : texture_bottom}
                    // color={hover === 3 ? 'hotpink' : 'white'}
                />
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 4 ? texture_left_hover : texture_left}
                    // color={hover === 4 ? 'hotpink' : 'white'}
                />
                <meshLambertMaterial
                    attachArray="material"
                    map={hover === 5 ? texture_right_hover : texture_right}
                    // color={hover === 5 ? 'hotpink' : 'white'}
                />

                <boxGeometry args={[60, 60, 60]} />
                <Edges color={0x555555} threshold={15} scale={1.002} />
            </mesh>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} intensity={0.5} />
        </>,
        virtualScene
    );
};

const Controls: FC<{ partRef: any; modelShapes: any[] }> = ({ partRef, modelShapes }) => {
    const { camera, gl } = useThree();
    const controlsRef = useRef(null);

    const [modelMeta, setModelMeta] = useState<any>({
        boundingSphere: {
            center: new THREE.Vector3(0, 0, 0),
            radius: 0,
        },
        boundingBox: {
            min: new THREE.Vector3(0, 0, 0),
            max: new THREE.Vector3(0, 0, 0),
        },
    });

    useEffect(() => {
        if (partRef.current) {
            const box = new THREE.Box3().setFromObject(partRef.current);
            const sphere = new THREE.Sphere();
            console.log('computed');
            box.getBoundingSphere(sphere);
            setModelMeta({
                boundingSphere: sphere,
                boundingBox: box,
            });
        }
    }, [modelShapes, partRef]);

    useEffect(() => {
        if (modelMeta.boundingSphere.radius > 0) {
            const offset = 4;

            const boundingBox = modelMeta.boundingBox;
            const center = boundingBox.getCenter();
            const size = boundingBox.getSize();

            // get the max side of the bounding box (fits to width OR height as needed )
            const maxDim = Math.max(size.x, size.y, size.z);

            camera.fov = 24; // Set camera fov to something closer to orthographic

            const fov = camera.fov * (Math.PI / 180);
            let cameraZ = Math.abs(maxDim * Math.tan(fov * 2));

            cameraZ *= offset; // zoom out a little so that objects don't fill the screen

            // Plce initial camera view at a three-quaters position
            camera.position.y = cameraZ;
            camera.position.x = cameraZ;
            camera.position.z = cameraZ;

            const minZ = boundingBox.min.z;
            const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

            camera.far = cameraToFarEdge * 10;
            camera.updateProjectionMatrix();

            if (controlsRef.current !== null) {
                // set camera to rotate around center of loaded object
                controlsRef.current.target = center;
                // prevent camera from zooming out far enough to create far plane cutoff
                // controlsRef.current.maxDistance = cameraToFarEdge * 2;
                controlsRef.current.saveState();
            } else {
                camera.lookAt(center);
            }
        }
    }, [partRef, modelMeta.boundingSphere.radius]);

    return (
        <group>
            {/* <TrackballControls
        rotateSpeed={10}
        args={[camera, gl.domElement]} /> */}
            <OrbitControls
                ref={controlsRef}
                enablePan={true}
                enableZoom={true}
                enableDamping={true}
                dampingFactor={0.5}
                target={modelMeta.boundingSphere.center}
                minDistance={modelMeta.boundingSphere.radius / 5}
                maxDistance={modelMeta.boundingSphere.radius * 5}
                args={[camera, gl.domElement]}
            />
        </group>
    );
};

const MeasureTool: FC<{
    isMeasuring: boolean;
    pointOne: any;
    pointTwo: any;
    measureLine: any;
    distance: any;
    midpoint: any;
}> = props => {
    const { isMeasuring, measureLine, distance, midpoint, pointOne, pointTwo } = props;

    if (isMeasuring) {
        return (
            <group>
                {pointOne && (
                    <mesh position={pointOne}>
                        <sphereBufferGeometry attach="geometry" args={[1, 1, 1]} />
                        <meshStandardMaterial attach="material" color={'black'} />
                    </mesh>
                )}

                {pointTwo && (
                    <mesh position={pointTwo}>
                        <sphereBufferGeometry attach="geometry" args={[1, 1, 1]} />
                        <meshStandardMaterial attach="material" color={'black'} />
                    </mesh>
                )}

                {pointOne && pointTwo && measureLine && (
                    //@ts-ignore
                    <line geometry={measureLine}>
                        <lineBasicMaterial linewidth={1} attach="material" color="black" />
                    </line>
                )}
                {distance && midpoint && (
                    //@ts-ignore
                    <Html position={midpoint}>
                        <span style={{ backgroundColor: '#ffffffaa' }}>📏 {distance.toFixed(4)}mm</span>
                    </Html>
                )}
            </group>
        );
    } else {
        return null;
    }
};

const ThreeDViewer: FC<{ url: string; canvasStyle?: React.CSSProperties }> = ({ url, canvasStyle }) => {
    const [modelLoading, setModelLoading] = useState<boolean>(false);

    const [modelShapes, setModelShapes] = useState<Array<any>>([]);
    const [modelEdges, setModelEdges] = useState<Array<any>>([]);
    const partRef = useRef(null);

    const [isMeasuring, setIsMeasuring] = useState<boolean>(false);
    const [measureLine, setMeasureLine] = useState<any>(null);
    const [pointOne, setPointOne] = useState<any>(null);
    const [pointTwo, setPointTwo] = useState<any>(null);
    const [distance, setDistance] = useState<any>(null);
    const [midpoint, setMidpoint] = useState<any>(null);

    const measureClick = (e: any) => {
        if (!isMeasuring) {
            console.log(e.point);
            return false;
        }

        // third click clears measure tool points
        if (pointOne && pointTwo) {
            setPointOne(null);
            setPointTwo(null);
            setDistance(null);
            setMidpoint(null);
            setMeasureLine(null);
            // second click sets point two and computes midpoint (for text display location)
        } else if (pointOne) {
            const { x, y, z } = e.point;
            const p = new THREE.Vector3(x, y, z);
            const l = new THREE.BufferGeometry().setFromPoints([pointOne, p]);
            const l3 = new THREE.Line3(pointOne, p);
            const mp = new THREE.Vector3();

            setPointTwo(p);
            setMeasureLine(l);
            setDistance(pointOne.distanceTo(p));
            l3.getCenter(mp);
            setMidpoint(mp);
            // first click starts measuring
        } else {
            const { x, y, z } = e.point;
            setPointOne(new THREE.Vector3(x, y, z));
        }
    };

    useEffect(() => {
        (async () => {
            setModelLoading(true);

            const loader = new THREE.BufferGeometryLoader();

            const modelJSON = await get<any>(url);
            const model = modelJSON.model;

            const shapes: any[] = [];
            const edges: any[] = [];

            Object.keys(model.shapes).forEach(shpId => {
                const bufferGeometry = loader.parse(model.shapes[shpId]);

                bufferGeometry.computeBoundingBox();
                bufferGeometry.computeBoundingSphere();

                shapes.push(bufferGeometry);
            });

            Object.keys(model.edges).forEach(edgId => {
                const bufferGeometry = loader.parse(model.edges[edgId]);
                edges.push(bufferGeometry);
            });

            setModelShapes(shapes);
            setModelEdges(edges);

            setModelLoading(false);
        })();
    }, [url]);

    return (
        <>
            <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                {modelLoading ? (
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1,
                        }}
                    >
                        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column' }}>
                            <LoadingOutlined style={{ fontSize: '20px' }} />
                            Loading 3D Model...
                        </div>
                    </div>
                ) : null}

                <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, padding: '2rem' }}>
                    <Button
                        style={{ height: '4rem', marginBottom: '1rem' }}
                        type={isMeasuring ? 'primary' : 'default'}
                        onClick={() => setIsMeasuring(!isMeasuring)}
                    >
                        <NodeIndexOutlined />
                        <br />
                        <span>Ruler</span>
                    </Button>
                </div>

                <Canvas style={canvasStyle} dpr={window.devicePixelRatio}>
                    <Suspense fallback={null}>
                        <Viewcube />
                    </Suspense>

                    <Controls partRef={partRef} modelShapes={modelShapes} />
                    <MeasureTool
                        isMeasuring={isMeasuring}
                        pointOne={pointOne}
                        pointTwo={pointTwo}
                        measureLine={measureLine}
                        distance={distance}
                        midpoint={midpoint}
                    />
                    <ambientLight />
                    <group ref={partRef} onClick={e => measureClick(e)}>
                        {modelShapes.map(shp => (
                            <Shape key={shp.uuid} shapeId={shp.uuid} shape={shp} />
                        ))}
                        {modelEdges.map(edg => (
                            <Edge key={edg.uuid} edgeId={edg.uuid} edge={edg} />
                        ))}
                    </group>
                </Canvas>
            </div>
        </>
    );
};

export default ThreeDViewer;
