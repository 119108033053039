import { useParams } from 'react-router';
import { Alert, Button, Card, Col, Divider, Row } from 'antd';
import Header from '../../../components/Header';
import { useGetMachineByIdQuery } from '../../../redux/api/machines';
import { Link } from 'react-router-dom';

function Machine() {
	const { id } = useParams<{ id: string }>();

	const { data, isLoading } = useGetMachineByIdQuery(
		{ id: id as string },
		{ skip: !id }
	);

	return (
		<>
			<Header
				title={data?.name as string}
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: <Link to="/machines"> Machines </Link>,
					},
					{
						title: 'Machine Details',
					},
				]}
			/>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Card className="shadow-sm" loading={isLoading}>
						<Row>
							<Col span={12}>
								<div className="mb-3">
									<h2 className="text-2xl mb-2">Details</h2>

									<div className="flex justify-between">
										<span className="font-bold">Machine:</span>{' '}
										{`${data?.machine_model?.manufacturer?.name} ${data?.machine_model?.model}`}
									</div>
									<div className="flex justify-between">
										<span className="font-bold">Mill Automation System:</span>{' '}
										{data?.mill_automation_system?.name}
									</div>
								</div>

								{data?.mill_automation_system ? (
									<div className="mb-3">
										<a
											href={`http://${data?.mill_automation_system?.vsc_api_ip_address}:${data?.mill_automation_system?.vsc_api_port}/`}
											target="_blank"
											rel="noreferrer"
										>
											<Button type="primary">View VSC Dashboard</Button>
										</a>
									</div>
								) : null}

								<Divider />

								<h2 className="text-2xl mb-2">Status</h2>
								{data?.is_on_network ? (
									<>
										<div className="flex justify-between">
											<span className="font-bold">Status:</span>{' '}
											{data?.status?.machine_state}
										</div>
										<div className="flex justify-between">
											<span className="font-bold">Program Number:</span>{' '}
											{data?.status?.program_number}
										</div>
										<div className="flex justify-between">
											<span className="font-bold">Sub Program Number:</span>{' '}
											{data?.active_sub_program}
										</div>
										<div className="flex justify-between">
											<span className="font-bold">Total Parts:</span>{' '}
											{data?.status?.total_parts}
										</div>
										<div className="flex justify-between">
											<span className="font-bold">Power On Time:</span>{' '}
											{Number(
												data?.power_on_time?.split(':')[0]
											)?.toLocaleString('en-US')}{' '}
											Hrs. {data?.power_on_time?.split(':')[1]} Min.
										</div>
										<div className="flex justify-between">
											<span className="font-bold">Motion Time:</span>{' '}
											{Number(data?.motion_time?.split(':')[0])?.toLocaleString(
												'en-US'
											)}{' '}
											Hrs. {data?.motion_time?.split(':')[1]} Min.
										</div>
									</>
								) : (
									<Alert
										showIcon
										type="warning"
										description={'Machine is not on the network'}
										message={<span className="font-bold">Alert</span>}
									/>
								)}
							</Col>
							<Col span={12}>
								<img src={data?.thumbnail?.file} alt="Thumbnail" />
							</Col>
						</Row>
					</Card>
				</Col>
				{data?.mill_automation_system ? (
					<Col span={24}>
						<Card title="VSC Panel">
							<iframe
								src={`http://${data?.mill_automation_system?.vsc_api_ip_address}:${data?.mill_automation_system?.vsc_api_port}/`}
								width="100%"
								style={{ height: '50vh' }}
							/>
						</Card>
					</Col>
				) : null}
			</Row>
		</>
	);
}

export default Machine;
