import {
	Button,
	Empty,
	Upload,
	Select,
	Tag,
	TableProps,
	Timeline,
	Menu,
	Dropdown,
} from 'antd';
import {
	DataTableMaterial,
	MaterialObject,
	MaterialReceivedInterface,
} from '../../interfaces/Material.Interface';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { SorterResult } from 'antd/es/table/interface';

import { MaterialRemenant } from '../../interfaces/MaterialRemenat';

import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';
import {
	CheckCircleFilled,
	DownOutlined,
	FileAddOutlined,
	UploadOutlined,
	WarningOutlined,
} from '@ant-design/icons';

import dayjs from 'dayjs';

import SalesOrderLineItemSelect from '../SalesOrderLineItemSelect';

export interface localData {
	id: string;
	isLoading: boolean;
}

const MaterialTable = (props: DataTableMaterial) => {
	const USD = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	const PARTIALLY_DELIVERED = 'Partially Delivered';

	const isPartiallyDelivered = (record: MaterialRemenant | MaterialObject) => {
		if (record.status === PARTIALLY_DELIVERED) {
			return (
				<>
					<div style={{ fontSize: '11px', paddingTop: '10px' }}>
						Total Delivered: {record.delivered_quantity}/
						{record.quantity_ordered}{' '}
						<EditOutlined
							style={{ color: 'red' }}
							onClick={() => {
								props?.onChangeStatus(record);
							}}
						/>
					</div>
				</>
			);
		}
		return;
	};

	const onChange: TableProps<MaterialRemenant | MaterialObject>['onChange'] = (
		_pagination,
		_filters,
		sorter,
		extra
	) => {
		const sorterColumn = (
			sorter as SorterResult<MaterialRemenant | MaterialObject>
		)?.column;

		if (extra.action === 'sort' && sorterColumn === undefined) {
			if (props?.onSort) props?.onSort(undefined);
			return;
		}

		if (extra.action === 'sort') {
			const orderType = (
				sorter as SorterResult<MaterialRemenant | MaterialObject>
			)?.order;

			const columnKey = (
				sorter as SorterResult<MaterialRemenant | MaterialObject>
			)?.columnKey;

			if (columnKey === 'height') {
				const sortType = orderType === 'ascend' || orderType === undefined;
				let params = '';
				if (!sortType) {
					params = '-height,-width,-outer_diameter,-inner_diameter';
				} else {
					params = 'height,width,outer_diameter,inner_diameter';
				}

				if (props?.onSort) props?.onSort(params);

				return false;
			}

			const column =
				orderType === 'ascend' || orderType === undefined
					? columnKey
					: '-' + columnKey;

			if (props?.onSort) props?.onSort(column?.toString());
		}
	};

	// const getRelatedSalesOrderLineItem = (
	// 	order_line_item: string | undefined | { name: string }
	// ) => {
	// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 	//@ts-ignore
	// 	return order_line_item?.name || order_line_item;
	// };

	const tableColumns: any = [
		{
			title: 'Quantity',
			render: (record: MaterialRemenant | MaterialObject) => (
				<>{record?.quantity_ordered}</>
			),
			key: 'quantity_ordered',
			visible: true,
			width: 145,
			sorter: true,
		},
		{
			title: 'Category',
			render: (record: MaterialRemenant | MaterialObject) => (
				<>{record?.remnant && <Tag style={{ marginLeft: 5 }}>Remnant</Tag>}</>
			),
			key: 'remnant',
			visible: !props?.showMaterialCertUploader,
			sorter: true,
			width: 145,
		},
		{
			title: 'Material Type',
			render: (record: MaterialRemenant | MaterialObject) =>
				`${record?.type?.display_name}`,
			visible: true,
			key: 'type__display_name',
			sorter: true,
			width: 145,
		},
		{
			title: 'Unit Price',
			render: (record: MaterialRemenant | MaterialObject) =>
				USD.format(Number(record?.unit_price)),
			visible: true,
			key: 'unit_price',
			sorter: true,
			width: 145,
		},

		{
			title: 'Material Shape',
			render: (record: MaterialRemenant | MaterialObject) =>
				`${record?.shape?.display_name}`,
			visible: true,
			key: 'shape__display_name',
			sorter: true,
			width: 145,
		},
		{
			title: 'Material Size',
			render: (record: MaterialRemenant | MaterialObject) =>
				record?.display_size,
			visible: true,
			key: 'height',
			sorter: false,
			width: 145,
		},
		{
			title: 'Length',
			render: (record: MaterialRemenant | MaterialObject) =>
				`${record?.length} ${record?.length_unit?.name}`,
			visible: true,
			key: 'length',
			sorter: true,
			width: 145,
		},
		{
			title: '',
			render: (record: MaterialRemenant | MaterialObject) => (
				<Button
					type="primary"
					onClick={() => props?.onSelectMaterial(record.id)}
				>
					Select
				</Button>
			),
			visible: props?.showMaterialSelectButton,
			width: 145,
		},
		{
			title: 'Related Sales Order Line Item',
			key: 'sales_order_line_items',
			width: 245,
			render: (record: MaterialObject) => (
				<>
					<>
						<SalesOrderLineItemSelect
							className="w-full"
							disabled={props?.showMaterialCertUploader ? false : true}
							value={record.sales_order_line_items?.map((item: any) => {
								return {
									key: item?.id,
									value: item?.id,
									label: `${item?.order?.name} - ${item?.name}`,
								};
							})}
							onChange={(e: any) => {
								const line_items: string[] = e;
								if (props?.onEditLineItem) {
									props?.onEditLineItem(record?.id, line_items);
								}
							}}
							material_item_id={record.id}
						/>
					</>
				</>
			),
			visible: true,
			sorter: true,
		},
		{
			title: 'Vendor Part #',
			render: (record: MaterialRemenant | MaterialObject) =>
				`${record?.vendor_part_number ? record?.vendor_part_number : ''}`,
			visible: true,
			key: 'vendor_part_number',
			sorter: true,
			width: 145,
		},
		{
			title: 'Promised Delivery Date',
			render: (record: MaterialRemenant | MaterialObject) =>
				`${dayjs(record?.promised_delivery_date).format('MMM-DD-YY')}`,
			visible: props?.showPromisedDeliveryDate,
			key: 'promised_delivery_date',
			sorter: false,
			width: 145,
		},
		{
			title: 'Status',
			key: 'status',
			width: 145,
			render: (record: MaterialObject) => (
				<>
					{props?.editableStatus ? (
						<div>
							<Select
								value={record.status ? record.status : ''}
								style={{ width: 140 }}
								onChange={(value: string) => {
									record = Object.assign({}, record, { status: value });
									props?.onChangeStatus(record);
								}}
								options={[
									{ value: 'Ordered', label: 'Ordered' },
									{
										value: 'Partially Delivered',
										label: 'Partially Delivered',
									},
									{ value: 'Delivered', label: 'Delivered' },
								]}
							/>
							{isPartiallyDelivered(record)}
						</div>
					) : (
						<div>
							{record.status}
							{record.status === PARTIALLY_DELIVERED && (
								<div style={{ fontSize: '11px', paddingTop: '10px' }}>
									Total Delivered: {record.quantity_total_received}/
									{record.quantity_ordered}{' '}
									<EditOutlined
										style={{ color: 'red' }}
										onClick={() => {
											props?.onChangeStatus(record);
										}}
									/>
								</div>
							)}
						</div>
					)}
				</>
			),
			field: 'status',
			visible: true,
			sorter: true,
		},
		{
			title: 'Actions',
			key: 'actions',
			visible: props?.showMaterialCertUploader,
			width: 145,
			render: (record: MaterialObject) => {
				const menu = (
					<Menu>
						<Menu.Item
							onClick={() => {
								if (props.onSelectMaterialForRemnant)
									props.onSelectMaterialForRemnant({
										id: record.id,
										maxLength: Number(record.length),
										maxQuantity: record.quantity_ordered,
									});
							}}
							disabled={!props.showRemnantBtn}
						>
							<FileAddOutlined /> Add Material Remnant
						</Menu.Item>
						<Menu.Item disabled={!props.showMaterialCertUploader}>
							{record.cert ? (
								<a
									href={record.cert.file}
									target="_blank"
									rel="noopener noreferrer"
								>
									View
								</a>
							) : (
								<Upload
									{...{ multiple: false, showUploadList: false }}
									beforeUpload={() => false}
									onChange={(info) => {
										if (props?.onUploadCert)
											props?.onUploadCert(record, info.file);
									}}
								>
									<UploadOutlined /> Upload Material Cert
								</Upload>
							)}
						</Menu.Item>
					</Menu>
				);

				return (
					<Dropdown overlay={menu}>
						<Button>
							Actions <DownOutlined />
						</Button>
					</Dropdown>
				);
			},
		},
	].filter((col) => col.visible);

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no materials to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				borderTop: '1px solid #f5f3f3',
			}}
		>
			<CustomResponsiveTable
				excludeWidthColumn={true}
				columns={tableColumns}
				data={props.data}
				onChange={onChange}
				pagination={
					props?.pagination
						? {
								pageSize: 20,
								current: props?.currentPage,
								pageSizeOptions: [],
								total: props?.total,
								onChange: (page) => {
									if (props?.onChangePage) props.onChangePage(page);
								},
								defaultPageSize: 20,
								showSizeChanger: false,
						  }
						: false
				}
				loading={props.isLoading}
				emptyTable={emptyTable}
				expandable={{
					expandedRowRender: (record: any, index: number) => (
						<div
							style={{
								backgroundColor: '#fff',
								padding: '10px 10px 10px 10px',
							}}
							key={index}
						>
							<div className="flex justify-start mb-4 ml-4">
								<span style={{ fontWeight: 'bold' }}>
									Deliveries ({record.quantity_total_received} Delivered)
								</span>
							</div>
							<div className="timeline_container">
								{record.material_received.length === 0 ? (
									<div className="text-center">No Deliveries yet</div>
								) : (
									<Timeline mode="left">
										{record.material_received.map(
											(item: MaterialReceivedInterface, index: number) => {
												return (
													<Timeline.Item
														label={dayjs(item.created_time).format(
															'YYYY-MM-DD'
														)}
														dot={
															item.material_rejected ? (
																<WarningOutlined />
															) : (
																<CheckCircleFilled />
															)
														}
														key={index}
													>
														{item.qty_delivered} units Delivered Successfully,
														{item.material_rejected ? (
															<span className={'text-red-500'}>
																{' '}
																{item.qty_rejected} units Rejected (
																{item.reject_reason}),
															</span>
														) : null}{' '}
														Received by {item.received_by.email}
													</Timeline.Item>
												);
											}
										)}
									</Timeline>
								)}
							</div>
						</div>
					),
					rowExpandable: (record: any) => 'material_received' in record,
				}}
			/>
		</div>
	);
};

export default MaterialTable;
