//import { useState } from 'react';
/*import QRCode from "qrcode";
import ToolHolderTable from "../../components/ToolHolderTable";
import ToolHolderModelsTable from "../../components/ToolHolderModelsTable";*/
import PageHeader from '../../components/PageHeader';
// import { useGetToolHoldersQuery } from "../../redux/api/tool-holders";

export interface QRCodes {
	img: string;
	value: string;
}

function ToolHoldersQRCodes() {
	// const getToolHolders = useGetToolHoldersQuery();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// const [qrCodes, setQrCodes] = useState<QRCodes[]>([]);
	const qrCodes: QRCodes[] = [];

	/* const generateQRCode = async (id) => {
    const qr = await QRCode.toDataURL(id);
    return qr;
  };*/

	// setQrCodes(
	//   await Promise.all(
	//     data.map(async (th) => {
	//       return {
	//         img: await generateQRCode(th.tms_id),
	//         value: th?.tool_holder_model?.model,
	//       };
	//     })
	//   )
	// );

	return (
		<>
			<PageHeader name="Tool Holders" addButton={false} />

			<div id="print">
				<div
					id="print-page"
					style={{
						width: '8.5in',
						height: '11in',
						padding: '0.225in 0.175in 0.225in 0.175in',
						border: '1px solid #ccc',
						display: 'flex',
						flexWrap: 'wrap',
						alignContent: 'flex-start',
						justifyContent: 'flex-start',
					}}
				>
					{qrCodes?.map((qr, index) => {
						return (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
									width: `.625in`,
									height: '0.617in',
								}}
								key={index}
							>
								<img
									style={{
										width: '0.5in',
										height: '0.5in',
										// border: "1px solid #ccc",
									}}
									src={`${qr.img}`}
								/>
								<span
									className="print-text"
									style={{
										fontSize: '0.05in',
										textAlign: 'center',
										width: '100%',
									}}
								>
									{qr?.value}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default ToolHoldersQRCodes;
