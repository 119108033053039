import { Button, Form, Input, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { useQueryParameters } from '../../hooks';

const FormItem = Form.Item;

export const SalesOrderFilter = () => {
	const queryParams = useQueryParameters();
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const onFinish = (values: { search: string; production_method: string }) => {
		if (values.search) {
			queryParams.set('search', values.search);
		}
		if (values.production_method) {
			queryParams.set('production_method', values.production_method);
		}
		navigate({ search: queryParams.toString() });
	};

	return (
		<div>
			<Form
				name="order-filter"
				form={form}
				layout={'horizontal'}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
			>
				<div className="flex flex-col lg:flex-row">
					<div className="order-search-and-filter-fields">
						<FormItem
							label="Search"
							name={'search'}
							initialValue={queryParams.get('search')}
							style={{ margin: 0, padding: 0 }}
						>
							<Input placeholder="Search" allowClear />
						</FormItem>
					</div>

					<div className="order-search-and-filter-fields lg:pl-2">
						<FormItem
							label="Production Method"
							name={'production_method'}
							initialValue={queryParams.get('production_method')}
							style={{ margin: 0, padding: 0, width: '100%' }}
						>
							<Select>
								<Select.Option value="Fastlane">Fastlane</Select.Option>
								<Select.Option value="QuickTurn">QuickTurn</Select.Option>
								<Select.Option value="Swiss">Swiss</Select.Option>
								<Select.Option value="Traditional">Traditional</Select.Option>
							</Select>
						</FormItem>
					</div>
					<div className="flex flex-row mb-4 lg:px-2">
						<Form.Item noStyle>
							<Button
								onClick={() => {
									form.setFieldValue('search', '');
									form.setFieldValue('production_method', '');
									navigate(location.pathname, { replace: true });
								}}
							>
								Reset
							</Button>
							<Button
								type="primary"
								htmlType={'submit'}
								style={{ marginLeft: 8 }}
							>
								Filter
							</Button>
						</Form.Item>
					</div>
				</div>
			</Form>
		</div>
	);
};
