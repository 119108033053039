import {Card, Col, Flex, Form, Row, Tag, Typography} from 'antd';
import {useParams} from 'react-router';
import {useGetOrderByIdQuery} from '../../../redux/api/order';
import {CardItem} from '../CardItemDetail';
import {Lineitem} from '../../../interfaces/Ordel.Interface';
import {parseISO} from 'date-fns';
// import apiPB from '../../../axios/api-pb';
import {STAFF_PORTAL_URL} from '../../../config/constants';
import {Link} from 'react-router-dom';

import Header from '../../../components/Header';
import Loading from '../../../components/Loading/index.tsx';
import {getOrderStatusTagColor, getTotalPartsQty} from '../../../utils';
import {ArrowRightOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const OrderDetails = () => {
    const {id} = useParams();

    const {data, isLoading, refetch} = useGetOrderByIdQuery(
        {id: id as string},
        {skip: !id, refetchOnMountOrArgChange: true}
    );

    // This code was added but it is not doing anything, should we remove it?

    // useEffect(() => {
    // 	if(data?.partsbadger_api_id){
    // 		GetOrderPB(data.partsbadger_api_id)
    // 	}
    // }, [data])

    // async function GetOrderPB(id:number){
    // 	try {
    // 		const data = await apiPB.get(`staff/sales-orders/${id}/`)
    // 		console.log(data)
    // 	} catch (error) {
    // 		console.log(error)
    // 	}
    // }

    const [form] = Form.useForm();

    const compareDates = (a: string, b: string): number => {
        const dateA = parseISO(a);
        const dateB = parseISO(b);

        if (dateA < dateB) {
            return -1;
        } else if (dateA > dateB) {
            return 1;
        } else {
            return 0;
        }
    };

    return (
        <>
            <Header
                title="Order Details"
                breadcrumbItems={[
                    {
                        title: <Link to="/orders">Home</Link>,
                    },
                    {
                        title: <Link to="/orders"> Orders </Link>,
                    },
                    {
                        title: 'Order Details',
                    },
                ]}
            />

            {!data || isLoading ? (
                <Loading loading={true}/>
            ) : (
                <Row gutter={[8, 16]}>
                    <Col xs={24} span={24}>
                        <Card>
                            <div className="flex flex-col sm:flex-row w-full gap-x-12 gap-y-6 mb-5 shrink-0 flex-wrap">
                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Order Name</span>
                                    <Title level={5}>{data?.name}</Title>
                                </Flex>

                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Number of Line Items</span>
                                    <span>{data?.line_items?.length}</span>
                                </Flex>
                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Total part quantities</span>
                                    {data && getTotalPartsQty(data.line_items) !== data.line_items.reduce((a, b) => a + b.quantity, 0) ? (
                                        <div className="flex flex-row items-baseline gap-1">
                                            <Text delete>{data.line_items.reduce((a, b) => a + b.quantity, 0)}</Text>
                                            <Text>
                                                <ArrowRightOutlined style={{fontSize: '0.7rem'}}/>
                                            </Text>
                                            <Text strong mark>{getTotalPartsQty(data.line_items)}</Text>
                                        </div>
                                    ) : (
                                        <span>
                                    	    {data?.line_items?.reduce((a, b) => a + b.quantity, 0)}
                                        </span>
                                    )}
                                </Flex>
                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Soonest Due Date</span>
                                    <span>
										{data?.line_items
                                            ? data.line_items
                                                .map((item) => item.required_part_ship_date)
                                                .sort(compareDates)[0]
                                            : ''}
									</span>
                                </Flex>

                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Sales Order</span>
                                    <Link
                                        to={`${STAFF_PORTAL_URL}/sales-order/${data?.partsbadger_api_id}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {data?.name}
                                    </Link>
                                </Flex>

                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">Order Status</span>
                                    <span>
										<Tag color={getOrderStatusTagColor(data?.status)}>
											{data?.status}
										</Tag>
									</span>
                                </Flex>
                            </div>
                            <div className="flex flex-col gap-6">
                                <Flex vertical={true} align="start">
									<span className="text-slate-400">
										Additional Requirements
									</span>
                                    <span>
										{data?.additional_requirements &&
                                        data.additional_requirements?.length > 0
                                            ? data?.additional_requirements?.join(', ')
                                            : 'None'}
									</span>
                                </Flex>

                                <Flex vertical={true} align="start">
                                    <span className="text-slate-400">In House Requirements</span>
                                    <span>
										{data && data.in_house_requirements
                                            ? data.in_house_requirements
                                            : 'None'}
									</span>
                                </Flex>
                            </div>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Form name="line_item_material_form" form={form}>
                            {data &&
                                data.line_items.map((item: Lineitem, index) => (
                                    <CardItem
                                        lineItem={item}
                                        refetchOrder={refetch}
                                        isLoading={isLoading}
                                        key={index}
                                        status_order={data.status_fastlane}
                                        ready_for_production={data.ready_for_production}
                                    />
                                ))}
                        </Form>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default OrderDetails;
