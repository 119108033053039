import { useState, useEffect } from 'react';
import { Modal, Form, Select, Row, Col, InputNumber, notification } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { closeAddMaterialModal } from '../../../redux/slices/material';
import {
	useSearchMaterialMutation,
	useAddMaterialRemnantMutation,
} from '../../../redux/api/material';
import { useGetUnitsOfMeasureQuery } from '../../../redux/api/core';
import { RootState } from '../../../redux/store';
import { MaterialRemenant } from '../../../interfaces/MaterialRemenat';
import { MaterialObject } from '../../../interfaces/Material.Interface';

export interface LocalMaterial {
	id: string;
	maxLength: number;
	maxQuantity: number;
}

export interface MaterialModal {
	formItemName?: string;
	material?: LocalMaterial;
	refetch: () => void;
	materialsData?: MaterialObject[] | MaterialRemenant[];
}

const AddMaterialRemnantModal = (props: MaterialModal) => {
	const dispatch = useDispatch();

	const visible = useSelector(
		(state: RootState) => state.material.addMaterialModalVisible
	);

	const [form] = Form.useForm();

	const [searchMaterial, searchMaterialResult] = useSearchMaterialMutation();
	const [addMaterialRemnant, addMaterialRemnantResult] =
		useAddMaterialRemnantMutation();

	const [localMaterial, setLocalMaterial] = useState<LocalMaterial>();

	const [lineItems, setLineItems] = useState<
		MaterialObject[] | MaterialRemenant[]
	>([]);

	const lengthUnitSelector = (
		<UnitSelector
			formItemName="length_unit"
			refetch={function (): void {
				throw new Error('Function not implemented.');
			}}
		/>
	);
	const handleOk = async () => {
		try {
			if (props?.material?.id) {
				form.setFieldValue('material', props?.material?.id);
			}
			const values = await form.validateFields();
			await addMaterialRemnant({ id: values.material, values: values });
		} catch (error) {
			console.log('Validate Failed:', error);
		}
	};

	const closeModal = () => {
		form.resetFields();
		dispatch(closeAddMaterialModal());
	};

	const onChange = (value: string) => {
		const index = lineItems.findIndex((item: any) => {
			return item.id === value;
		});

		if (index > -1) {
			setLocalMaterial({
				id: lineItems[index].id,
				maxLength: Number(lineItems[index].length),
				maxQuantity: lineItems[index].quantity_ordered,
			});
		}
	};
	/*
	const concatName = (item: MaterialObject) => {
		let name = `${item.display_name}`;
		// if (item.shape?.display_name) name += ` ${item.shape.display_name}`;
		// if (item.width) name += `  W: ${item.width} ${item.width_unit?.name}`;
		// if (item.height) name += ` H: ${item.height} ${item.height_unit?.name}`;
		if (item.length) name += ` L: ${item.length} ${item.length_unit?.name}`;
		return name
	}*/

	const onSearch = async (value: string) => {
		if (value !== '' && value !== null && value !== undefined) {
			await searchMaterial({
				search: value.trimEnd().trimStart(),
				hide_draft_and_used_materials: true,
			});
		}
	};

	const openNotification = (message: string, type: string) => {
		if (type === 'success')
			return notification.success({ message: message, placement: 'topRight' });
		if (type === 'error')
			return notification.error({ message: message, placement: 'topRight' });

		return notification.info({ message: message, placement: 'topRight' });
	};

	useEffect(() => {
		if (props?.materialsData) {
			setLineItems(props?.materialsData);
		}
	}, [props?.materialsData]);

	useEffect(() => {
		if (searchMaterialResult.status === 'fulfilled') {
			if (searchMaterialResult.isSuccess) {
				setLineItems(searchMaterialResult.data.results);
			}
		}
	}, [searchMaterialResult]);

	useEffect(() => {
		if (addMaterialRemnantResult.status === 'fulfilled') {
			if (addMaterialRemnantResult.isSuccess) {
				closeModal();
				openNotification('Remnant created', 'success');
				props?.refetch();
			} else {
				openNotification('Something went wront', 'error');
			}
		}
	}, [addMaterialRemnantResult]);

	return (
		<>
			<Modal
				title="Add Material Remnant"
				width={720}
				open={visible}
				onOk={handleOk}
				onCancel={closeModal}
				bodyStyle={{ paddingTop: '1rem' }}
				forceRender
				centered={true}
				// maskClosable={false}
			>
				<Form form={form} layout="vertical" requiredMark={false}>
					<Row gutter={[8, 8]}>
						{!props?.material?.id ? (
							<Col span={24}>
								<Form.Item
									name="material"
									label="Material Order Line Item"
									rules={[
										{
											required: true,
										},
									]}
								>
									<Select
										clearIcon
										showSearch
										placeholder="Search"
										optionFilterProp="children"
										onChange={onChange}
										onSearch={onSearch}
										filterOption={(input) => {
											return input.length > 0;
										}}
									>
										{lineItems.map((item, index) => (
											<Select.Option key={index} value={item.id}>
												{/*concatName(item.display_name)*/}
												{item.display_name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						) : (
							<Form.Item
								style={{ display: 'none' }}
								name="material"
								label="Material Order Line Item"
								rules={[
									{
										required: true,
									},
								]}
							></Form.Item>
						)}

						<Col span={12}>
							<Form.Item
								name="length"
								label="Length"
								rules={[
									{
										required: true,
									},
								]}
							>
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									step={0.125}
									max={
										props?.material
											? props?.material?.maxLength
											: localMaterial?.maxLength
									}
									addonAfter={lengthUnitSelector}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="quantity"
								label="Quantity"
								rules={[
									{
										required: true,
									},
								]}
							>
								<InputNumber
									style={{ width: '100%' }}
									min={1}
									step={1}
									precision={0}
									max={
										props?.material
											? props?.material?.maxQuantity
											: localMaterial?.maxQuantity
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

const UnitSelector = (props: MaterialModal) => {
	const units = useGetUnitsOfMeasureQuery();

	return (
		<Form.Item
			name={props?.formItemName}
			noStyle
			initialValue={units?.data?.find((unit) => unit.name === 'IN')?.id}
			rules={[
				{
					required: true,
					message: '',
				},
			]}
		>
			<Select
				style={{ width: 70 }}
				options={units?.data?.map((unit) => {
					return { value: unit.id, label: unit.name };
				})}
			/>
		</Form.Item>
	);
};

export default AddMaterialRemnantModal;
