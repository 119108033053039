import React from 'react';

import { Typography } from 'antd';

interface IProps {
	lineItem: any;
}
const { Title } = Typography;

const PartInformation: React.FC<IProps> = ({ lineItem }) => {
	return (
		<>
			<div className="tool">
				<img src={lineItem.thumbnail_file} style={{ width: '160px' }}></img>
				<div className="tool-image">
					<div>
						<Title level={5} className="tag-key">
							Part Dimensions
						</Title>
						<Title level={5} className="unit-value">
							Height: {Number.parseFloat(lineItem.height).toFixed(3)}{' '}
							{lineItem.unit}{' '}
						</Title>

						<Title level={5} className="unit-value">
							Width: {Number.parseFloat(lineItem.width).toFixed(3)}{' '}
							{lineItem.unit}{' '}
						</Title>

						<Title level={5} className="unit-value">
							Length: {Number.parseFloat(lineItem.length).toFixed(3)}{' '}
							{lineItem.unit}{' '}
						</Title>
					</div>
					<div>
						<Title level={5} className="tag-key">
							Quantity
						</Title>
						<Title level={5} className="unit-value">
							{lineItem.quantity}{' '}
						</Title>
					</div>
				</div>
			</div>
		</>
	);
};

export default PartInformation;
