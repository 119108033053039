import { createSlice } from '@reduxjs/toolkit';

interface MaterialState {
	addMaterialModalVisible: boolean;
}

const initialState: MaterialState = {
	addMaterialModalVisible: false,
};

export const material = createSlice({
	name: 'material',
	initialState,
	reducers: {
		showAddMaterialModal: (state) => {
			state.addMaterialModalVisible = true;
		},
		closeAddMaterialModal: (state) => {
			state.addMaterialModalVisible = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { showAddMaterialModal, closeAddMaterialModal } = material.actions;

export default material.reducer;
