import React from 'react';

import { Button, Breadcrumb } from 'antd';
import { ButtonType } from 'antd/es/button';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

import { Link } from 'react-router-dom';

interface IProps {
	title: string;
	link?: string;
	showLinkButton?: boolean;
	showOnClickButton?: boolean;
	buttonText?: string;
	icon?: React.ReactNode;
	type?: ButtonType;
	handleOnClick?: () => void;
	breadcrumbItems?: ItemType[];
}

const Header: React.FC<IProps> = ({
	title,
	link,
	showLinkButton,
	showOnClickButton,
	buttonText,
	icon,
	type = 'primary',
	handleOnClick,
	breadcrumbItems,
}) => {
	return (
		<div>
			<div className="flex flex-col md:flex-row justify-between py-4">
				<h1 className="text-2xl font-bold m-0">{title}</h1>

				{showLinkButton && link && (
					<Link to={link}>
						<Button icon={icon} type={type} className="mt-2 md:mt-0">
							{buttonText}
						</Button>
					</Link>
				)}
				{showOnClickButton && (
					<div>
						<Button
							icon={icon}
							type={type}
							onClick={() => handleOnClick && handleOnClick()}
							className="mt-2 md:mt-0"
						>
							{buttonText}
						</Button>
					</div>
				)}
			</div>
			{breadcrumbItems && breadcrumbItems?.length >= 1 && (
				<Breadcrumb
					style={{ marginBottom: '1.5rem', marginTop: '10px' }}
					items={breadcrumbItems}
				/>
			)}
		</div>
	);
};

export default Header;
