import { Button, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import RequestAdditionalMaterialModal from '../../../components/modals/RequestAdditionalMaterialModal';
import { useState } from 'react';
import { Lineitem } from '../../../interfaces/Ordel.Interface';
import SelectMaterialModal from '../../../components/modals/Orders/SelectMaterialModal';
import { showModal } from '../../../redux/slices/material-request';
import MaterialRequestModal from '../../../components/modals/Orders/MaterialRequestModal';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

export const Material = ({
	lineItem,
	refetchLineItem,
	isDisabled,
}: {
	lineItem: Lineitem;
	refetchLineItem: () => void;
	isDisabled: boolean;
}) => {
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const [materialRequestModalOpen, setMaterialRequestModalOpen] =
		useState(false);
	const [
		isRequestAdditionalMaterialModalOpen,
		setIsRequestAdditionalMaterialModalOpen,
	] = useState(false);

	return (
		<>
			<Flex vertical>
				<Title level={5}>Material</Title>
				{lineItem?.material ? (
					<Flex vertical={true} align="start">
						<span>{`${lineItem?.material?.display_name} ${lineItem?.material?.length} X ${lineItem?.material?.length_unit?.name}.`}</span>
						<div>
							{lineItem?.material?.material_order ? (
								<Link
									to={`/material/orders/${lineItem?.material?.material_order?.id}`}
								>
									View Material Order
								</Link>
							) : null}

							{lineItem?.material_request &&
								lineItem?.material_request?.status === 'Ordered' && (
									<Button
										className="p:0 md:mt-0"
										type="link"
										onClick={() =>
											setIsRequestAdditionalMaterialModalOpen(true)
										}
									>
										Request Additional Material
									</Button>
								)}
						</div>
					</Flex>
				) : (
					<>
						{lineItem?.material_request ? (
							<Flex vertical align="start" className="gap-1">
								<span>{`${lineItem?.material_request?.type?.display_name} ${lineItem?.material_request?.display_size}`}</span>
								<div>
									<Link
										to={`/material/requests?search=${lineItem?.material_request.id}&page=1`}
									>
										View Material Request
									</Link>
								</div>
							</Flex>
						) : (
							<div>
								<Button
									disabled={isDisabled}
									onClick={() => {
										setModalOpen(true), dispatch(showModal());
									}}
									type="primary"
								>
									Select Material
								</Button>
							</div>
						)}
					</>
				)}
			</Flex>

			{modalOpen && (
				<SelectMaterialModal
					showModal={modalOpen}
					closeModal={() => {
						setModalOpen(false);
					}}
					lineItem={lineItem}
					openNewMaterialRequest={() => {
						setMaterialRequestModalOpen(true);
					}}
				/>
			)}

			{materialRequestModalOpen && (
				<MaterialRequestModal
					showModal={materialRequestModalOpen}
					closeModal={() => {
						setMaterialRequestModalOpen(false);
					}}
					lineItem={lineItem}
					refetchLineItem={refetchLineItem}
					orderName={lineItem.order.name}
				/>
			)}

			{isRequestAdditionalMaterialModalOpen && (
				<RequestAdditionalMaterialModal
					modalIsOpen={isRequestAdditionalMaterialModalOpen}
					closeModal={() => setIsRequestAdditionalMaterialModalOpen(false)}
					lineItemData={lineItem}
				/>
			)}
		</>
	);
};
