import {Fragment, useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, InputNumber, notification, Select} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {MaterialObject} from "../../../interfaces/Material.Interface.ts";
import {MaterialDimensions, SignatureDefault} from "../../../components";
import {useGetUsersQuery, UserQueryInterface} from "../../../redux/api/users.ts";
import {UserListInterface} from "../../../interfaces/User.interface.ts";
import {useAddMaterialReceivedMutation} from "../../../redux/api/material-received.ts";

interface ReceivingFormProps {
    refetchData: () => void;
    material: MaterialObject;
    onCancel: (value: boolean) => void;
}
export const ReceivingForm = (props: ReceivingFormProps) => {
    const userQuery: UserQueryInterface = {};

    const {data} = useGetUsersQuery(userQuery);
    const [addMaterial, result] = useAddMaterialReceivedMutation();

    const [qtyDelivered, setQtyDelivered] = useState(props.material.quantity_ordered - props.material.quantity_total_received);
    const [checkedMaterialRejected, setCheckedMaterialRejected] = useState(false);
    const [signature, setSignature] = useState<string | null>(null);

    const [maxQtyDelivered, setMaxQtyDelivered] = useState(0)
    const [form] = Form.useForm();

    const handleChangeMaterialRejected = (e: CheckboxChangeEvent) => {
        setCheckedMaterialRejected(e.target.checked)
    }

    const handleFinish = async (values: any) => {
        if(values.qty_delivered !== 0 && signature !== null && signature !== '') {

            if ("qty_delivered" in values) {
                values.qty_delivered = qtyDelivered;
            }
            values.signature = signature;
            values.material_order_line_item = props.material.id;

            if ("qty_rejected" in values) {
                if (values.qty_rejected > values.qty_delivered) {
                    notification.error({
                        message: 'Rejected Quantity cannot be greater than Delivered Quantity',
                        placement: 'topRight'
                    });
                    return;
                }
            }

            await addMaterial(values);
        }
    }

    useEffect(() => {
        if (result.isSuccess) {
            notification.success({
                message: 'Material Received Successfully',
                placement: 'topRight'
            });
            props.refetchData();
            handleCancel();
        }
    }, [result]);

    useEffect(() => {
        setQtyDelivered(props.material.quantity_ordered - props.material.quantity_total_received);
        setMaxQtyDelivered(props.material.quantity_ordered - props.material.quantity_total_received);
    }, [props.material]);

    const handleCancel = () => {
        form.resetFields();
        setCheckedMaterialRejected(false);
        setQtyDelivered(0);
        setMaxQtyDelivered(0);
        setSignature(null);
        props.onCancel(true);
    }

    return (
        <Fragment>
            <Form layout="vertical" form={form} onFinish={handleFinish}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Vendor Order Number</div>
                            <div>
                                {props.material.material_order.vendor_order_number}
                            </div>
                        </div>
                    </div>

                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">P. Order Number</div>
                            <div>
                                {props.material.material_order.purchase_order_number}
                            </div>
                        </div>
                    </div>

                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Material Type</div>
                            <div>
                                {props.material.type.display_name}
                            </div>
                        </div>
                    </div>

                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Material Shape</div>
                            <div>
                                {props.material.shape.display_name}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Material Size</div>
                        </div>
                        <MaterialDimensions
                            shape={props.material.shape?.display_name}
                            width={Number(props.material.width)}
                            width_unit={props.material.width_unit?.name}
                            height={Number(props.material.height)}
                            height_unit={props.material.height_unit?.name}
                            inner_diameter={Number(props.material.inner_diameter)}
                            inner_diameter_unit={props.material.inner_diameter_unit?.name}
                            outer_diameter={Number(props.material.outer_diameter)}
                            outer_diameter_unit={props.material.outer_diameter_unit?.name}
                        />
                    </div>

                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Material Length</div>
                            <div>
                                {props.material.length} {props.material.length_unit.name} L.
                            </div>
                        </div>
                    </div>

                    <div className="p-2">
                        <div className="flex flex-col justify-between">
                            <div className="font-light">Ordered / Delivered</div>
                            <div>
                                {props.material.quantity_ordered} / {props.material.quantity_total_received}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <Form.Item
                        name="qty_delivered"
                        label="Delivered Quantity"
                        initialValue={qtyDelivered}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <div>
                            <Button type="default" icon={<MinusOutlined />} onClick={() => {
                                if (qtyDelivered > 0) {
                                    setQtyDelivered(qtyDelivered - 1);
                                }
                            }}  />

                            <InputNumber
                                min={1}
                                max={maxQtyDelivered}
                                value={qtyDelivered}
                                onChange={(value: number | null): void => {
                                    if (value !== null && value !== undefined) {
                                        const integerQtyDelivered: boolean = Number.isInteger(value);
                                        if (!integerQtyDelivered) {
                                            notification.info({
                                                message: `Delivered Quantity: ${value} was rounded to ${Math.ceil(value)}`,
                                                placement: 'topRight'
                                            });
                                            setQtyDelivered(Math.ceil(value));
                                        } else {
                                            setQtyDelivered(value);
                                        }
                                    }
                                }}
                            />

                            <Button type="default" icon={<PlusOutlined />} onClick={() => {
                               if (maxQtyDelivered > qtyDelivered) {
                                   setQtyDelivered(qtyDelivered + 1);
                               }
                            }}/>

                            {qtyDelivered === 0 ? <div className="text-red-500">Please enter delivered qty</div> : null}

                        </div>
                    </Form.Item>
                </div>

                <Form.Item
                    name={"packing_slip"}
                    initialValue={true}
                    valuePropName="checked"
                >
                    <Checkbox>
                        The delivered quantity matches the Packaging Slip
                    </Checkbox>
                </Form.Item>

                <Form.Item name={"material_rejected"} initialValue={false} valuePropName="checked">
                    <Checkbox onChange={handleChangeMaterialRejected}>
                        Material Rejected
                    </Checkbox>
                </Form.Item>

                {checkedMaterialRejected ? <>
                    <Form.Item
                        name="reject_reason"
                        label="Reject Reason"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            key={"reject_reason"}
                            placeholder={"Select Reject Reason"}
                            style={{width: '100%'}}
                        >
                            <Select.Option value="Damaged Material">Damaged Material</Select.Option>
                            <Select.Option value="Incorrect Material Type">Incorrect Material Type</Select.Option>
                            <Select.Option value="Incorrect Material Size">Incorrect Material Size</Select.Option>
                            <Select.Option value="Ordered by Mistake">Ordered by Mistake</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="qty_rejected"
                        label="Reject Quantity"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber
                            min={1}
                            max={qtyDelivered}
                            style={{width: '100%'}}
                            formatter={value => `${Math.ceil(value as number)}`}
                            parser={value => Math.ceil(value as unknown as number)}
                        />
                    </Form.Item>

                    <Form.Item
                        name={"return_to_vendor"}
                        initialValue={false}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Material Rejected Return to Vendor
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        name={"additional_details"}
                        label="Additional Details"
                        >
                        <Input.TextArea />
                    </Form.Item>

                </>: null}

                <Form.Item
                    name="received_by"
                    label="Received By"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        key={"received_by"}
                        placeholder={"Select User"}
                        style={{width: '100%'}}
                    >
                        {data && data.results.map((user: UserListInterface) => {
                            return <Select.Option key={user.id} value={user.id}>{user.full_name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>

                <SignatureDefault
                    signature={(value: string) => {
                        if (value !== '') {
                            setSignature(value);
                        } else {
                            setSignature(null);
                        }
                    }}
                    showSignature={signature !== null}
                />
                {signature === null ? <div className="text-red-500">Please sign to submit</div> : null}

                <div className={"flex flex-row justify-end mt-4"}>
                    <Button type={"default"} onClick={handleCancel} className={"mx-2"}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={signature === null || qtyDelivered === 0}>
                        Submit
                    </Button>
                </div>
            </Form>
        </Fragment>
    )
}
