
interface Props {
    shape?: string;
    width?: number;
    width_unit?: string;
    height?: number;
    height_unit?: string;
    inner_diameter?: number;
    inner_diameter_unit?: string;
    outer_diameter?: number;
    outer_diameter_unit?: string;
}

export const MaterialDimensions = (props: Props) => {
    return (
        <div>
            {props.shape === 'Rectangle' || props.shape === 'Plate' ? (
                <div>
                    {props.height} {props.height_unit} H. x {props.width} {props.width_unit} W.
                </div>
            ) : null }

            {props.shape === 'Round' ? (
                <div>
                    {props.outer_diameter} {props.outer_diameter_unit} O.D.
                </div>
            ) : null }

            {props.shape === 'Tube' ? (
                <div>
                    {props.outer_diameter} {props.outer_diameter_unit} O.D. x {props.inner_diameter} {props.inner_diameter_unit} I.D.
                </div>
            ) : null }
        </div>
    )
}
