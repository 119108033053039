import { Card, Col, Row } from 'antd';
import MachinesTable from '../../../components/MachineTable';
import Header from '../../../components/Header';
import { Link } from 'react-router-dom';

function Machines() {
	return (
		<>
			<Header
				title="Machines"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Machines',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
						<MachinesTable />
					</Card>
				</Col>
			</Row>
		</>
	);
}

export default Machines;
