interface IProps {
    value: number
}

const ShowPrices = (props: IProps) => {
    const formatPrice = (price: number) => {
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'USD'}).format(price);
    };

    return (
        <span>{formatPrice(props.value).toString()}</span>
    );
}

export default ShowPrices;
