import { Alert, Card, Col, Row } from 'antd';
import { useGetMachineStatusesQuery } from '../../redux/api/machines';
import { LoadingOutlined } from '@ant-design/icons';

import Header from '../../components/Header';
import { Link } from 'react-router-dom';

function MachineStatuses() {
	const machines = useGetMachineStatusesQuery();

	return (
		<>
			<Header
				title="Machine Status"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Machine Status',
					},
				]}
			/>
			{machines.isLoading ? (
				<>
					<LoadingOutlined />
					Loading...
				</>
			) : (
				<Row gutter={[8, 8]}>
					{machines?.data?.map((machine) => {
						const background =
							machine?.status?.machine_state == 'Stopped'
								? 'salmon'
								: machine?.status?.machine_state == 'Active'
								? 'lightgreen'
								: machine?.status?.machine_state == 'Feed Hold'
								? 'khaki'
								: 'white';

						return (
							<Col xl={6} lg={8} md={12} sm={24} span={24} key={machine?.id}>
								<Card
									style={{
										height: '100%',
										background: machine.is_on_network
											? background
											: 'lightyellow',
									}}
								>
									<img src={machine.thumbnail?.file} />
									<h2 className="text-2xl font-bold mb-3">{machine.name}</h2>
									{machine?.is_on_network ? (
										<>
											<div className="flex justify-between">
												<span className="font-bold">Status:</span>{' '}
												{machine?.status?.machine_state}
											</div>
											<div className="flex justify-between">
												<span className="font-bold">Program Number:</span>{' '}
												{machine?.status?.program_number}
											</div>
											<div className="flex justify-between">
												<span className="font-bold">Sub Program Number:</span>{' '}
												{machine?.active_sub_program}
											</div>
											<div className="flex justify-between">
												<span className="font-bold">Total Parts:</span>{' '}
												{machine?.status?.total_parts}
											</div>
											<div className="flex justify-between">
												<span className="font-bold">Power On Time:</span>{' '}
												{Number(
													machine?.power_on_time?.split(':')[0]
												)?.toLocaleString('en-US')}{' '}
												Hrs. {machine?.power_on_time?.split(':')[1]} Min.
											</div>
											<div className="flex justify-between">
												<span className="font-bold">Motion Time:</span>{' '}
												{Number(
													machine?.motion_time?.split(':')[0]
												)?.toLocaleString('en-US')}{' '}
												Hrs. {machine?.motion_time?.split(':')[1]} Min.
											</div>
										</>
									) : (
										<Alert
											showIcon
											type="warning"
											description={'Machine is not on the network'}
											message={<span className="font-bold">Alert</span>}
										/>
									)}
								</Card>
							</Col>
						);
					})}
				</Row>
			)}
		</>
	);
}

export default MachineStatuses;
