import { Form, Select } from 'antd';
import { useGetUnitsOfMeasureQuery } from '../../redux/api/core';

interface UnitInterface {
	name?: number;
	formItemName: string;
	disabled: boolean;
}

export const UnitSelector = (props: UnitInterface) => {
	const units = useGetUnitsOfMeasureQuery();

	return (
		<Form.Item
			name={
				props.name ? [(props.name, props?.formItemName)] : props?.formItemName
			}
			noStyle
			initialValue={units?.data?.find((unit) => unit.name === 'IN')?.id}
			rules={[
				{
					required: true,
					message: 'Unit Required',
				},
			]}
		>
			<Select
				popupMatchSelectWidth={false}
				style={{ width: 60 }}
				options={units?.data?.map((unit) => {
					return { value: unit.id, label: unit.name };
				})}
				disabled={props.disabled}
			/>
		</Form.Item>
	);
};
