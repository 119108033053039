import { api } from '../slices/api';

export const extendedApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        addMaterialReceived: builder.mutation({
            query: (values) => ({
                url: 'material/material-received/',
                method: 'POST',
                body: values,
            }),
        }),
    }),
});

export const { useAddMaterialReceivedMutation } = extendedApiSlice;
