import { createSlice } from '@reduxjs/toolkit';

interface ToolHolderModelState {
	addToolHolderModelModalVisible: boolean;
}

const initialState: ToolHolderModelState = {
	addToolHolderModelModalVisible: false,
};

export const toolHolderModel = createSlice({
	name: 'toolHolderModel',
	initialState,
	reducers: {
		showAddToolHolderModelModal: (state) => {
			state.addToolHolderModelModalVisible = true;
		},
		closeAddToolHolderModelModal: (state) => {
			state.addToolHolderModelModalVisible = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { showAddToolHolderModelModal, closeAddToolHolderModelModal } =
	toolHolderModel.actions;

export default toolHolderModel.reducer;
