export const STAFF_PORTAL_URL = import.meta.env.VITE_STAFF_PORTAL_URL
	? import.meta.env.VITE_STAFF_PORTAL_URL
	: 'https://quotes.parts-badger.com';

interface Status {
	label: string;
	field_name: string;
	statuses: string[];
}

export const statuses: Status[] = [
	{
		label: 'Ballooning',
		field_name: 'ballooning_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Blank FAI Form Creation',
		field_name: 'blank_fai_form_creation_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Keyence Programming',
		field_name: 'keyence_programming_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Material Selection',
		field_name: 'material_selection_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Material Ordering',
		field_name: 'material_ordering_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Material Prep',
		field_name: 'material_prep_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Tooling Selection',
		field_name: 'tooling_selection_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Tooling Ordering',
		field_name: 'tooling_ordering_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'CAM Programming',
		field_name: 'cam_programming_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Cart Assembly',
		field_name: 'cart_assembly_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Machine Setup',
		field_name: 'machine_setup_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
	{
		label: 'Machining',
		field_name: 'machining_status',
		statuses: ['Not Started', 'In Progress', 'Completed'],
	},
];

interface Tab {
	label:
		| 'Ballooning'
		| 'Blank FAI Form Creation'
		| 'Keyence Programming'
		| 'Material Selection'
		| 'Material Ordering'
		| 'Material Prep'
		| 'Tooling Selection'
		| 'Tooling Ordering'
		| 'CAM Programming'
		| 'Cart Assembly'
		| 'Machine Setup'
		| 'Machining';
	field_name:
		| 'ballooning_status'
		| 'blank_fai_form_creation_status'
		| 'keyence_programming_status'
		| 'material_selection_status'
		| 'material_ordering_status'
		| 'material_prep_status'
		| 'tooling_selection_status'
		| 'tooling_ordering_status'
		| 'cam_programming_status'
		| 'cart_assembly_status'
		| 'machine_setup_status'
		| 'machining_status';
}

export const productionQueueTabs: Tab[] = [
	{
		label: 'Ballooning',
		field_name: 'ballooning_status',
	},
	{
		label: 'Blank FAI Form Creation',
		field_name: 'blank_fai_form_creation_status',
	},
	{
		label: 'Keyence Programming',
		field_name: 'keyence_programming_status',
	},
	{
		label: 'Material Selection',
		field_name: 'material_selection_status',
	},
	{
		label: 'Material Ordering',
		field_name: 'material_ordering_status',
	},
	{
		label: 'Material Prep',
		field_name: 'material_prep_status',
	},
	{
		label: 'Tooling Selection',
		field_name: 'tooling_selection_status',
	},
	{
		label: 'Tooling Ordering',
		field_name: 'tooling_ordering_status',
	},
	{
		label: 'CAM Programming',
		field_name: 'cam_programming_status',
	},
	{
		label: 'Cart Assembly',
		field_name: 'cart_assembly_status',
	},
	{
		label: 'Machine Setup',
		field_name: 'machine_setup_status',
	},
	{
		label: 'Machining',
		field_name: 'machining_status',
	},
];
