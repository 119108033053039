import 'react';
import { Col, Row } from 'antd';

import OrderLineItemsTable from '../../../components/OrderLineItemsTable/index.tsx';
import Header from '../../../components/Header/index.tsx';
import { Link } from 'react-router-dom';

const OrderLineItems = () => {
	return (
		<>
			<Header
				title="Order Line Items"
				breadcrumbItems={[
					{
						title: <Link to="/orders">Home</Link>,
					},
					{
						title: 'Order Line Items',
					},
				]}
			/>
			<Row>
				<Col span={24}>
					<OrderLineItemsTable />
				</Col>
			</Row>
		</>
	);
};

export default OrderLineItems;
