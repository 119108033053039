import { Empty } from 'antd';
import React from 'react';
import { useGetMachineCycleTimesQuery } from '../../redux/api/machines';
import { machineRecord } from '../../interfaces/Machines.interfaces';
import CustomResponsiveTable from '../CustomResponsiveTable/index.tsx';

const MachineCycleTimeTable: React.FC = () => {
	const { data, isLoading } = useGetMachineCycleTimesQuery();

	const tableColumns = [
		{
			title: 'Machine',
			render: (record: machineRecord) => record?.machine?.name,
		},
		{
			title: 'IP Address',
			render: (record: machineRecord) => record?.machine?.ip_address,
		},
		{
			title: 'Cycle Time',
			render: (record: machineRecord) => record?.motion_time,
		},
	];

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no machine cycle time to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<CustomResponsiveTable
				columns={tableColumns}
				data={data}
				pagination={false}
				loading={isLoading}
				emptyTable={emptyTable}
			/>
		</div>
	);
};

export default MachineCycleTimeTable;
