import 'react';
import { Modal } from 'antd';
import { useState } from 'react';

import ThreeDViewer from '../../../components/ThreeDViewer/index.tsx';
import None from '../../../assets/none-icon.svg';
import { Lineitem } from '../../../interfaces/Ordel.Interface.ts';

interface Props {
	lineItem: Lineitem;
}

const Thumbnail = (props: Props) => {
	const [, modalContext] = Modal.useModal();

	const [is3DModalOpen, setIs3DModalOpen] = useState(false);

	return (
		<>
			<div className="flex flex-col items-center">
				<img
					className="mb-2"
					style={{
						width: '100%',
						cursor:
							!props.lineItem.thumbnail_file && !props.lineItem.step_file
								? 'not-allowed'
								: 'pointer',
						border: !props.lineItem.thumbnail_file ? '1px solid #aaa' : 'none',
						opacity: props?.lineItem.thumbnail_file ? 1 : 0.25,
					}}
					src={
						props.lineItem.thumbnail_file ? props.lineItem.thumbnail_file : None
					}
					onClick={() =>
						props?.lineItem.file_3djs ? setIs3DModalOpen(true) : null
					}
				/>

				{!props.lineItem.thumbnail_file ? (
					<div className="mb-5">No Preview</div>
				) : null}
			</div>

			{props?.lineItem.file_3djs ? (
				<Modal
					title="3D Model Preview"
					open={is3DModalOpen}
					footer={null}
					closable={true}
					centered={true}
					onCancel={() => setIs3DModalOpen(false)}
					width={800}
					afterOpenChange={() =>
						setTimeout(() => {
							window.dispatchEvent(new Event('resize'));
						}, 0)
					}
				>
					{is3DModalOpen && (
						<ThreeDViewer
							url={props.lineItem.file_3djs}
							canvasStyle={{ height: '600px' }}
						/>
					)}
				</Modal>
			) : null}

			{modalContext}
		</>
	);
};

export default Thumbnail;
