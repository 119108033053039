import { useState, useEffect } from 'react';

import {
	Button,
	Modal,
	Alert,
	Empty,
	Input,
	TablePaginationConfig,
	TableProps,
	message,
} from 'antd';
import { DataType } from '../../../../interfaces/TableDataMaterial.interfaces';
import {
	useGetMaterialRemnantQuery,
	useGetMaterialTypesQuery,
	useUpdateMaterialMutation,
	useLazyGetMaterialRemnantQuery,
	useGetMaterialShapesQuery,
} from '../../../../redux/api/material';

import { closeModal as closeModalMaterial } from '../../../../redux/slices/material-request';

import { MaterialRemenant } from '../../../../interfaces/MaterialRemenat';

import { ColumnsType } from 'antd/es/table';

import PartInformation from '../PartInformation';

import { useDispatch } from 'react-redux';

import { Lineitem } from '../../../../interfaces/Ordel.Interface';

import CustomResponsiveTable from '../../../CustomResponsiveTable/index.tsx';

interface IProps {
	showModal: boolean;
	closeModal: () => void;
	openNewMaterialRequest: () => void;
	lineItem: Lineitem;
}

interface IMaterialsData {
	key: string;
	quantity: string;
	material: string;
	shape: string;
	sizes: string;
	length: string;
	button: React.ReactElement;
}

const { Search } = Input;

const SelectMaterialModal: React.FC<IProps> = ({
	showModal,
	closeModal,
	lineItem,
	openNewMaterialRequest,
}: IProps) => {
	const [loadTable, setLoadTable] = useState(false);
	const [pagination, setPagination] = useState<TablePaginationConfig>({
		current: 1,
		pageSize: 20,
	});
	const [materialsData, setMaterialsData] = useState<IMaterialsData[]>([]);
	const { data: remenantData, refetch } = useGetMaterialRemnantQuery({
		page: pagination.current,
		hide_all_unavailable_materials: true,
	});
	const [getInventory] = useLazyGetMaterialRemnantQuery();
	const { data: materialTypes } = useGetMaterialTypesQuery();
	const [updateMaterial] = useUpdateMaterialMutation();
	const [messageApi, contextHolder] = message.useMessage();
	const materialShapesQuery = useGetMaterialShapesQuery();

	const dispatch = useDispatch();

	function formatRemnantData(data: MaterialRemenant[] | undefined) {
		return data?.map((item) => ({
			id: item.id,
			key: item.id,
			quantity: `${item.quantity_ordered}`,
			material: `${item.type?.display_name as string}`,
			shape: `${item.shape?.display_name as string}`,
			sizes: getSizes(item),
			length: `${item.length as string} ${item.length_unit?.name as string}`,
			button: (
				<Button
					onClick={() => handleSelectMaterial(item.id)}
					style={{ width: '70px' }}
					block
				>
					Select
				</Button>
			),
		}));
	}

	useEffect(() => {
		if (showModal) {
			const newData = formatRemnantData(remenantData?.results);

			if (newData) {
				setMaterialsData(newData);
				setLoadTable(true);
			}
		}
	}, [remenantData]);

	function getSizes(lineItem: any): string {
		let response = '';
		if (lineItem.outer_diameter) {
			response += `Outer diameter : ${lineItem.outer_diameter}  ${lineItem.outer_diameter_unit?.name} `;
		}
		if (lineItem.inner_diameter) {
			response += `Inner diameter : ${lineItem.inner_diameter}  ${lineItem.inner_diameter_unit?.name} `;
		}
		if (lineItem.width) {
			response += `Width : ${lineItem.width}  ${lineItem.width_unit?.name} `;
		}
		if (lineItem.height) {
			response += `Height : ${lineItem.height}  ${lineItem.height_unit?.name} `;
		}
		return response;
	}

	const columns: ColumnsType<DataType> = [
		{
			title: 'Quantity',
			dataIndex: 'quantity',
		},
		{
			title: 'Material',
			dataIndex: 'material',
			filters: materialTypes?.map((item: any) => {
				return { text: item.display_name, value: item.display_name };
			}),
			onFilter: (value: any, record: any) =>
				record.material.indexOf(value as string) === 0,
		},
		{
			title: 'Shape',
			dataIndex: 'shape',
			filters: materialShapesQuery.data?.map((item) => {
				return {
					text: item.display_name,
					value: item.display_name,
				};
			}),
			onFilter: (value: any, record: any) =>
				record.shape.indexOf(value as string) === 0,
		},
		{
			title: 'Sizes',
			dataIndex: 'sizes',
			sorter: {
				compare: (a, b) => a.sizes.length - b.sizes.length,
			},
		},
		{
			title: 'Length',
			dataIndex: 'length',
			sorter: {
				compare: (a, b) =>
					Number.parseInt(a.length.replace(' IN', '')) -
					Number.parseInt(b.length.replace(' IN', '')),
			},
		},
		{
			title: '',
			dataIndex: 'button',
		},
	];

	async function handleSelectMaterial(idOrderLineItem: string) {
		try {
			const values = {
				id: idOrderLineItem,
				values: {
					order: lineItem.order?.id,
					sales_order_line_items: [lineItem.id],
				},
			};

			await updateMaterial(values);

			refetch();

			dispatch(closeModalMaterial());

			await messageApi
				.open({
					type: 'success',
					content: 'Select material successfully',
					duration: 1,
				})
				.then(() => {
					closeModal();
				});
		} catch (error) {
			messageApi.open({
				type: 'error',
				content: 'Material request can´t created',
			});
		}
	}

	const onSearch = async (value: string) => {
		if (value) {
			const res = await getInventory({
				search: value,
				hide_all_unavailable_materials: true,
			});
			if (res?.isSuccess) {
				const newData = formatRemnantData(res?.data?.results);

				if (newData) {
					setMaterialsData(newData);
					setLoadTable(true);
				}
			}
		}
	};

	const handleTableChange: TableProps<DataType>['onChange'] = async (
		newPagination
	) => {
		setPagination(newPagination);

		const inventory = await getInventory({
			page: newPagination.current,
			hide_all_unavailable_materials: true,
		});

		const newData = formatRemnantData(inventory.data?.results);

		if (newData) {
			setMaterialsData(newData);
			setLoadTable(true);
		}
	};

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no materials to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<>
			{contextHolder}
			<Modal
				title={'Selected Material'}
				centered
				open={showModal}
				// onOk={() => closeModal()}
				onCancel={() => {
					closeModal();
				}}
				width={1100}
				destroyOnClose={true}
				footer={[
					<div key={1}>
						<>
							<Button
								onClick={() => {
									closeModal();
								}}
							>
								Cancel
							</Button>
						</>

						<>
							<Button
								// style={{ opacity: modalTwo ? 0 : 1 }}

								onClick={() => {
									closeModal();
									openNewMaterialRequest();
								}}
								type="primary"
							>
								New Material Request
							</Button>
						</>
					</div>,
				]}
			>
				<div className="select-material">
					<PartInformation lineItem={lineItem} />
					<div className="info-tool">
						<Search
							placeholder="Search"
							allowClear
							onSearch={onSearch}
							// defaultValue={currentSearch}
							className="select-material-search"
						/>
						{!lineItem.material && (
							<Alert
								description="Is your needed material not available at PartsBadger facility? Click On New Material Request to create a Material Order and purchase it"
								type="info"
								closable
								style={{ marginBottom: '2rem' }}
							/>
						)}
						<CustomResponsiveTable
							pagination={{ ...pagination, total: remenantData?.count }}
							loading={!loadTable}
							style={{
								marginBottom: '2rem',
							}}
							columns={columns}
							data={materialsData}
							onChange={handleTableChange}
							emptyTable={emptyTable}
							tableMaxHeight={50}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default SelectMaterialModal;
