import { useState } from 'react';
import {
	Modal,
	Form,
	Select,
	Row,
	Col,
	InputNumber,
	message,
	Button,
} from 'antd';

import { UnitSelector } from '../../../components/UnitSelector/index.tsx';

import {
	useGetMaterialRequestReasonsQuery,
	useAddMaterialRequestMutation,
} from '../../../redux/api/material-request.ts';

import { Lineitem } from '../../../interfaces/Ordel.Interface';

export interface IProps {
	modalIsOpen: boolean;
	closeModal: () => void;
	lineItemData: Lineitem | undefined;
}

const RequestAdditionalMaterialModal = (props: IProps) => {
	const [messageApi, contextHolder] = message.useMessage();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const materialRequestReasonsQuery = useGetMaterialRequestReasonsQuery();
	const [addMaterialRequest] = useAddMaterialRequestMutation();

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const values = await form.validateFields();

			const data = {
				...values,
				shape: props.lineItemData?.material_request?.shape?.id,
				height_unit: props.lineItemData?.material_request?.height_unit
					? props.lineItemData?.material_request?.height_unit?.id
					: props.lineItemData?.material_request?.height_unit,
				height: props.lineItemData?.material_request?.height,
				inner_diameter: props.lineItemData?.material_request?.inner_diameter,
				inner_diameter_unity: props.lineItemData?.material_request
					?.inner_diameter_unity
					? props.lineItemData?.material_request?.inner_diameter_unity?.id
					: props.lineItemData?.material_request?.inner_diameter_unity,
				outer_diameter: props.lineItemData?.material_request?.outer_diameter,
				outer_diameter_unity: props.lineItemData?.material_request
					?.outer_diameter_unity
					? props.lineItemData?.material_request?.outer_diameter_unity?.id
					: props.lineItemData?.material_request?.outer_diameter_unity,
				quantity_ordered:
					props.lineItemData?.material_request?.quantity_ordered,
				sale_order_name: props.lineItemData?.material_request?.sale_order_name,
				order_line_item: props.lineItemData?.id,
				type: props.lineItemData?.material_request?.type?.id,
				vendor: props.lineItemData?.material_request?.vendor?.id,
				vendor_number: props.lineItemData?.material_request?.vendor_number,
				width_unit: props.lineItemData?.material_request?.width_unit
					? props.lineItemData?.material_request?.width_unit?.id
					: props.lineItemData?.material_request?.width_unit,
				width: props.lineItemData?.material_request?.width,
				is_additional_request: true,
				wall_thickness: props?.lineItemData?.material_request?.wall_thickness,
				wall_thickness_unit:
					props?.lineItemData?.material_request?.wall_thickness_unit?.id,
			};
			await addMaterialRequest(data).unwrap();

			await messageApi
				.open({
					type: 'success',
					content: 'Additional Material created successfully',
					duration: 1,
				})
				.then(() => {
					setLoading(false);
					props.closeModal();
				});
		} catch (error) {
			console.log('Validate Failed:', error);
			messageApi.open({
				type: 'error',
				content: 'Additional material cannot be created.',
			});
			setLoading(false);
		}
	};

	const handleCloseModal = () => {
		form.resetFields();
		props.closeModal();
	};

	const modalStyles = {
		body: {
			paddingTop: '1rem',
		},
	};

	return (
		<>
			<Modal
				title="Request Additional Material"
				width={720}
				open={props.modalIsOpen}
				onCancel={handleCloseModal}
				styles={modalStyles}
				forceRender
				centered={true}
				footer={
					<>
						<Button
							className="mr-2"
							onClick={() => handleCloseModal()}
							disabled={loading}
						>
							Cancel
						</Button>
						<Button
							form="request-additional-material-form"
							type="primary"
							htmlType="submit"
							loading={loading}
						>
							Submit
						</Button>
					</>
				}
			>
				{contextHolder}
				<Form
					form={form}
					name="request-additional-material-form"
					layout="vertical"
					onFinish={() => handleSubmit()}
					requiredMark={false}
				>
					<Row gutter={[8, 8]}>
						<Col span={24}>
							<Form.Item
								name="additional_request_reason"
								label="Reason"
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									options={materialRequestReasonsQuery?.data?.map((item) => ({
										label: item.name,
										value: item.id,
									}))}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name="length"
								label="Material Length"
								rules={[
									{
										required: true,
									},
								]}
							>
								<InputNumber
									style={{ width: '100%' }}
									min={0.001}
									max={1000}
									step={0.125}
									addonAfter={
										<UnitSelector
											formItemName={'length_unit'}
											disabled={false}
										/>
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default RequestAdditionalMaterialModal;
