interface MenuElement {
	path: string;
	label: string;
}

interface MenuElements {
	path?: string;
	label: string;
	children?: MenuElement[];
}

export const menu: MenuElements[] = [
	{
		label: 'Production',
		children: [
			{
				path: '/orders',
				label: 'Orders',
			},
			{ path: '/orders/line-items', label: 'Line Items' },
			{ path: '/production/queues', label: 'Queues' },
		],
	},
	{
		label: 'Material',
		children: [
			{
				path: '/material/orders',
				label: 'Material Orders',
			},
			{ path: '/material/materials', label: 'Material Inventory' },
			{ path: '/material/requests', label: 'Material Requests' },
			{
				path: '/material/receiving',
				label: 'Receiving',
			},
		],
	},
	{
		label: 'Machines',
		children: [
			{
				path: '/machines/statuses',
				label: 'Statuses',
			},
			{
				path: '/machines',
				label: 'Machines',
			},
			{
				path: '/cycle-time',
				label: 'Cycle Times',
			},
			{
				path: '/versabuilt/mill-automation-systems',
				label: 'VersaBuilt',
			},
		],
	},
	{
		label: 'Tool Holders',
		children: [
			{ path: '/tool-holders', label: 'Tool Holder' },
			{
				path: '/tool-holders/models',
				label: 'Tool Holder Models',
			},
		],
	},
];
